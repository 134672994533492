import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Mmenu from 'mmenu-js';
import 'mmenu-js/dist/mmenu.css';

const SomethingView = ({ setShow }) => {
      const menuRef = useRef(null);

      useEffect(() => {
            if (!menuRef.current) {
                  menuRef.current = new Mmenu('#menu', {
                        offCanvas: {
                              use: false
                        },
                        counters: {
                              add: true
                        },
                        setSelected: {
                              hover: true
                        },
                        navbars: [
                              {
                                    position: 'top',
                                    content: ['searchfield']
                              }
                        ]
                  });
            }
      }, []);

      return (
            <>
                  <nav id="menu">
                        <ul>
                              <li>
                                    <Link onClick={() => setShow()} to="/">
                                          Home
                                    </Link>
                              </li>
                              <li>
                                    <span>About us</span>
                                    <ul>
                                          <li>
                                                <Link
                                                      onClick={() => setShow()}
                                                      to="/about-us"
                                                >
                                                      Who we are?
                                                </Link>
                                          </li>
                                          <li>
                                                <Link
                                                      onClick={() => setShow()}
                                                      to="/our-plant"
                                                >
                                                      Our Plant
                                                </Link>
                                          </li>
                                    </ul>
                              </li>
                              <li>
                                    <Link
                                          onClick={() => setShow()}
                                          to="/why-prime-tiles"
                                    >
                                          Why Prime Tiles
                                    </Link>
                              </li>
                              <li>
                                    <Link
                                          onClick={() => setShow()}
                                          to="/products"
                                    >
                                          Products
                                    </Link>
                              </li>
                              <li>
                                    <Link
                                          onClick={() => setShow()}
                                          to="/catalogues"
                                    >
                                          Catalogues
                                    </Link>
                              </li>
                              <li>
                                    <Link
                                          onClick={() => setShow()}
                                          to="/sustainability"
                                    >
                                          Sustainability
                                    </Link>
                              </li>
                              <li>
                                    <Link
                                          onClick={() => setShow()}
                                          to="/contact-us"
                                    >
                                          Contact Us
                                    </Link>
                              </li>
                              <li>
                                    <Link
                                          onClick={() => setShow()}
                                          to="/dealer-contact"
                                    >
                                          Dealers
                                    </Link>
                              </li>
                              <li>
                                    <a
                                          href="https://portal.primeceramics.com.np/login"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                    >
                                          Log In
                                    </a>
                              </li>
                        </ul>
                  </nav>
            </>
      );
};

export default SomethingView;
