function LimitedText({ text, limit }) {
      if (text.length <= limit) {
            return text;
      }

      const limitedText = text.substring(0, limit) + '...';

      return limitedText;
}

export default LimitedText;
