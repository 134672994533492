import styled from 'styled-components';

export const MainContainer = styled.div`
      max-width: 1536px;
      margin: 0 auto;
      background-color: #f2f2f2;
`;

export const BannerContainer = styled.div`
      /* height: 100vh; */
      position: relative;

      @media screen and (max-width: 944px) {
            margin-top: 0;
            width: 100%;
      }
`;

export const BannerSlider = styled.div`
      padding: 160px 0 90px 0;

      @media screen and (max-width: 768px) {
            padding: 100px 0 90px 0;
      }
`;

export const BannerRow = styled.div.attrs(() => ({
      className: 'row'
}))`
      display: flex;
      align-items: center;
`;

export const BannerContainerContent = styled.div.attrs(() => ({
      className: 'col-lg-5'
}))`
      @media screen and (max-width: 1200px) {
            width: 100%;
            order: 2;
            margin-top: 20px;
      }
`;

export const BannerContent = styled.div``;

export const BannerTitle = styled.div`
      h1 {
            font-weight: 500;
            font-size: 2.25rem;
            line-height: 1 !important;
            color: ${(props) => props.theme.primaryColor};
            position: relative;
            margin-top: 0px;
            margin-bottom: 1rem;
            & span {
                  font-style: italic;
                  color: #240406;
                  font-size: 2.825rem;
            }
      }

      span {
            font-weight: 300;
            font-size: 1.75rem;
            color: ${(props) => props.theme.primaryColor};

            & span {
                  font-style: italic;
                  color: #240406;
            }
      }

      p {
            font-weight: 400;
            font-size: 1rem;
            color: ${(props) => props.theme.textColor};
      }

      @media screen and (max-width: 1200px) {
            h1,
            span {
                  color: ${(props) => props.theme.primaryColor};
            }
      }
`;

export const BannerButton = styled.div`
      width: 9.063rem;
      height: 2.5rem;
      background: #e71d28;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      padding: 7px;
      text-align: center;
      margin-top: 1rem;

      &:hover {
            background-color: ${(props) => props.theme.primaryColor};
      }

      a {
            color: ${(props) => props.theme.white};
      }
`;

export const BannerSliderContent = styled.div.attrs(() => ({
      className: 'col-md-7'
}))`
      @media screen and (max-width: 1200px) {
            width: 100%;
            order: 1;
      }
`;

export const SliderContainer = styled.div`
      position: relative;
`;

export const SliderMedia = styled.div`
      position: absolute;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      z-index: 9999;
`;

export const SliderMediaLink = styled.div`
      background-color: var(--white-color);
      padding: 1.125rem;

      svg {
            height: 16px;
            width: 16px;
      }

      &:nth-child(3) {
            border-bottom-right-radius: 20px;
      }
`;

export const SliderImage = styled.div`
      img {
            border-radius: 20px;
      }
`;

export const BannerBackground = styled.div`
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0;

      @media screen and (max-width: 1200px) {
            display: none;
      }
`;
