import React from 'react';
import Breadcrumb from '../components/common/Breadcrumb';
import { Container } from 'react-bootstrap';
import {
      AboutContent,
      AboutDetail,
      AboutItem,
      AboutTitle,
      BannerContainer,
      BannerImage
} from '../components/styles/aboutStyle';
import { Helmet } from 'react-helmet';

const OurPlant = () => {
      return (
            <>
                  <Helmet>
                        <link rel="canonical" href={window.location.href} />
                        <title> Our Plant | Prime Tiles with stile</title>
                  </Helmet>
                  <Breadcrumb title="Our Plant" summary="Our Plant" />
                  <Container>
                        <AboutContent>
                              <BannerContainer className="mb-3">
                                    <AboutItem>
                                          <AboutDetail className="p-0">
                                                <AboutTitle>
                                                      Our Plant
                                                </AboutTitle>
                                                <p>
                                                      Nestled in the quiet
                                                      environs of laid-back
                                                      Sakhuwa Dhamaura Brindavan
                                                      Municipality-6 in Rautahat
                                                      district, the production
                                                      unit of Prime Ceramics
                                                      catches attention from a
                                                      distance.
                                                </p>
                                                <p>
                                                      A grey-and-white combo of
                                                      style, the plant is an
                                                      obvious visitors’ delight.
                                                      Unlike a dusty tile plant
                                                      with winds spreading the
                                                      particles all over, this
                                                      manufacturing unit is no
                                                      less than any European
                                                      facility of similar
                                                      nature. The factory is
                                                      right along the highway,
                                                      in-between the
                                                      Chandranigahpur and Garuda
                                                      Road section (some 11
                                                      kilometers east of
                                                      Chandranigahpur).
                                                </p>
                                          </AboutDetail>
                                    </AboutItem>
                                    <BannerImage
                                          className="ms-md-5 ms-sm-0"
                                          image='url("/images/ourplant/plant2.jpg ")'
                                    />
                              </BannerContainer>
                              <BannerContainer>
                                    <BannerImage
                                          className="ms-sm-0"
                                          image='url("/images/ourplant/plant3.jpg ")'
                                    />
                                    <AboutItem className="w-100 d-block mt-0">
                                          <AboutDetail>
                                                <p>
                                                      As you enter the factory,
                                                      you are flanked by a
                                                      terracotta-finished temple
                                                      on the right and a modern
                                                      office building on the
                                                      left. You may then be
                                                      attracted to the tile
                                                      experience center on the
                                                      first floor of the office
                                                      building.
                                                </p>
                                                <p>
                                                      As you cross the parking
                                                      shed, you then begin to
                                                      appreciate the pylon as an
                                                      architectural feature. A
                                                      perfect photo cannot be
                                                      missed below the Prime
                                                      Tiles with <i> Stile </i>{' '}
                                                      signage affixed to the
                                                      feature. Take your turn if
                                                      you want to taste the
                                                      delicious and nutritious
                                                      food available at the
                                                      dining hall, which serves
                                                      both internal staff and
                                                      external guests. Walk
                                                      further to marvel at the
                                                      miracle of our laboratory,
                                                      which provides all kinds
                                                      of facilities when it
                                                      comes to the testing of
                                                      produced tiles.
                                                </p>
                                          </AboutDetail>
                                    </AboutItem>
                              </BannerContainer>
                              <BannerContainer>
                                    <AboutItem className="w-100 d-block mt-0">
                                          <AboutDetail>
                                                <p>
                                                      You can now enter the main
                                                      factory shed to your left.
                                                      The factory shed is 256
                                                      meters long and appears to
                                                      be a playground for tiles
                                                      that undergo the entire
                                                      production cycle,
                                                      beginning in the form of
                                                      raw powder and eventually
                                                      showing up as exquisitely
                                                      glazed tile.
                                                </p>
                                                <p>
                                                      Enjoy the view of
                                                      technological grandeur:
                                                      the rotating ball mills,
                                                      the gigantic spray dryer
                                                      touching the sky, the
                                                      press machine with tons of
                                                      load, the hot kiln running
                                                      at 1,200 degrees, the
                                                      digital printing machine,
                                                      the decoration applied by
                                                      the glaze line, the
                                                      robotic sorting line, the
                                                      tough sizing machine, and
                                                      finally the packaging
                                                      machine wrapping the
                                                      finished tile. If you have
                                                      time, please do visit the
                                                      raw material shed and gas
                                                      yard to see the tough part
                                                      of the pre-production
                                                      process.
                                                </p>
                                                <p>
                                                      Overall, you would find
                                                      SACMI Italy—Ceramics
                                                      Better, a testimony to the
                                                      perfection that the plant
                                                      borrows from the world
                                                      leader in ceramics
                                                      technology.
                                                </p>
                                          </AboutDetail>
                                    </AboutItem>
                                    <BannerImage
                                          className="ms-md-1 ms-sm-0"
                                          image='url("/images/ourplant/plant4.jpg ")'
                                    />
                              </BannerContainer>
                        </AboutContent>
                  </Container>
            </>
      );
};

export default OurPlant;
