import React, { useRef } from 'react';
import { Container } from 'react-bootstrap';
import { ContactContainer } from '../components/styles/contactStyle';
import ContactForm from './ContactForm';
import ContactLoader from '../components/styles/loaderStyle/ContactLoader';
import Breadcrumb from '../components/common/Breadcrumb';
import ContactMap from './ContactMap';
import { Helmet } from 'react-helmet';

const ContactPage = () => {
      let screen = useRef(null);
      let body = useRef(null);

      return (
            <>
                  <Helmet>
                        <link rel="canonical" href={window.location.href} />
                        <title>Contact Us| Prime Tiles with Stile</title>
                  </Helmet>
                  {/* <ContactLoader screen={screen} body={body} /> */}
                  <Breadcrumb title="Contact Us" summary="Contact Us" />
                  <Container>
                        <ContactContainer>
                              <ContactForm />
                              <ContactMap />
                        </ContactContainer>
                  </Container>
            </>
      );
};

export default ContactPage;
