import React from 'react';
import Breadcrumb from '../components/common/Breadcrumb';
import { Container } from 'react-bootstrap';
import {
      TermBody,
      TermBodyContent,
      TermBodyDescription,
      TermBodyTitle,
      TermContainer
} from '../components/styles/termStyle';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
      return (
            <>
                  <Helmet>
                        <link rel="canonical" href={window.location.href} />
                        <title>Privacy Policy | Prime Tiles with Stile</title>
                  </Helmet>
                  <Breadcrumb title="Privacy Policy" summary="Privacy Policy" />
                  <Container>
                        <TermContainer>
                              <TermBody>
                                    <TermBodyTitle>Summary</TermBodyTitle>
                                    <TermBodyContent>
                                          <TermBodyDescription>
                                                <p>
                                                      Prime Ceramics Pvt. Ltd
                                                      owns, maintains and
                                                      operates its official
                                                      website,
                                                      www.primeceramics.com.np.
                                                      Through this privacy
                                                      policy, we want our
                                                      visitors to the website to
                                                      understand what kinds of
                                                      information we may collect
                                                      from you, how we process
                                                      and use that information,
                                                      your choices regarding our
                                                      use of that information,
                                                      and your ability to review
                                                      correct, and/or opt out of
                                                      our use of that
                                                      information. This Privacy
                                                      Policy is applicable to
                                                      our interactions with our
                                                      customers, prospect
                                                      customers as well as
                                                      visitors, including, but
                                                      not limited to:
                                                </p>
                                                <ul>
                                                      <li>
                                                            Use of our website,
                                                            including, 3D tile
                                                            visualizer.
                                                      </li>
                                                      <li>
                                                            Phone and email
                                                            communications
                                                      </li>
                                                      <li>
                                                            Social media
                                                            interactions on our
                                                            Web Site and other
                                                            third party websites
                                                            such as Facebook,
                                                            Instagram, Whatsapp,
                                                            Pinterest, Twitter,
                                                            YouTube and
                                                            LinkedIn, among
                                                            others.
                                                      </li>
                                                      <li>
                                                            Viewing our online
                                                            advertisements or
                                                            email
                                                      </li>
                                                      <li>
                                                            Use of our
                                                            application for
                                                            mobile phones,
                                                            tablets or other
                                                            smart electronic as
                                                            well as
                                                            tele-communication
                                                            devices.
                                                      </li>
                                                      <li>
                                                            Through contacts
                                                            with our authorized
                                                            service providers
                                                      </li>
                                                      <li>
                                                            {' '}
                                                            Visits to our stores
                                                      </li>
                                                </ul>
                                                <p className="text-muted">
                                                      Note: By using our website
                                                      and/or by providing your
                                                      contact information to us
                                                      during that process, you
                                                      are accepting and agreeing
                                                      to the practices outlined
                                                      in this Privacy Policy.
                                                </p>
                                          </TermBodyDescription>
                                    </TermBodyContent>
                              </TermBody>
                              <TermBody>
                                    <TermBodyContent>
                                          <TermBodyTitle>
                                                INFORMATION WE COLLECT
                                          </TermBodyTitle>
                                          <TermBodyDescription>
                                                <p>
                                                      The type of information
                                                      that we may collect from
                                                      our website visitors:
                                                </p>
                                          </TermBodyDescription>
                                    </TermBodyContent>

                                    <TermBodyContent>
                                          <TermBodyTitle>
                                                Contact Information
                                          </TermBodyTitle>
                                          <TermBodyDescription>
                                                <p>
                                                      We collect the names of
                                                      our visitors to our
                                                      website. We also collect
                                                      transportation and billing
                                                      address, email address,
                                                      phone numbers, digital
                                                      contact information and
                                                      purchase histories.
                                                </p>
                                          </TermBodyDescription>
                                    </TermBodyContent>

                                    <TermBodyContent>
                                          <TermBodyTitle>
                                                Demographic Information
                                          </TermBodyTitle>
                                          <TermBodyDescription>
                                                <p>
                                                      We do collect visitor’s
                                                      demographic data
                                                      including, his/her name,
                                                      age, gender, places s/he
                                                      may buy tiles from,
                                                      reviews the visitor gives
                                                      us and tile products that
                                                      the visitor prefer.
                                                </p>
                                          </TermBodyDescription>
                                    </TermBodyContent>

                                    <TermBodyContent>
                                          <TermBodyTitle>
                                                Transaction Information
                                          </TermBodyTitle>
                                          <TermBodyDescription>
                                                <p>
                                                      We collect location
                                                      information obtained from
                                                      the visitor’s IP address
                                                      and use the same to help
                                                      find our nearest
                                                      distributor/dealer or
                                                      sub-dealer for the
                                                      visitor’s and provide the
                                                      information with driving
                                                      direction as well as
                                                      available products in
                                                      those stores. If the
                                                      visitor uses our mobile
                                                      application, we collect
                                                      location data that his/her
                                                      mobile device provides.
                                                </p>
                                          </TermBodyDescription>
                                    </TermBodyContent>

                                    <TermBodyContent>
                                          <TermBodyTitle>
                                                Employment Information
                                          </TermBodyTitle>
                                          <TermBodyDescription>
                                                <p>
                                                      If the visitor applies for
                                                      employment with us via our
                                                      official website, we ask
                                                      for certain personal
                                                      information including, job
                                                      application, resume or
                                                      similar employment-related
                                                      materials.
                                                </p>
                                          </TermBodyDescription>
                                    </TermBodyContent>

                                    <TermBodyContent>
                                          <TermBodyTitle>
                                                The Process of Information
                                                Collection
                                          </TermBodyTitle>
                                          <TermBodyDescription>
                                                <p>
                                                      We collect two types of
                                                      information from the
                                                      visitors through our
                                                      official website –
                                                      anonymous information and
                                                      personally identifiable
                                                      information.
                                                </p>
                                                <p>
                                                      Anonymous information does
                                                      not personally identify
                                                      the visitors, by itself or
                                                      in combination with other
                                                      information. We gather
                                                      anonymous information
                                                      including, type of browser
                                                      the visitor uses, IP
                                                      address and the duration
                                                      of visitor’s visit to our
                                                      website.
                                                </p>
                                                <p>
                                                      Personally identifiable
                                                      information includes the
                                                      visitor’s name, address,
                                                      telephone number, email
                                                      address, social media user
                                                      names, transaction
                                                      information and credit
                                                      card information, among
                                                      others. In all cases the
                                                      visitor can choose whether
                                                      s/he provide us with
                                                      personally identifiable
                                                      information, but s/he may
                                                      not be able to make
                                                      purchases, access certain
                                                      offers, or certain portion
                                                      of the website, if s/he
                                                      choose not to provide such
                                                      information to us.
                                                </p>
                                          </TermBodyDescription>
                                    </TermBodyContent>

                                    <TermBodyContent>
                                          <TermBodyTitle>
                                                Cookies and Tracking
                                          </TermBodyTitle>
                                          <TermBodyDescription>
                                                <p>
                                                      We may send one or more
                                                      cookies to the visitor’s
                                                      computer or other devices
                                                      when s/he visit our
                                                      website. We may also use
                                                      cookies in emails to send
                                                      to the visitors. We use
                                                      cookies to improve the
                                                      visitor’s experience on
                                                      our website. We may also
                                                      use cookies to remind us
                                                      of who the visitor is and
                                                      to customize our products
                                                      to serve the personal
                                                      interest of the customers.
                                                      We can also use other
                                                      online tracking tools. The
                                                      information we gather
                                                      through the use of these
                                                      tools include, visitor’s
                                                      IP address, language s/he
                                                      speaks, operating system,
                                                      browser type, screen
                                                      resolution, connection
                                                      type and information that
                                                      identifies the cookies.
                                                </p>
                                          </TermBodyDescription>
                                    </TermBodyContent>

                                    <TermBodyContent>
                                          <TermBodyTitle>
                                                The Use of Visitors’ Information
                                          </TermBodyTitle>
                                          <TermBodyDescription>
                                                <p>
                                                      We use the information
                                                      collected from the
                                                      visitors to process,
                                                      fulfill and ensure proper
                                                      delivery of their orders
                                                      for our products, send
                                                      electronic or physical
                                                      copy of receipts, manage
                                                      their participation in
                                                      contest, to register for
                                                      loyalty program, to
                                                      conduct different kinds of
                                                      surveys and to launch
                                                      different marketing
                                                      campaigns, among others.
                                                      The information might also
                                                      be used for statistical
                                                      purposes, to maintain
                                                      database, to respond to
                                                      the customer’s requests
                                                      and reviews, to improve
                                                      and personalize the
                                                      website, to customize
                                                      customer’s experience with
                                                      us, to prevent fraud, to
                                                      find eligible candidates
                                                      for job opportunities and
                                                      the more.
                                                </p>
                                          </TermBodyDescription>
                                    </TermBodyContent>

                                    <TermBodyContent>
                                          <TermBodyTitle>
                                                Confidentiality
                                          </TermBodyTitle>
                                          <TermBodyDescription>
                                                <p>
                                                      We do use and disclose the
                                                      visitor’s information only
                                                      for our internal, business
                                                      and as legally required
                                                      purposes only.
                                                </p>
                                                <p>
                                                      We do commit and promise
                                                      that not even a bit amount
                                                      of information collected
                                                      from the visitors will be
                                                      manipulated, processed,
                                                      shared, sold or exchanged.
                                                      We do fully ensure
                                                      complete confidentiality
                                                      of our visitors from our
                                                      end.
                                                </p>
                                          </TermBodyDescription>
                                    </TermBodyContent>
                              </TermBody>
                        </TermContainer>
                  </Container>
            </>
      );
};

export default PrivacyPolicy;
