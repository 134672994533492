import { Card, Table } from 'react-bootstrap';
import styled from 'styled-components';

export const CareerBody = styled.div`
      padding: 20px;

      ul {
            padding: 0;
      }

      a {
            color: #333;
            display: inline-block;
            text-align: center;
            margin-top: auto;
      }

      p {
            font-size: 16px;
            margin-top: 10px;
            text-align: justify;
            font-weight: 500;
      }
`;

export const CareerCard = styled(Card)`
      background-color: #fff;
      border: 1px solid #ced4da;
      color: #515151;
      padding: 16px;

      label {
            font-size: 18px;
      }

      input,
      select,
      textarea {
            border-radius: 0;
      }

      select:focus {
            box-shadow: none;
      }
`;

export const CareerHeader = styled.div`
      display: flex;
      justify-content: space-between;
      width: 100%;
      background-color: #fff;
      padding: 10px;
      border-radius: 5px;
      border: 1px solid rgb(206, 212, 218);

      a {
            display: block;
            text-decoration: none;
            width: 100%;
            color: ${(props) => props.theme.primaryColor};

            &:hover + div {
                  color: ${(props) => props.theme.primaryColor};
            }
      }

      @media screen and (max-width: 600px) {
            justify-content: left;
            display: block;
            padding: 0;
      }
`;

export const CareerHeaderImage = styled.div`
      display: flex;
      gap: 20px;

      div {
            font-weight: 600;
            font-size: 18px;
            letter-spacing: 0.5px;
            width: 100%;
            color: ${(props) => props.theme.black} !important;

            div {
                  &:last-child {
                        font-size: 16px;
                        color: #666 !important;
                  }
            }
      }
      img {
            width: 60px;
            background-color: ${(props) => props.theme.white};
            border: 1px solid ${(props) => props.theme.borderColor};
      }
`;

export const CareerHeaderIcon = styled.div`
      display: flex;
      gap: 10px;

      div {
            margin-top: 2.5px;
            font-size: ${(props) => props.fontSize};
            font-weight: ${(props) => props.fontWeigth};
      }

      @media screen and (max-width: 600px) {
            display: none;
      }
`;

export const CareerLink = styled.div`
      width: 100%;
      margin-top: 20px;

      a {
            background-color: ${(props) => props.theme.primaryColor};
            color: ${(props) => props.theme.white};
            padding: 10px 16px;
            display: block;
            text-align: center;
            width: fit-content;

            &:hover {
                  background-color: ${(props) => props.theme.secondaryColor};
            }
      }
`;

export const CareerTitle = styled.div`
      border-bottom: 1px solid #ced4da;
      font-size: 18px;
      font-weight: 600;
      padding-bottom: 10px;
      padding-top: 10px;
`;

export const CareerTable = styled(Table)`
      strong {
            font-size: 24px;
      }

      td {
            font-size: 16px;
      }

      ul {
            li {
                  list-style: disc !important;
            }
      }
`;
