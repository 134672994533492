import React from 'react';
import { Link } from 'react-router-dom';

const FooterCategoryItem = ({
      slug,
      title,
      type
}) => {
      return (
            <>
                  <li>
                        <Link to={`${type ? '/products/' + type : ''}/${slug}`}>
                              {title}
                        </Link>
                  </li>
            </>
      )
}

export default FooterCategoryItem;
