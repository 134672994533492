import styled from 'styled-components';

export const OtherHeaderBox = styled.header`
      position: relative;
      background-color: white;
`;

export const OtherHeaderContainer = styled.div`
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media screen and (max-width: 1200px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px;
            background-color: ${(props) => props.theme.white};
            position: fixed;
            width: 100%;
            border-bottom: 1px solid #dee2e6;
            z-index: 260;
      }
`;

export const OtherHeaderShape = styled.div`
      position: absolute;
      top: 30px;
      left: 20px;
      clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
      width: 20px;
      height: 20px;
      background-color: ${(props) => props.theme.primaryColor};
      z-index: 1000000;
`;
