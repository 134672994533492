import React from 'react';
import PrimeTitle from './frontend/components/loader/PrimeTitle';

const Loader = () => {
      return (
            <>
                  <div id="preloader">
                        <div className='prime-loader'>
                              <PrimeTitle classes="prime-title" />
                        </div>
                  </div>
            </>
      )
}

export default Loader;