import React from 'react';

function ColorIcon() {
      return (
            <div>
                  <svg
                        enableBackground="new 0 0 520 520"
                        viewBox="0 0 520 520"
                        xmlns="http://www.w3.org/2000/svg"
            id="fi_2613288"
            width="30"
            height="30"
                  >
                        <g id="_x31_000_x2C__Art_x2C__Color_x2C__Design_x2C__Palette_x2C__Swatch">
                              <g>
                                    <path d="m480 331.331h-82.237l87.187-49.659c4.799-2.733 6.474-8.84 3.74-13.639-71.9-126.196-69.563-122.733-71.668-124.45-2.733-2.23-6.547-2.918-10-1.535-1.011.399 3.788-2.247-72.354 40.894l40.634-62.639c3.004-4.63 1.685-10.826-2.947-13.832l-117.453-76.191c-4.634-3.006-10.827-1.687-13.832 2.947l-51.07 78.726v-70.622c0-5.523-4.477-10-10-10h-140c-5.523 0-10 4.477-10 10v440c0 5.522 4.477 10 10 10h440c5.522 0 10-4.478 10-10v-140c0-5.522-4.478-10-10-10zm-290 118.336 40-22.783v44.447h-40zm60-34.175 40-22.782v78.621h-40zm60-34.174 40-22.783v112.796h-40c0-11.28 0-78.777 0-90.013zm60-29.987h40v120h-40zm36.961-186.379 59.4 104.29c-12.084 6.883-263.816 150.263-276.361 157.408v-20.702l121.079-186.646zm-154.554-112.452 100.672 65.307-163.079 251.392v-220.497zm-82.407 418.831h-120v-420h120zm300 0h-40v-120h40z"></path>
                                    <path d="m110 451.331c22.056 0 40-17.944 40-40s-17.944-40-40-40-40 17.944-40 40 17.944 40 40 40zm0-60c11.028 0 20 8.972 20 20s-8.972 20-20 20-20-8.972-20-20 8.972-20 20-20z"></path>
                              </g>
                        </g>
                  </svg>
            </div>
      );
}

export default ColorIcon;
