import React from 'react';
import { useParams } from 'react-router-dom';

const CatalogueDetail = () => {
      const { catalgoueId } = useParams();

      return (
            <>
                  <iframe
                        src={`${process.env.REACT_APP_SECRET_KEY}/catalogues/${catalgoueId}`}
                        style={{ border: 0, width: '100%', height: 'calc(100vh - 70px)', overflow: 'hidden' }}
                        allowFullscreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"></iframe>
            </>
      )
}

export default CatalogueDetail;
