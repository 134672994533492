import React from 'react';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay } from 'swiper';
import { TrendingWrapper } from '../styles/homeStyle/trendingTileStyle';

const HomeTrendingSlider = () => {
      return (
            <>
                  <Swiper
                        slidesPerView={1}
                        spaceBetween={20}
                        autoplay={{
                              delay: 2000,
                              disableOnInteraction: false
                        }}
                        breakpoints={{
                              640: {
                                    slidesPerView: 2,
                                    spaceBetween: 10
                              },
                              768: {
                                    slidesPerView: 2,
                                    spaceBetween: 10
                              },
                              1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 30
                              }
                        }}
                        modules={[Autoplay]}
                        className="trending-slider"
                  >
                        <SwiperSlide>
                              <TrendingWrapper>
                                    <Link to="/">
                                          <img
                                                src="images/img/home/trending/trending1.jpeg"
                                                alt="Prime Ceramics - Trending Tiles"
                                                width="100%"
                                                height="100%"
                                          ></img>
                                    </Link>
                              </TrendingWrapper>
                              <TrendingWrapper>
                                    <Link to="/">
                                          <img
                                                src="images/img/home/trending/trending3.png"
                                                alt="Prime Ceramics - Trending Tiles"
                                                width="100%"
                                                height="100%"
                                          ></img>
                                    </Link>
                              </TrendingWrapper>
                        </SwiperSlide>
                        <SwiperSlide>
                              <TrendingWrapper>
                                    <Link to="/">
                                          <img
                                                src="images/img/home/trending/trending4.png"
                                                alt="Prime Ceramics - Trending Tiles"
                                                width="100%"
                                                height="100%"
                                          ></img>
                                    </Link>
                              </TrendingWrapper>
                              <TrendingWrapper>
                                    <Link to="/">
                                          <img
                                                src="images/img/home/trending/trending5.png"
                                                alt="Prime Ceramics - Trending Tiles"
                                                width="100%"
                                                height="100%"
                                          ></img>
                                    </Link>
                              </TrendingWrapper>
                        </SwiperSlide>
                        <SwiperSlide>
                              <TrendingWrapper>
                                    <Link to="/">
                                          <img
                                                src="images/img/home/trending/trending2.jpeg"
                                                alt="Prime Ceramics - Trending Tiles"
                                                width="100%"
                                                height="100%"
                                          ></img>
                                    </Link>
                              </TrendingWrapper>
                              <TrendingWrapper>
                                    <Link to="/">
                                          <img
                                                src="images/img/home/trending/trending6.png"
                                                alt="Prime Ceramics - Trending Tiles"
                                                width="100%"
                                                height="100%"
                                          ></img>
                                    </Link>
                              </TrendingWrapper>
                        </SwiperSlide>
                  </Swiper>
            </>
      );
};

export default HomeTrendingSlider;
