import React from 'react';

const Visualizer = () => {
    return (
        <>
            <iframe
                src={`${process.env.REACT_APP_VISUALIZER_KEY}/rooms`}

                style={{ border: 0, width: '100%', height: 'calc(100vh - 8px)', overflow: 'hidden' }}
                allowFullscreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
        </>
    )
}

export default Visualizer;
