import React from 'react';
import {
      FooterCompany,
      FooterCopyright,
      FooterCredential,
      FooterCredentialContainer
} from '../../frontend/components/layouts/footer';
import { Link } from 'react-router-dom';

export const CopyrightFooter = () => {
      return (
            <>
                  <FooterCredential>
                        <FooterCredentialContainer>
                              <FooterCopyright>
                                    <span>Copyright © 2022</span>{' '}
                                    <Link to="#" className="text-white">
                                          Prime Ceramics Pvt. Ltd
                                    </Link>
                                    . All Rights Reserved
                              </FooterCopyright>
                              <FooterCompany>
                                    Powered By{' '}
                                    <Link
                                          className="text-white"
                                          to="https://infinityinfosys.com"
                                          target="_blank"
                                          title="Infinity Infosys "
                                    >
                                          Infinity Infosys
                                    </Link>
                              </FooterCompany>
                        </FooterCredentialContainer>
                  </FooterCredential>
            </>
      );
};
