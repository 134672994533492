import {
      TestimonialFooter,
      TestimonialSliderContent,
      TestimonialSliderImage,
      TestimonialSliderName,
      TestimonialSliderPosition,
      TestimonialSliderText
} from '../styles/homeStyle/testimonialStyle';

import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import axios from 'axios';

const HomeTestimonialSlider = () => {
      const [testimonials, setTestimonials] = useState([]);
      const [loading, setLoading] = useState(false);

      useEffect(() => {
            const loadData = async () => {
                  setLoading(true);
                  await axios
                        .get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/testimonials`
                        )
                        .then((response) => {
                              if (response.data.result == 'success') {
                                    setTestimonials(response.data.testimonials);
                              }
                              setLoading(false);
                        });
            };

            loadData();
      }, []);

      return (
            <>
                  <Swiper
                        slidesPerView={1}
                        spaceBetween={40}
                        breakpoints={{
                              640: {
                                    slidesPerView: 1,
                                    spaceBetween: 10
                              },
                              768: {
                                    slidesPerView: 2,
                                    spaceBetween: 10
                              },
                              1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 30
                              }
                        }}
                        className="mySwiper"
                  >
                        {!loading ? (
                              <>
                                    {testimonials.length > 0 && (
                                          <>
                                                {testimonials.map(
                                                      (testimonial, index) => (
                                                            <SwiperSlide>
                                                                  <TestimonialSliderContent>
                                                                        <img
                                                                              src="/images/img/home/testimonial/testimonialmarker.png"
                                                                              alt={
                                                                                    testimonial.full_name
                                                                              }
                                                                        />
                                                                        <TestimonialSliderText>
                                                                              <p>
                                                                                    {
                                                                                          testimonial.message
                                                                                    }
                                                                              </p>
                                                                        </TestimonialSliderText>
                                                                        <TestimonialFooter>
                                                                              <TestimonialSliderImage>
                                                                                    <img
                                                                                          alt={
                                                                                                testimonial.full_name
                                                                                          }
                                                                                          src={`${process.env.REACT_APP_SECRET_KEY}/frontend/images/testimonials/${testimonial.image}`}
                                                                                          width="100%"
                                                                                          height="100%"
                                                                                    />
                                                                              </TestimonialSliderImage>
                                                                              <div>
                                                                                    <TestimonialSliderName>
                                                                                          {
                                                                                                testimonial.full_name
                                                                                          }
                                                                                    </TestimonialSliderName>
                                                                                    <TestimonialSliderPosition>
                                                                                          {
                                                                                                testimonial.company_name
                                                                                          }

                                                                                          ,{' '}
                                                                                          {
                                                                                                testimonial.position
                                                                                          }
                                                                                    </TestimonialSliderPosition>
                                                                              </div>
                                                                        </TestimonialFooter>
                                                                  </TestimonialSliderContent>
                                                            </SwiperSlide>
                                                      )
                                                )}
                                          </>
                                    )}
                              </>
                        ) : (
                              <>Loading</>
                        )}
                  </Swiper>
            </>
      );
};

export default HomeTestimonialSlider;
