import React, { useEffect, useState } from 'react';
import {
      BrandBox,
      BrandContainer,
      BrandContent,
      BrandContentDescription,
      BrandContentTitle,
      BrandHeading,
      BrandSlider
} from '../components/styles/homeStyle/brandStyle';
import { Row, Container, Col } from 'react-bootstrap';
import HomeBrandSlider from '../components/sliders/HomeBrandSlider';
import axios from 'axios';

const BrandSection = () => {
      const [loading, setLoading] = useState(false);
      const [clients, setClients] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/clients`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setClients(response.data.clients);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <BrandBox>
                        <BrandContainer>
                              <Container>
                                    <Row>
                                          <Col lg={6}>
                                                <BrandSlider>
                                                      {!loading
                                                            ? clients?.length >
                                                                    0 && (
                                                                    <HomeBrandSlider
                                                                          clients={
                                                                                clients
                                                                          }
                                                                    />
                                                              )
                                                            : null}
                                                </BrandSlider>
                                          </Col>
                                          <Col lg={6}>
                                                <BrandContent>
                                                      <BrandContentTitle>
                                                            <BrandHeading>
                                                                  <h2>
                                                                        Our
                                                                        <span>
                                                                              Clients
                                                                        </span>
                                                                  </h2>
                                                            </BrandHeading>
                                                      </BrandContentTitle>
                                                      <BrandContentDescription>
                                                            <p>
                                                                  Our clients
                                                                  are essential
                                                                  partners in
                                                                  our success,
                                                                  bringing
                                                                  valuable
                                                                  insights,
                                                                  diverse
                                                                  perspectives,
                                                                  and unique
                                                                  challenges
                                                                  that inspire
                                                                  us to
                                                                  continuously
                                                                  innovate and
                                                                  improve.
                                                            </p>
                                                      </BrandContentDescription>
                                                </BrandContent>
                                          </Col>
                                    </Row>
                              </Container>
                        </BrandContainer>
                  </BrandBox>
            </>
      );
};

export default BrandSection;
