import styled from 'styled-components';

export const VisualizerContainer = styled.section`
      padding: 197px 0 112px;

      @media screen and (max-width: 1200px) {
            .col-lg-6 {
                  &:first-child {
                        order: 2;
                  }

                  &:last-child {
                        order: 1;
                  }
            }

            padding: 50px 0 112px;
      }
      @media screen and (max-width: 767px) {
            padding: 30px 0 70px;
      }
`;

export const VisualizerContent = styled.div`
      transform: translate(140px, 60px);

      @media screen and (max-width: 1200px) {
            transform: translate(0, 0);
            padding-top: 60px;
      }
`;

export const VisualizerTitle = styled.div`
      position: relative;

      h2 {
            font-style: italic;
            font-weight: 300;
            font-size: 3.125rem;
            line-height: 3.813rem;
      }

      span {
            color: ${(props) => props.theme.primaryColor};
      }

      &::after {
            content: url(/images/png/trendingtitle.png);
            position: absolute;
            left: 0;
            left: 0;
            top: -75px;
            z-index: -1;
      }
      @media screen and (max-width: 767px) {
            h2 {
                  font-size: 2rem;
            }
      }
`;

export const VisualizerDescription = styled.div`
      margin-right: 3rem;
      margin-bottom: 1.5rem;

      p {
            color: #555;
            font-weight: 300;
            font-size: 1.125rem;
      }
      @media screen and (max-width: 767px) {
            margin-right: 0;
            p {
                  font-size: 1rem;
                  text-align: justify;
            }
      }
`;

export const VisualizerVideo = styled.div`
      transform: translateY(-242px);

      @media screen and (max-width: 1200px) {
            transform: translateY(0);
      }
`;

export const VisualizerContentButton = styled.div`
      width: 145px;
      height: 40px;
      border: 1px solid ${(props) => props.theme.primaryColor};
      /* box-shadow: 0px 4px 4px ${(props) => props.theme.footerColor}; */
      border-radius: 5px;
      padding: 7px;
      text-align: center;

      a {
            display: block;
            color: ${(props) => props.theme.primaryColor};
      }
      &:hover {
            background-color: ${(props) => props.theme.primaryColor};

            a {
                  color: ${(props) => props.theme.white};
            }
      }
`;
