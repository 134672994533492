import React from 'react';
import './style.css';
import Breadcrumb from '../components/common/Breadcrumb';
import { Container } from 'react-bootstrap';
import {
      AboutContent,
      AboutDetail,
      AboutDetailSummary,
      AboutItem,
      AboutTitle,
      BannerContainer,
      BannerImage
} from '../components/styles/aboutStyle';
import { Helmet } from 'react-helmet';

const About = () => {
      return (
            <>
                  <Helmet>
                        <link rel="canonical" href={window.location.href} />
                        <title>
                              About Prime Ceramics - Leading Tiles Company in
                              Nepal
                        </title>
                  </Helmet>
                  <Breadcrumb title="About Us" summary="About Us" />

                  <Container>
                        <AboutContent>
                              <BannerContainer>
                                    <AboutItem>
                                          <AboutDetail>
                                                <AboutTitle>
                                                      About Prime Tiles with{' '}
                                                      <i>Stile</i>
                                                </AboutTitle>
                                                <p>
                                                      Prime Ceramics Private
                                                      Limited (PCPL) was
                                                      incorporated in Nepal with
                                                      a primary objective of
                                                      manufacturing high-quality
                                                      ceramic tiles for Nepalese
                                                      consumers. The
                                                      Manufacturing facility is
                                                      located at Rautahat, Nepal
                                                      with an annual capacity of
                                                      4 million sq. meters.
                                                </p>
                                                <p>
                                                      The promoters of Prime
                                                      Ceramics include CMS Group
                                                      and Fortune Ventures,
                                                      leading business houses in
                                                      Nepal. They have more than
                                                      two decade of experience
                                                      in providing construction
                                                      material solutions to
                                                      Nepalese market including
                                                      more than 50 products of
                                                      international repute. In
                                                      the tiles segment, the
                                                      promoters command leading
                                                      position in Nepal. Driven
                                                      by the desire to provide
                                                      homemade high-class,
                                                      international-quality
                                                      tiles at competitive
                                                      price, PCPL was born.
                                                </p>
                                                <p>
                                                      The Nepalese market is all
                                                      set to be redefined with
                                                      an innovative and state of
                                                      art manufacturing facility
                                                      in Nepal and for Nepal.
                                                      The Plant boasts one of
                                                      its kind set up which can
                                                      meet the ever-changing
                                                      requirements of the
                                                      consumers aspiring for a
                                                      better and prosperous
                                                      home. The team at PCPL is
                                                      proud for having unleashed
                                                      a product that will
                                                      contribute significantly
                                                      to raising living standard
                                                      of Nepalese society.
                                                </p>
                                          </AboutDetail>
                                    </AboutItem>
                                    <BannerImage image='url("/images/ourplant/plant1.jpg")' />
                              </BannerContainer>

                              <BannerContainer>
                                    <BannerImage image='url("/images/ourplant/vision.jpg")' />
                                    <AboutItem padding="20px">
                                          <AboutDetail className="p-0">
                                                <AboutTitle>
                                                      Guiding philosophy
                                                </AboutTitle>
                                                <p>
                                                      <span>
                                                            <ul>
                                                                  <li>
                                                                        Prime
                                                                        never
                                                                        compromises
                                                                        on one
                                                                        element—Quality,
                                                                        Quality,
                                                                        and
                                                                        Quality.
                                                                  </li>
                                                                  <li>
                                                                        Prime
                                                                        aspires
                                                                        to
                                                                        enhance
                                                                        the
                                                                        living
                                                                        standards
                                                                        of the
                                                                        common
                                                                        man with
                                                                        - Stile,
                                                                        Stile,
                                                                        and
                                                                        Stile.
                                                                  </li>
                                                                  <li>
                                                                        Prime
                                                                        has a
                                                                        fire to
                                                                        create
                                                                        the
                                                                        unimaginable
                                                                        -
                                                                        Passion,
                                                                        Passion,
                                                                        and
                                                                        Passion
                                                                  </li>
                                                                  <li>
                                                                        Prime
                                                                        produces
                                                                        tile
                                                                        which
                                                                        are -
                                                                        Sustainable
                                                                        Sustainable
                                                                        and
                                                                        Sustainable
                                                                  </li>
                                                                  <li>
                                                                        Prime is
                                                                        - Prime,
                                                                        Prime
                                                                        and
                                                                        Prime –
                                                                        Tiles
                                                                        with
                                                                        Stile
                                                                  </li>
                                                            </ul>
                                                      </span>
                                                </p>
                                                <p>
                                                      <AboutDetailSummary>
                                                            VISION
                                                      </AboutDetailSummary>
                                                      <span>
                                                            To manufacture
                                                            world-class tiles
                                                            and be the industry
                                                            leader with
                                                            continuous
                                                            innovation,
                                                            creativity, and
                                                            passion to deliver
                                                            the best.
                                                      </span>
                                                </p>
                                                <p>
                                                      <AboutDetailSummary>
                                                            Mission
                                                      </AboutDetailSummary>
                                                      To become a leading tile
                                                      manufacturing company by
                                                      producing tiles that are
                                                      stylish and provide
                                                      customers with value at
                                                      affordable prices.
                                                      Gradually scale up
                                                      capacity to meet the
                                                      aspirations of Nepalese
                                                      customers and expand
                                                      horizons to the region and
                                                      the world. Practice best
                                                      industry norms in the
                                                      manufacturing process,
                                                      employment, environment,
                                                      and sustainability to lead
                                                      by example.
                                                </p>
                                          </AboutDetail>
                                    </AboutItem>
                              </BannerContainer>
                        </AboutContent>
                  </Container>
            </>
      );
};

export default About;
