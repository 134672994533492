import React, { useEffect, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import { Link, useParams } from 'react-router-dom';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

import './style.css';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper';
import axios from 'axios';
import Breadcrumb from '../components/common/Breadcrumb';
import { Container } from 'react-bootstrap';
import RelatedProduct from './RelatedProduct';
import { Helmet } from 'react-helmet';

const ProductDetail = () => {
      const [loading, setLoading] = useState(false);
      const { productId } = useParams();
      const [product, setProduct] = useState([]);
      const [products, setProducts] = useState([]);
      const [thumbsSwiper, setThumbsSwiper] = useState(null);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/products/${productId}`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setLoading(false);
                              setProducts(response.data.products);
                              setProduct(response.data.product);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  {!loading && (
                        <Helmet>
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                              <title>
                                    Prime{' '}
                                    {`${product?.category} ${product.title} in Nepal`}
                              </title>
                              <meta
                                    name="keywords"
                                    content="prime ceramics, prime ceramic tiles, tiles in nepal, nepali tiles manufacturers, nepali ceramic tiles manufacturers, nepali vitrified tiles manufacturers, nepali porcelain tiles manufacturers, nepali marble tiles manufacturers, nepali granite tiles manufacturers, nepali bathroom tiles manufacturers, nepali kitchen tiles manufacturers, nepali floor tiles manufacturers, nepali wall tiles manufacturers, nepali outdoor tiles manufacturers"
                              />
                              <meta
                                    name="description"
                                    content="Prime Ceramics - Tile with Stile: Your premier choice for high-quality tile manufacturing in Nepal. As a leading tile factory in Nepal, Prime Ceramics offers a wide range of Nepali ceramic tiles, including durable floor tiles, decorative wall tiles, vitrified tiles, porcelain tiles, marble tiles, granite tiles, bathroom tiles, and kitchen tiles. Discover innovative tile solutions and custom designs with Prime Ceramics - your trusted name in quality tiles in Nepal."
                              />

                              <meta
                                    property="og:url"
                                    content={window.location.url}
                              />
                              <meta
                                    property="og:title"
                                    content={`${product.title} in Nepal | Prime Ceramics -
                                    Tile with Stile`}
                              />
                              <meta
                                    name="og:description"
                                    content={
                                          product.description ||
                                          'Prime Ceramics - Tile with Stile: Your premier choice for high-quality tile manufacturing in Nepal. As a leading tile factory in Nepal, Prime Ceramics offers a wide range of Nepali ceramic tiles, including durable floor tiles, decorative wall tiles, vitrified tiles, porcelain tiles, marble tiles, granite tiles, bathroom tiles, and kitchen tiles. Discover innovative tile solutions and custom designs with Prime Ceramics - your trusted name in quality tiles in Nepal.'
                                    }
                              />
                              <meta property="og:type" content="article" />
                              {product.image && (
                                    <meta
                                          property="og:image"
                                          content={product.image}
                                    />
                              )}
                              <meta
                                    property="og:image:type"
                                    content="image/png"
                              />
                              <meta property="og:image:width" content="1000" />
                              <meta property="og:image:height" content="500" />
                        </Helmet>
                  )}
                  <Breadcrumb
                        summary={`${product.title}`}
                        title={`${product.title}`}
                        otherLinkTitle="Products"
                        otherLink="/products"
                  />
                  <Container>
                        <div className="product__container">
                              <div className="row">
                                    <div className="col-md-5">
                                          <div className="product__image-slider">
                                                <Swiper
                                                      style={{
                                                            '--swiper-navigation-color':
                                                                  '#fff',
                                                            '--swiper-pagination-color':
                                                                  '#fff'
                                                      }}
                                                      spaceBetween={10}
                                                      navigation={true}
                                                      thumbs={{
                                                            swiper: thumbsSwiper
                                                      }}
                                                      modules={[
                                                            FreeMode,
                                                            Navigation,
                                                            Thumbs
                                                      ]}
                                                      className="mySwiper2"
                                                >
                                                      <SwiperSlide className="product__image">
                                                            <img
                                                                  className="w-100"
                                                                  src={
                                                                        product.image
                                                                  }
                                                            />
                                                      </SwiperSlide>
                                                </Swiper>
                                                <Swiper
                                                      onSwiper={setThumbsSwiper}
                                                      spaceBetween={10}
                                                      slidesPerView={4}
                                                      freeMode={true}
                                                      watchSlidesProgress={true}
                                                      modules={[
                                                            FreeMode,
                                                            Navigation,
                                                            Thumbs
                                                      ]}
                                                      className="mySwiper"
                                                >
                                                      <SwiperSlide className="slider__thumb">
                                                            <img
                                                                  src={
                                                                        product.image
                                                                  }
                                                            />
                                                      </SwiperSlide>
                                                </Swiper>
                                          </div>
                                    </div>
                                    <div className="col-md-7">
                                          <div className="product__header">
                                                <div className="product__title border-0">
                                                      {product.title}
                                                </div>
                                                {product.price > 0 && (
                                                      <div className="product__amount">
                                                            <div className="product__price">
                                                                  MRP
                                                                  <span>
                                                                        Rs
                                                                        {
                                                                              product.price
                                                                        }
                                                                  </span>
                                                            </div>
                                                            <div className="product__select">
                                                                  <select>
                                                                        <option>
                                                                              /sq.ft
                                                                        </option>
                                                                        <option>
                                                                              /sq.meter
                                                                        </option>
                                                                  </select>
                                                            </div>
                                                      </div>
                                                )}
                                          </div>
                                          <div className="product__body">
                                                <div className="product__detail">
                                                      <div className="product__addition mt-0">
                                                            <div className="product__addition-title">
                                                                  Details
                                                            </div>
                                                            <div className="product__description-detail">
                                                                  <ul>
                                                                        <li>
                                                                              <div>
                                                                                    Material
                                                                              </div>
                                                                              {
                                                                                    product.category
                                                                              }
                                                                        </li>
                                                                        <li>
                                                                              <div>
                                                                                    Finish
                                                                              </div>
                                                                              {
                                                                                    product.finish
                                                                              }
                                                                        </li>
                                                                        <li>
                                                                              <div>
                                                                                    Type
                                                                              </div>
                                                                              {
                                                                                    product.type
                                                                              }
                                                                        </li>
                                                                        <li>
                                                                              <div>
                                                                                    Size
                                                                              </div>
                                                                              {
                                                                                    product.size
                                                                              }
                                                                        </li>
                                                                        <li>
                                                                              <div>
                                                                                    Area
                                                                              </div>
                                                                              {
                                                                                    product.area
                                                                              }
                                                                        </li>
                                                                        <li>
                                                                              <div>
                                                                                    Design
                                                                              </div>
                                                                              {
                                                                                    product.design
                                                                              }
                                                                        </li>
                                                                        <li>
                                                                              <div>
                                                                                    Collection
                                                                              </div>
                                                                              {
                                                                                    product.collection
                                                                              }
                                                                        </li>
                                                                  </ul>
                                                            </div>
                                                      </div>
                                                </div>
                                                <div className="product__detail">
                                                      <div className="product__addition">
                                                            <div className="product__addition-title">
                                                                  Additional
                                                                  Tools
                                                            </div>
                                                            <div className="product__addition-description">
                                                                  <Link
                                                                        to="/dealer-contact"
                                                                        className="product__addition-item"
                                                                  >
                                                                        <div className="product__addition-image">
                                                                              <i className="bx bxs-map"></i>
                                                                        </div>
                                                                        <div className="product__addition-name">
                                                                              Find
                                                                              a
                                                                              Showroom
                                                                        </div>
                                                                  </Link>
                                                                  {/* <div className="product__addition-item">
                                                                        <div className="product__addition-image">
                                                                              <i className="bx bxs-cart-alt"></i>
                                                                        </div>
                                                                        <div className="product__addition-name">
                                                                              Order
                                                                              Sample
                                                                        </div>
                                                                  </div> */}
                                                                  {/* <div className="product__addition-item">
                                                                        <div className="product__addition-image">
                                                                              <i className="bx bxs-download"></i>
                                                                        </div>
                                                                        <div className="product__addition-name">
                                                                              Hi-Res
                                                                              Image
                                                                        </div>
                                                                  </div> */}
                                                                  <div className="product__addition-item">
                                                                        <div className="product__addition-image">
                                                                              <i className="bx bxs-file"></i>
                                                                        </div>
                                                                        <div className="product__addition-name">
                                                                              Request
                                                                              Quotation
                                                                        </div>
                                                                  </div>
                                                                  <div className="product__addition-item">
                                                                        <div className="product__addition-image">
                                                                              <i className="bx bxs-widget"></i>
                                                                        </div>
                                                                        <div className="product__addition-name">
                                                                              Laying
                                                                              Patterns
                                                                        </div>
                                                                  </div>
                                                                  {/* <div className="product__addition-item">
                                                                        <div className="product__addition-image">
                                                                              <i className='bx bxs-calculator' ></i>
                                                                        </div>
                                                                        <div className="product__addition-name">
                                                                              Till Calculator
                                                                        </div>
                                                                  </div> */}
                                                                  <div className="product__addition-item">
                                                                        <div className="product__addition-image">
                                                                              <i className="bx bxs-file-pdf"></i>
                                                                        </div>
                                                                        <div className="product__addition-name">
                                                                              Specifications
                                                                        </div>
                                                                  </div>
                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                                    {product.description && (
                                          <>
                                                <div div className="col-lg-12">
                                                      <div className="product__description">
                                                            <div className="product__description-title">
                                                                  About
                                                                  {
                                                                        product.title
                                                                  }
                                                            </div>
                                                            <div className="product__description-detail">
                                                                  {
                                                                        product.description
                                                                  }
                                                            </div>
                                                      </div>
                                                </div>
                                          </>
                                    )}
                              </div>
                        </div>
                        {!loading && (
                              <>
                                    <RelatedProduct
                                          loading={loading}
                                          products={products}
                                    />
                              </>
                        )}
                  </Container>
            </>
      );
};

export default ProductDetail;
