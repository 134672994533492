import React from "react";

const LoginIcon = () => {
  return (
    <>
      <svg
        fill="white"
        height="25"
        viewBox="0 0 24 24"
        width="25"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill="rgb(255,255,255)">
          <path d="m14.9453 1.25c-1.3675-.00002-2.4699-.00004-3.3368.11652-.9001.12102-1.65802.37991-2.25993.98183-.52494.52493-.79018 1.17001-.92941 1.928-.13529.73656-.16117 1.63795-.1672 2.71948-.0023.4142.33161.75185.74582.75416s.75186-.33161.75416-.74581c.00609-1.0935.03449-1.86858.14254-2.45683.10411-.56681.27132-.89489.51472-1.13834.2768-.27676.6654-.45721 1.3991-.55586.7554-.10156 1.7565-.10315 3.1919-.10315h1c1.4354 0 2.4365.00159 3.1919.10315.7338.09865 1.1223.2791 1.3991.55586s.4572.66534.5559 1.39911c.1015.75535.1031 1.75647.1031 3.19188v8c0 1.4354-.0016 2.4365-.1031 3.1919-.0987.7338-.2791 1.1223-.5559 1.3991s-.6653.4572-1.3991.5559c-.7554.1015-1.7565.1031-3.1919.1031h-1c-1.4354 0-2.4365-.0016-3.1919-.1031-.7337-.0987-1.1223-.2791-1.3991-.5559-.2434-.2435-.41061-.5715-.51472-1.1383-.10805-.5883-.13645-1.3634-.14254-2.4569-.0023-.4142-.33995-.7481-.75416-.7458s-.74812.34-.74582.7542c.00603 1.0815.03191 1.9829.1672 2.7194.13923.758.40447 1.4031.92941 1.9281.60191.6019 1.35983.8608 2.25993.9818.8669.1165 1.9693.1165 3.3368.1165h1.1098c1.3676 0 2.4699 0 3.3369-.1165.9001-.121 1.6579-.3799 2.2599-.9818.6019-.602.8608-1.3598.9818-2.2599.1166-.867.1165-1.9693.1165-3.3369v-8.10977c0-1.36759.0001-2.46991-.1165-3.33688-.121-.90011-.3799-1.65798-.9818-2.2599-.602-.60192-1.3598-.86081-2.2599-.98183-.867-.11656-1.9693-.11654-3.3369-.11652z" />
          <path d="m2.00098 11.249c-.41422 0-.75.3358-.75.75s.33578.75.75.75h11.97252l-1.9606 1.6806c-.3145.2695-.3509.743-.0814 1.0575.2696.3145.7431.3509 1.0576.0814l3.5-3c.1662-.1425.2619-.3505.2619-.5695 0-.2189-.0957-.4269-.2619-.5694l-3.5-3.00002c-.3145-.26956-.788-.23314-1.0576.08135-.2695.3145-.2331.78797.0814 1.05754l1.9606 1.68053z" />
        </g>
      </svg>
    </>
  );
};
export default LoginIcon;
