import styled from 'styled-components';

export const TermContainer = styled.div`
      margin: 50px 0;
      background-color: ${(props) => props.theme.white};
      padding: 20px 50px;
`;

export const TermBody = styled.div`
      ul {
            margin-top: 10px;

            li {
                  list-style: disc;
                  padding-bottom: 10px;
            }
      }

      p {
            margin: 0 0 10px;
      }
`;

export const TermBodyContent = styled.div`
      margin-top: 30px;
`;

export const TermBodyTitle = styled.div`
      font-size: 20px;
      margin-top: 20px;
`;

export const TermBodyDescription = styled.div`
      font-size: 16px;
      color: #666;
      margin: 10px 0;
      text-align: justify;
`;

export const TermBodySummary = styled.div`
      font-size: 17px;
      font-weight: 500;
`;
