import React from 'react';
import { BlogContent, BlogGridContainer, BlogImageContainer } from './styles';
import { BlogItemIcon, BlogItemIcons } from '../../../styles/blogStyle';
import { Link } from 'react-router-dom';

const BlogitemGrid = ({ blog }) => {
      return (
            <>
                  <BlogGridContainer>
                        <BlogImageContainer>
                              <Link to={`/blogs/${blog.slug}`}>
                                    <img src={blog.image} alt={blog.title} />
                              </Link>
                        </BlogImageContainer>
                        <BlogContent to={`/blogs/${blog.slug}`}>
                              <div>
                                    <h4>{blog.title}</h4>
                                    <BlogItemIcons>
                                          <BlogItemIcon>
                                                <i className="bx bx-calendar"></i>
                                                <span>{blog.date}</span>
                                          </BlogItemIcon>
                                    </BlogItemIcons>
                                    <p>{blog.summary}</p>
                              </div>
                        </BlogContent>
                  </BlogGridContainer>
            </>
      );
};

export default BlogitemGrid;
