import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const BlogGridContainer = styled.div`
      background-color: ${(props) => props.theme.white};
      display: flex;
      align-items: center;
      width: calc(100% - 26px);
      margin: 0 13px;
      margin-bottom: 10px;
      padding: 20px;
`;

export const BlogImageContainer = styled.div`
      img {
            width: 100%;
      }
`;

export const BlogContent = styled(Link)`
      padding: 20px;
      color: ${(props) => props.theme.black};
`;
