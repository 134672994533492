import React from 'react';
import styled from 'styled-components';

export const FooterStyle = styled.footer`
      img {
            object-fit: contain;
      }
`;

export const FooterContainer = styled.div`
      border-left: 28px solid ${(props) => props.theme.secondaryColor};
      background: #231f20;

      @media screen and (max-width: 512px) {
            border-left: 0;
      }
`;

export const FooterCategory = styled.div`
      padding: 60px 30px 60px 40px;

      @media screen and (max-width: 990px) {
            display: none;
      }
`;

export const FooterItem = styled.div``;

export const FooterItemTitle = styled.div`
      h2 {
            color: ${(props) => props.theme.white};
            font-size: 18px;
            font-weight: 300;
            margin-bottom: 15px;
      }
`;

export const FooterItemContent = styled.div`
      ul {
            padding: 0;
      }
      ul li {
            margin-bottom: ${(props) => props.margin};
      }

      ul li a {
            font-size: ${(props) => (props.fontSize ? props.fontSize : '15px')};
            font-weight: ${(props) =>
                  props.fontWeight ? props.fontWeight : '300'};
            color: ${(props) =>
                  props.textColor ? props.textColor : props.theme.linkColor};
            line-height: ${(props) => props.lineHeight};
      }
`;

export const FooterLink = styled.div`
      box-shadow: 0px -10px 10px -10px ${(props) => props.theme.secondaryColor};

      @media screen and (max-width: 990px) {
            display: none;
      }
`;

export const FooterNavigation = styled.div`
      margin-bottom: -3px;

      img {
            transform: translate(-1px, -3px);
      }
`;

export const FooterLinkContainer = styled.div.attrs(() => ({
      className: 'container'
}))`
      padding: 80px 200px 32px 80px;
`;

export const FooterLinkItem = styled.div.attrs(() => ({
      className: 'col-md-6 py-4'
}))``;

export const FooterSubscription = styled.div`
      width: 400px;
      border: 2px solid ${(props) => props.theme.linkColor};
      border-radius: 30px;

      form {
            display: flex;
            justify-content: space-between;
            padding: 0.25rem;
      }

      input {
            background-color: transparent;
            outline: none;
            border: none;
            color: ${(props) => props.theme.white};
            width: 100%;

            &:focus {
                  background-color: transparent;
                  color: ${(props) => props.theme.white};
                  box-shadow: none;
            }

            &::placeholder {
                  color: ${(props) => props.theme.linkColor};
            }
      }

      svg {
            fill: ${(props) => props.theme.white};
            height: 15px;
            width: 15px;
      }

      button {
            background-color: ${(props) => props.theme.primaryColor};
            border-radius: 50%;
            padding: 8px 14px 8px 12px;

            &:hover {
                  background-color: ${(props) => props.theme.secondaryColor};
            }
      }

      @media screen and (max-width: 990px) {
            margin: 0 20px;

            form {
                  padding: 1%;
            }

            button {
                  padding: 6px 12px 6px 10px;
            }
      }

      @media screen and (max-width: 512px) {
            width: 100%;
            margin: 0;
      }
`;

export const FooterSocialMedia = styled.div.attrs(() => ({
      className: 'my-5'
}))`
      display: flex;
      flex-wrap: wrap;
      gap: 60px;
      padding-left: 30px;

      svg {
            fill: ${(props) => props.theme.white};
            width: 15px;
            height: 15px;
      }

      @media screen and (max-width: 990px) {
            margin: 0 20px;
      }
`;

export const FooterSocialMediaLink = styled.div`
      position: relative;
      transition: all 0.3s linear;

      a {
            z-index: 99999;
            position: relative;
      }

      &::after,
      &::before {
            content: '';
            position: absolute;
            border: 1px solid ${(props) => props.theme.primaryColor};
            height: 38.07px;
            width: 39.7px;
            transform: rotate(45deg);
            transition: all 0.3s linear;
      }

      &::before {
            top: -5px;
            left: -6px;
      }

      &::after {
            top: -5px;
            right: -12px;
      }

      &:hover {
            cursor: pointer;
            transition: all 0.3s linear;

            &::before {
                  left: -9px;
                  transition: all 0.3s linear;
            }

            &::after {
                  right: -16px;
                  transition: all 0.3s linear;
            }
      }
`;

export const FooterCredential = styled.div`
      color: ${(props) => props.theme.white};
      font-size: 15px;
      font-weight: 300;
      background-color: ${(props) => props.theme.footerColor};
`;

export const FooterCredentialContainer = styled.div`
      background-color: ${(props) => props.theme.secondaryColor};
      padding: 15px 59px 15px 25px;
      clip-path: polygon(0 0, 97% 0, 100% 100%, 0% 100%);
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 990px) {
            display: block;
      }
`;

export const FooterCopyright = styled.div`
      color: white;
`;

export const FooterCompany = styled.div`
      ${FooterCopyright}
`;

export const FooterMobile = styled.div`
      display: none;

      @media screen and (max-width: 990px) {
            display: block;
      }
`;

export const FooterMobileLink = styled.div`
      .nav-link {
            color: ${(props) => props.theme.linkColor};
            clip-path: polygon(15% 0%, 100% 0%, 85% 100%, 0% 100%);
            border-top-left-radius: 0;
            border-top-right-radius: 0;
      }

      .tab-content {
            padding: 0 20px;
      }
`;
