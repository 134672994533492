import axios from 'axios';
import React, { useState } from 'react';
import { Col, Spinner } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import $ from 'jquery';
import {
      FaqAsk,
      FaqAskBody,
      FaqAskHeader,
      FaqAskTitle,
      FaqFormButton,
      FaqRequestContainer
} from '../components/styles/faqStyle';
import { Form } from 'react-bootstrap';

const FaqRequest = () => {
      const [loading, setLoading] = useState(false);
      const [fullName, setFullName] = useState('');
      const [email, setEmail] = useState('');
      const [phone, setPhone] = useState('');
      const [question, setQuestion] = useState('');

      const handleForm = async (event) => {
            event.preventDefault();

            setLoading(true);
            const data = {
                  name: fullName,
                  email: email,
                  phone: phone,
                  question: question
            };

            axios.post(
                  `${process.env.REACT_APP_SECRET_KEY}/api/faqs/requests`,
                  data
            ).then((response) => {
                  if (response.data.result === 'success') {
                        toast.success('Successfully requested');
                        $('form').trigger('reset');
                  }
                  setLoading(false);
            });
      };

      return (
            <>
                  <FaqRequestContainer>
                        <FaqAsk>
                              <FaqAskHeader>
                                    <FaqAskTitle>Ask your question</FaqAskTitle>
                              </FaqAskHeader>
                              <FaqAskBody>
                                    <Form
                                          onSubmit={handleForm}
                                          className="row g-3"
                                    >
                                          <Col lg="12">
                                                <Form.Label>
                                                      Full Name
                                                </Form.Label>
                                                <Form.Control
                                                      onChange={(event) =>
                                                            setFullName(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                      type="text"
                                                />
                                          </Col>
                                          <Col lg="12">
                                                <Form.Label>Email </Form.Label>
                                                <Form.Control
                                                      onChange={(event) =>
                                                            setEmail(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                      type="email"
                                                />
                                          </Col>
                                          <Col lg="12">
                                                <Form.Label>
                                                      Phone No.{' '}
                                                </Form.Label>
                                                <Form.Control
                                                      onChange={(event) =>
                                                            setPhone(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                      type="text"
                                                />
                                          </Col>
                                          <Col lg="12">
                                                <Form.Label>
                                                      Your Question{' '}
                                                </Form.Label>
                                                <Form.Control
                                                      as="textarea"
                                                      onChange={(event) =>
                                                            setQuestion(
                                                                  event.target
                                                                        .value
                                                            )
                                                      }
                                                      rows={4}
                                                ></Form.Control>
                                          </Col>
                                          <Col lg="12">
                                                <FaqFormButton>
                                                      Submit
                                                      {loading && (
                                                            <Spinner
                                                                  animation="border"
                                                                  role="status"
                                                            >
                                                                  <span className="visually-hidden">
                                                                        Loading...
                                                                  </span>
                                                            </Spinner>
                                                      )}
                                                </FaqFormButton>
                                          </Col>
                                    </Form>
                              </FaqAskBody>
                        </FaqAsk>
                  </FaqRequestContainer>
            </>
      );
};

export default FaqRequest;
