import React from 'react';
import {
      BannerBackground,
      BannerButton,
      BannerContainer,
      BannerContainerContent,
      BannerContent,
      BannerRow,
      BannerSlider,
      BannerSliderContent,
      BannerTitle
} from '../components/styles/homeStyle';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HomeBannerSlider from '../components/sliders/HomeBannerSlider';

const HomeBanner = () => {
      return (
            <>
                  <BannerContainer>
                        <BannerSlider>
                              <Container>
                                    <BannerRow>
                                          <BannerContainerContent>
                                                <BannerContent>
                                                      <BannerTitle>
                                                            <span className="text-dark">
                                                                  Discover The
                                                                  Finest
                                                            </span>
                                                            <h1>
                                                                  'Prime Tiles
                                                                  with{' '}
                                                                  <span>
                                                                        {' '}
                                                                        Stile
                                                                  </span>
                                                                  '
                                                            </h1>
                                                            <p className="mt-2 mb-4">
                                                                  Immerse
                                                                  yourself in a
                                                                  world of
                                                                  exceptional
                                                                  quality and
                                                                  style.
                                                            </p>
                                                      </BannerTitle>
                                                      <BannerButton>
                                                            <Link to="/about-us">
                                                                  Learn More
                                                            </Link>
                                                      </BannerButton>
                                                </BannerContent>
                                          </BannerContainerContent>
                                          <BannerSliderContent>
                                                <HomeBannerSlider />
                                          </BannerSliderContent>
                                    </BannerRow>
                              </Container>
                        </BannerSlider>
                        <BannerBackground>
                              <img
                                    src="/images/img/banner/bannerbackground.png"
                                    alt="Prime Ceramics Banner"
                                    width="100%"
                                    height="100%"
                              />
                        </BannerBackground>
                  </BannerContainer>
            </>
      );
};

export default HomeBanner;
