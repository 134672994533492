import React, { useEffect, useRef, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import SomethingView from './SomethingView';

const MobileMenu = ({ show, setShow }) => {
      const [menu, setMenu] = useState(null);

      useEffect(() => {
            if (show) {
                  setMenu(<SomethingView setShow={setShow} />);
            } else {
                  setMenu(null);
            }
      }, [show]);

      return (
            <>
                  <Offcanvas show={show} onHide={setShow}>
                        <Offcanvas.Header closeButton>
                        </Offcanvas.Header>
                        <Offcanvas.Body>{menu}</Offcanvas.Body>
                  </Offcanvas>
            </>
      );
};

export default MobileMenu;
