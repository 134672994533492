import React from 'react';
import {
      TrendingBox,
      TrendingContainer,
      TrendingContent,
      TrendingDescription,
      TrendingLayout,
      TrendingTitle
} from '../components/styles/homeStyle/trendingTileStyle';
import { Col, Container, Row } from 'react-bootstrap';
import HomeTrendingSlider from '../components/sliders/HomeTrendingSlider';

const TrendingTile = () => {
      return (
            <>
                  <TrendingBox>
                        <TrendingLayout>
                              <img
                                    src="images/img/home/testing.png"
                                    alt="Prime Ceramics - Trending Tiles"
                                    width="100%"
                                    height="100%"
                              />
                        </TrendingLayout>
                        <TrendingContainer>
                              <Container>
                                    <Row>
                                          <Col sm={6}>
                                                <HomeTrendingSlider />
                                          </Col>
                                          <Col sm={6}>
                                                <TrendingContent>
                                                      <TrendingTitle>
                                                            <h2>
                                                                  <span>
                                                                        Trending{' '}
                                                                  </span>
                                                                  Tile
                                                            </h2>
                                                      </TrendingTitle>
                                                      <TrendingDescription>
                                                            <p>
                                                                  We offer an
                                                                  exclusive
                                                                  virtual
                                                                  experience
                                                                  that lets you
                                                                  visit select
                                                                  stores across
                                                                  the country
                                                                  from the
                                                                  convenience of
                                                                  your home.
                                                            </p>
                                                      </TrendingDescription>
                                                </TrendingContent>
                                          </Col>
                                    </Row>
                              </Container>
                        </TrendingContainer>
                  </TrendingBox>
            </>
      );
};

export default TrendingTile;
