import React from 'react';
import { ContactDescription } from '../components/styles/contactStyle';

const ContactMap = () => {
      return (
            <>
                  <ContactDescription>
                        <iframe
                              src="https://www.google.com/maps/d/u/8/embed?mid=141Ns6xVfDQTi-UNdh-b6cIkkJ3BpvG8&ehbc=2E312F&ll=27.694060600000018%2C85.3126528&z=17"
                              height="520"
                              allowFullscreen=""
                              loading="lazy"
                              referrerPolicy="no-referrer-when-downgrade"
                        ></iframe>
                  </ContactDescription>
            </>
      );
};

export default ContactMap;
