import React from 'react';

const PageNotFound = () => {
      return (
            <>
                  <div
                        className="text-center d-flex align-items-center justify-content-center"
                        style={{
                              height: '30vh',
                              padingTop: '20vh',
                              color: '#555',
                              fontSize: '40px !important'
                        }}
                  >
                        <h4>Page not found</h4>
                  </div>
            </>
      );
};

export default PageNotFound;
