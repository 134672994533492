import styled from 'styled-components';

export const TrendingBox = styled.section`
      position: relative;
      width: 100%;
`;

export const TrendingLayout = styled.div`
      position: absolute;
      z-index: 0;
      width: 100%;

      img {
            object-fit: contain;
      }

      img:nth-child(1) {
            margin-top: -200px;
            height: 1103px;
            width: 613px;
      }

      img:nth-child(2) {
            margin-top: 60px;
      }

      ${
            '' /* &::after {
            content: url(/images/img/home/trending/lamp.png);
            position: absolute;
            right: 170px;
            bottom: 243px;
      } */
      }

      @media screen and (max-width: 1200px) {
            display: none;
      }
`;

export const TrendingContainer = styled.div`
      padding: 80px 0px 0px 0px;

      .swiper:nth-child(1) {
            margin-bottom: 60px;
      }

      .row {
            align-items: center;
      }

      @media screen and (max-width: 1200px) {
            .col-sm-6 {
                  width: 100%;

                  .trending-slider {
                        margin-right: 0;
                  }
            }
      }
      @media screen and (max-width: 767px) {
            padding: 10px 0px 0px 0px;
            .swiper:nth-child(1) {
                  margin-bottom: 30px;
            }
      }
`;

export const TrendingContent = styled.div`
      position: relative;

      &::after {
            content: url(/images/png/trendingtitle.png);
            position: absolute;
            left: 0;
            top: -126px;
            z-index: 1;
      }
`;

export const TrendingTitle = styled.div`
      h2 {
            font-style: italic;
            font-weight: 300;
            font-size: 2.5rem;
            line-height: 3rem;

            span {
                  color: ${(props) => props.theme.primaryColor};
            }
      }
      @media screen and (max-width: 767px) {
            h2 {
                  font-size: 2rem;
            }
      }
`;

export const TrendingDescription = styled.div`
      p {
            font-weight: 300;
            font-size: 1.125rem;
            line-height: 1.813rem;
            color: rgb(120, 117, 117);
      }
      @media screen and (max-width: 767px) {
            p {
                  font-size: 1rem;
            }
      }
`;

export const TrendingWrapper = styled.div`
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
            width: 100%;
            /* width: 220px; */
            height: 200px;
            filter: drop-shadow(0px 4px 4px rgba(68, 56, 56, 0.27));
            border-radius: 15px;
      }

      @media screen and (max-width: 944px) {
            padding-left: 0;
            margin-top: 20px;

            img {
                  width: 300px;
                  height: auto;
            }
      }
      @media screen and (max-width: 767px) {
            img {
                  width: 300px;
                  height: 200px;
            }
            margin-bottom: 10px;
      }
      @media screen and (max-width: 512px) {
            &:last-child {
                  display: none;
            }
      }
`;
