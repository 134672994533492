import React from 'react';
import {
      BlogItem,
      BlogItemButton,
      BlogItemDescription,
      BlogItemIcon,
      BlogItemIcons,
      BlogItemImage
} from '../../styles/blogStyle';
import { Link } from 'react-router-dom';
import LimitedText from '../../common/LimitedText';

const BlogItemBox = ({ blog, width }) => {
      return (
            <div className={`col-lg-4 col-md-6 ${width}`}>
                  <BlogItem>
                        <BlogItemImage>
                              <Link to={`/blogs/${blog.slug}`}>
                                    <img
                                          src={`${blog.image}`}
                                          alt={blog.title}
                                          width="100%"
                                          height="100%"
                                    />
                              </Link>
                        </BlogItemImage>
                        <BlogItemDescription>
                              <BlogItemIcons>
                                    <BlogItemIcon>
                                          <i className="bx bx-calendar"></i>
                                          <span>{blog.date}</span>
                                    </BlogItemIcon>
                              </BlogItemIcons>
                              <h1>
                                    <LimitedText text={blog.title} limit={86} />
                              </h1>
                              <Link to={`/blogs/${blog.slug}`}>
                                    <p>
                                          <LimitedText
                                                text={blog.summary}
                                                limit={126}
                                          />
                                    </p>
                              </Link>
                        </BlogItemDescription>
                        <BlogItemButton>
                              <Link
                                    aria-label={blog.title}
                                    title={blog.title}
                                    to={`/blogs/${blog.slug}`}
                              >
                                    Read More
                              </Link>
                        </BlogItemButton>
                  </BlogItem>
            </div>
      );
};

export default BlogItemBox;
