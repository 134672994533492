import React from 'react';
import {
      TestimonialBox,
      TestimonialContainer,
      TestimonialContent,
      TestimonialHeading,
      TestimonialTitle
} from '../components/styles/homeStyle/testimonialStyle';
import { Col, Container, Row } from 'react-bootstrap';
import HomeTestimonialSlider from '../components/sliders/HomeTestimonialSlider';

const TestimonialSection = () => {
      return (
            <>
                  <TestimonialBox>
                        <TestimonialContainer>
                              <Container>
                                    <TestimonialContent>
                                          <TestimonialTitle>
                                                <TestimonialHeading>
                                                      <h2>
                                                            Our
                                                            <span>
                                                                  Testimonials
                                                            </span>
                                                      </h2>
                                                </TestimonialHeading>
                                          </TestimonialTitle>
                                    </TestimonialContent>
                                    <Row>
                                          <Col lg={12}>
                                                <HomeTestimonialSlider />
                                          </Col>
                                    </Row>
                              </Container>
                        </TestimonialContainer>
                  </TestimonialBox>
            </>
      );
};

export default TestimonialSection;
