import styled from 'styled-components';

export const BlogDescription = styled.div`
      margin: 50px 0;
      padding: 20px;
      background-color: ${(props) => props.theme.white};
`;

export const BlogDescriptionHeader = styled.div`
      margin-bottom: 20px;
`;

export const BlogDescriptionImage = styled.div`
      margin-bottom: 10px;

      img {
            width: 100%;
            object-fit: contain;
      }
`;

export const BlogDescriptionBody = styled.div`
      color: #666;
      text-align: justify;
      font-size: 20px;
`;

export const BlogDescriptionTitle = styled.div`
      h1 {
            font-size: 18px;
      }
`;
