import React, { useContext, useEffect, useState } from 'react';
import HomeBanner from './HomeBanner';
import TrendingTile from './TrendingTile';
import VisualizerSection from './VisualizerSection';
import BrandSection from './BrandSection';
import TestimonialSection from './TestimonialSection';
import BlogSection from './BlogSection';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import MetaContext from '../../stores/MetaContext';

const HomePage = () => {
      const metaCtx = useContext(MetaContext);
      metaCtx.handleSlug('home');
      const [page, setPage] = useState([]);
      const [loading, setLoading] = useState(false);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/pages/home`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPage(response.data.page);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <HomeBanner />
                  <TrendingTile />
                  <VisualizerSection />
                  <BrandSection />
                  <TestimonialSection />
                  <BlogSection />
            </>
      );
};

export default HomePage;
