import React from 'react';
import { Offcanvas } from 'react-bootstrap';

const CareerApply = ({ handleClose, show }) => {
      return (
            <>
                  <Offcanvas
                        placement="end"
                        style={{ zIndex: '999999999' }}
                        show={show}
                        onHide={handleClose}
                  >
                        <Offcanvas.Header closeButton>
                              <Offcanvas.Title>Appy Now</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className="bg-white">
                              <form className="row g-3">
                                    <div className="">
                                          <label className="form-label">
                                                First Name
                                          </label>
                                          <input
                                                className="form-control"
                                                type="text"
                                          />
                                    </div>
                                    <div className="">
                                          <label className="form-label">
                                                Last Name
                                          </label>
                                          <input
                                                className="form-control"
                                                type="text"
                                          />
                                    </div>
                                    <div className="">
                                          <label className="form-label">
                                                Email
                                          </label>
                                          <input
                                                className="form-control"
                                                type="email"
                                          />
                                    </div>
                                    <div className="">
                                          <label className="form-label">
                                                Phone Number
                                          </label>
                                          <input
                                                className="form-control"
                                                type="number"
                                          />
                                    </div>
                                    <div className="">
                                          <label className="form-label">
                                                Date of Birth
                                          </label>
                                          <input
                                                className="form-control"
                                                type="date"
                                          />
                                    </div>
                                    <div className="">
                                          <label className="form-label">
                                                Current Address
                                          </label>
                                          <input
                                                className="form-control"
                                                type="text"
                                          />
                                    </div>
                                    <div className="">
                                          <label className="form-label">
                                                Tell me about yourself
                                          </label>
                                          <textarea
                                                className="form-control"
                                                rows={4}
                                          ></textarea>
                                    </div>
                                    <div className="">
                                          <label className="form-label">
                                                Resume
                                          </label>
                                          <input
                                                className="form-control"
                                                type="file"
                                          />
                                    </div>
                                    <div className="">
                                          <button className="career__btn">
                                                Submit
                                          </button>
                                    </div>
                              </form>
                        </Offcanvas.Body>
                  </Offcanvas>
            </>
      );
};

export default CareerApply;
