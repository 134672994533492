import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import $ from 'jquery';
import {
      ContactFormButton,
      ContactFormContainer,
      ContactInfoContainer,
      ContactInfoItem,
      ContactSection,
      TollFree
} from '../components/styles/contactStyle';
import { Container, Form, Row } from 'react-bootstrap';

const ContactForm = () => {
      const [loading, setLoading] = useState(false);

      const [fullName, setFullName] = useState('');
      const [email, setEmail] = useState('');
      const [phone, setPhone] = useState('');
      const [message, setMessage] = useState('');

      const handleForm = async (event) => {
            event.preventDefault();
            const data = {
                  name: fullName,
                  email: email,
                  phone: phone,
                  message: message
            };
            setLoading(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/contacts`,
                        data
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setLoading(false);
                              toast.success('Successfully send');
                              $('form').trigger('reset');
                        }
                        if (response.data.error) {
                              toast.error(response.data.error);
                        }
                  });
            setLoading(false);
      };

      return (
            <>
                  <ContactSection>
                        <Container fluid="lg">
                              <Row>
                                    <ContactInfoContainer>
                                          <Row>
                                                <ContactInfoItem>
                                                      <i className="bx bx-envelope"></i>
                                                      <h4>Email Us</h4>
                                                      <a href="mailto:info@primeceramics.com.np">
                                                            info@primeceramics.com.np
                                                      </a>
                                                </ContactInfoItem>
                                                <ContactInfoItem>
                                                      <i className="bx bx-phone"></i>
                                                      <h4>Call Us</h4>
                                                      <a
                                                            className="mb-1"
                                                            href="tel:015918860"
                                                      >
                                                            +977 - 1 - 5918860
                                                      </a>
                                                      <a
                                                            className="mb-1"
                                                            href="tel:015918861"
                                                      >
                                                            +977 - 1 - 5918861
                                                      </a>
                                                      <a
                                                            className="mb-1"
                                                            href="tel:015918862"
                                                      >
                                                            +977 - 1 - 5918862
                                                      </a>

                                                      <a href="tel:18105000062">
                                                            Toll-Free No:
                                                            18105000062
                                                            <TollFree
                                                                  style={{
                                                                        color: '#666',
                                                                        fontSize: '14px'
                                                                  }}
                                                            >
                                                                  (For NTC Only)
                                                            </TollFree>
                                                      </a>
                                                </ContactInfoItem>

                                                <ContactInfoItem>
                                                      <i className="bx bx-map"></i>
                                                      <h4>Corporate Office</h4>
                                                      <p>
                                                            Level 4, Saket
                                                            Complex <br />{' '}
                                                            Tripureshwor,
                                                            Kathmandu, Nepal
                                                      </p>
                                                </ContactInfoItem>
                                                <ContactInfoItem>
                                                      <i className="bx bx-map"></i>
                                                      <h4>Factory Location</h4>
                                                      <p>
                                                            Sakhuwa Dhamaura
                                                            Brindavan
                                                            Municipality-6{' '}
                                                            <br /> Rautahat,
                                                            Nepal
                                                      </p>
                                                </ContactInfoItem>
                                          </Row>
                                    </ContactInfoContainer>

                                    <ContactFormContainer>
                                          <Form
                                                onSubmit={handleForm}
                                                role="form"
                                                className="php-email-form"
                                          >
                                                <Row className="g-3">
                                                      <Form.Group className="col-lg-12">
                                                            <Form.Label>
                                                                  Your Name
                                                            </Form.Label>
                                                            <Form.Control
                                                                  type="text"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setFullName(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                                  required
                                                            />
                                                      </Form.Group>

                                                      <Form.Group className="col-lg-6">
                                                            <Form.Label>
                                                                  Your Email
                                                            </Form.Label>
                                                            <Form.Control
                                                                  type="email"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setEmail(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                                  required
                                                            />
                                                      </Form.Group>

                                                      <Form.Group className="col-lg-6">
                                                            <Form.Label>
                                                                  Phone No.
                                                            </Form.Label>
                                                            <Form.Control
                                                                  type="text"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setPhone(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                                  required
                                                            />
                                                      </Form.Group>

                                                      <Form.Group className="col-lg-12">
                                                            <Form.Label>
                                                                  Message
                                                            </Form.Label>
                                                            <Form.Control
                                                                  as="textarea"
                                                                  onChange={(
                                                                        event
                                                                  ) =>
                                                                        setMessage(
                                                                              event
                                                                                    .target
                                                                                    .value
                                                                        )
                                                                  }
                                                                  rows="8"
                                                                  required
                                                            >
                                                                  {' '}
                                                            </Form.Control>
                                                      </Form.Group>
                                                </Row>
                                                <ContactFormButton>
                                                      <button type="submit">
                                                            Send Message
                                                            {loading ? (
                                                                  <span
                                                                        className="spinner-border spinner-border-sm ml-5"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                  ></span>
                                                            ) : (
                                                                  <span></span>
                                                            )}
                                                      </button>
                                                </ContactFormButton>
                                          </Form>
                                    </ContactFormContainer>
                              </Row>
                        </Container>
                  </ContactSection>
            </>
      );
};

export default ContactForm;
