import React from 'react';
import './style.css';
import { Link } from 'react-router-dom';
import Breadcrumb from '../components/common/Breadcrumb';

const PressRelease = () => {
      return (
            <>
                  <Breadcrumb title="CSR" summary="CSR" />
                  <div className='container'>
                        <div className='blog__container'>
                              <div className='row'>
                                    <div className='col-lg-8 col-sm-12'>
                                          <div className='blog__header'>
                                                <h1 className='blog__title'>Prime Ceramics in Media</h1>
                                          </div>
                                          <div className='blog__content'>
                                                <div className='row g-4'>
                                                      <div className='col-lg-12'>
                                                            <h4>Not CSR Found</h4>
                                                      </div>

                                                </div>
                                          </div>
                                    </div>
                                    <div className='col-lg-4 col-sm-12'>
                                          <div className='blog__widget'>
                                                <div className='blog__widget-header'>
                                                      <div className="blog__widget-title">
                                                            <span>Latest Announcements</span>
                                                      </div>
                                                </div>
                                                <div className='blog__widget-body'>
                                                      <div className='blog__content'>
                                                            <div className='row g-4'>


                                                            </div>
                                                      </div>
                                                </div>
                                          </div>
                                    </div>
                              </div>
                        </div>
                  </div>
            </>
      )
}

export default PressRelease;