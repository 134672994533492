import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import {
      BlogContainer,
      BlogContent,
      BlogHeader
} from '../components/styles/blogStyle';
import Breadcrumb from '../components/common/Breadcrumb';
import BlogItemBox from '../components/frontend/blogs/BlogItemBox';
import { useMediaQuery } from 'react-responsive';
import BlogitemGrid from '../components/frontend/blogs/BlogItemGrid';
import { Helmet } from 'react-helmet';

const BlogPage = () => {
      const [loading, setLoading] = useState(false);
      const [blogs, setBlogs] = useState([]);
      const [singleBlogs, setSingleBlogs] = useState([]);
      const isMobile = useMediaQuery({ maxWidth: 768 });
      useEffect(() => {
            const loadData = async () => {
                  setLoading(true);
                  await axios
                        .get(`${process.env.REACT_APP_SECRET_KEY}/api/blogs`)
                        .then((response) => {
                              if (response.data.result === 'success') {
                                    setBlogs(response.data.blogs);
                                    setSingleBlogs(response.data.singleBlog);
                                    setLoading(false);
                              }
                        });
            };
            loadData();
      }, []);

      var breadcrumb;

      useEffect(() => {
            if (isMobile) {
                  breadcrumb = true;
            } else {
                  breadcrumb = false;
            }
      }, [isMobile]);

      return (
            <>
                  <Helmet>
                        <link rel="canonical" href={window.location.href} />
                        <title>Blogs & Event | Prime Tiles with stile</title>
                  </Helmet>
                  <Breadcrumb title="Blogs & Event" summary="Blogs & Event" />
                  <Container>
                        <BlogContainer>
                              <Row>
                                    <Col lg="12" sm="12">
                                          <BlogHeader>
                                                <h1>Media Coverage</h1>
                                          </BlogHeader>
                                          <BlogContent className="row g-4">
                                                {!loading ? (
                                                      <>
                                                            {blogs.length >
                                                            0 ? (
                                                                  <>
                                                                        {singleBlogs && (
                                                                              <BlogitemGrid
                                                                                    blog={
                                                                                          singleBlogs
                                                                                    }
                                                                              />
                                                                        )}
                                                                        {blogs?.map(
                                                                              (
                                                                                    blog,
                                                                                    index
                                                                              ) => (
                                                                                    <BlogItemBox
                                                                                          key={
                                                                                                index
                                                                                          }
                                                                                          blog={
                                                                                                blog
                                                                                          }
                                                                                    />
                                                                              )
                                                                        )}
                                                                  </>
                                                            ) : (
                                                                  <>
                                                                        <div
                                                                              className="text-center"
                                                                              style={{
                                                                                    height: '50vh',
                                                                                    paddingTop:
                                                                                          '20vh'
                                                                              }}
                                                                        >
                                                                              <h4>
                                                                                    No
                                                                                    Blog
                                                                                    Found
                                                                              </h4>
                                                                        </div>
                                                                  </>
                                                            )}
                                                      </>
                                                ) : (
                                                      <>
                                                            <div
                                                                  className="text-center"
                                                                  style={{
                                                                        height: '50vh',
                                                                        paddingTop:
                                                                              '20vh'
                                                                  }}
                                                            >
                                                                  <Spinner
                                                                        animation="border"
                                                                        role="status"
                                                                  >
                                                                        <span className="visually-hidden">
                                                                              Loading...
                                                                        </span>
                                                                  </Spinner>
                                                            </div>
                                                      </>
                                                )}
                                          </BlogContent>
                                    </Col>
                              </Row>
                        </BlogContainer>
                  </Container>
            </>
      );
};

export default BlogPage;
