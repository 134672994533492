import React from 'react';
import { Link } from 'react-router-dom';
import {
      CategoryProductAmount,
      CategoryProductImage,
      CategoryProductItem,
      CategoryProductPrice,
      CategoryProductPrime,
      CategoryProductSize,
      CategoryProductTitle,
      CategoryProductVisualizer
} from '../components/styles/productStyle/categoryPage';

const Product = ({ product, handleShow }) => {
      return (
            <>
                  <CategoryProductItem>
                        <CategoryProductImage>
                              <Link to={`/products/${product.slug}`}>
                                    <img
                                          className="w-100"
                                          src={product.image}
                                    />
                              </Link>
                        </CategoryProductImage>
                        <div className="category__product-detail">
                              <CategoryProductVisualizer>
                                    <CategoryProductPrime>
                                          <a
                                                role="button"
                                                className="text-white"
                                                onClick={handleShow}
                                          >
                                                Try in Visualizer
                                          </a>
                                    </CategoryProductPrime>
                              </CategoryProductVisualizer>
                              <CategoryProductTitle>
                                    <Link to={`/products/${product.slug}`}>
                                          {product.title}
                                    </Link>
                              </CategoryProductTitle>
                              {product.price > 0 && (
                                    <CategoryProductAmount>
                                          <CategoryProductPrice>
                                                MRP Rs. {product.price}/sq.ft
                                          </CategoryProductPrice>
                                    </CategoryProductAmount>
                              )}
                              <CategoryProductSize>
                                    Size: {product.size}
                              </CategoryProductSize>
                        </div>
                  </CategoryProductItem>
            </>
      );
};

export default Product;
