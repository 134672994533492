import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../components/common/Breadcrumb';
import { useMediaQuery } from 'react-responsive';
import { Container } from 'react-bootstrap';
import {
      BlogDescription,
      BlogDescriptionBody,
      BlogDescriptionHeader,
      BlogDescriptionImage,
      BlogDescriptionTitle
} from './stlyles';
import { BlogItemIcon, BlogItemIcons } from '../components/styles/blogStyle';
import { Helmet } from 'react-helmet';

const BlogDetail = () => {
      const { blogId } = useParams();
      const [loading, setLoading] = useState('');
      const [blog, setBlog] = useState([]);
      const isMobile = useMediaQuery({
            maxWidth: 768
      });
      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/blogs/${blogId}/details`
                  )
                  .then((response) => {
                        if (response.data.result == 'success') {
                              setBlog(response.data.blog);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  {!loading && (
                        <Helmet>
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                              <title>{blog.title}</title>
                        </Helmet>
                  )}
                  {!isMobile && (
                        <Breadcrumb
                              summary={`${blog.title}`}
                              otherLink="/blogs"
                              otherLinkTitle="Blogs"
                        />
                  )}
                  <Container style={{ paddingTop: isMobile ? '61px' : '' }}>
                        <BlogDescription>
                              <BlogDescriptionHeader>
                                    <BlogDescriptionImage>
                                          <img
                                                src={blog.image}
                                                className="img-fluid"
                                          />
                                    </BlogDescriptionImage>
                                    <div className="blog__description-date">
                                          <BlogItemIcons>
                                                <BlogItemIcon>
                                                      <i className="bx bx-calendar"></i>
                                                      <span>{blog.date}</span>
                                                </BlogItemIcon>
                                          </BlogItemIcons>
                                          <BlogDescriptionTitle>
                                                <h1>{blog.title}</h1>
                                          </BlogDescriptionTitle>
                                    </div>
                              </BlogDescriptionHeader>
                              <BlogDescriptionBody>
                                    <p
                                          dangerouslySetInnerHTML={{
                                                __html: blog.description
                                          }}
                                    ></p>
                              </BlogDescriptionBody>
                        </BlogDescription>
                  </Container>
            </>
      );
};

export default BlogDetail;
