import styled from 'styled-components';

export const CloseButtton = styled.button`
      position: absolute;
      z-index: 99999999999999999999999999999999999999999999;
      top: 20px;
      right: 30px;
      background-color: ${(props) => props.theme.primaryColor};
      color: white;
      padding: 13px;
      border-radius: 50%;
`;
