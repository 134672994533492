import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { SliderContainer, SliderImage } from '../styles/homeStyle';

const HomeBannerSlider = () => {
      return (
            <>
                  <Swiper
                        className="banner-slider"
                        spaceBetween={30}
                        autoplay={{
                              delay: 3000,
                              disableOnInteraction: false
                        }}
                        navigation={true}
                        effect={'fade'}
                        modules={[Autoplay, EffectFade]}
                  >
                        <SwiperSlide>
                              <SliderContainer>
                                    <SliderImage>
                                          <img
                                                src="/images/sliders/slider-3.jpg"
                                                alt="Prime Tiles Slider"
                                                className="img-fluid"
                                                width="100%"
                                                height="100%"
                                          />
                                    </SliderImage>
                              </SliderContainer>
                        </SwiperSlide>
                        <SwiperSlide>
                              <SliderContainer>
                                    <SliderImage>
                                          <img
                                                src="/images/sliders/slider-4.jpg"
                                                className="img-fluid"
                                                alt="Prime Tiles Slider"
                                                width="100%"
                                                height="100%"
                                          />
                                    </SliderImage>
                              </SliderContainer>
                        </SwiperSlide>
                        <SwiperSlide>
                              <SliderContainer>
                                    <SliderImage>
                                          <img
                                                src="/images/sliders/slider-7.jpg"
                                                className="img-fluid"
                                                alt="Prime Tiles Slider"
                                                width="100%"
                                                height="100%"
                                          />
                                    </SliderImage>
                              </SliderContainer>
                        </SwiperSlide>
                        <SwiperSlide>
                              <SliderContainer>
                                    <SliderImage>
                                          <img
                                                src="/images/sliders/slider-6.jpg"
                                                className="img-fluid"
                                                alt="Prime Tiles Slider"
                                                width="100%"
                                                height="100%"
                                          />
                                    </SliderImage>
                              </SliderContainer>
                        </SwiperSlide>
                        {/* <SliderMedia>
                              <SliderMediaLink>
                                    <Link to="/"><Facebook /></Link>
                              </SliderMediaLink>
                              <SliderMediaLink>
                                    <Link to="/"><Instagram /></Link>
                              </SliderMediaLink>
                              <SliderMediaLink>
                                    <Link to="/"><Youtube /></Link>
                              </SliderMediaLink>
                        </SliderMedia> */}
                  </Swiper>
            </>
      );
};

export default HomeBannerSlider;
