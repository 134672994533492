import React from 'react';
import { Accordion, Spinner } from 'react-bootstrap';
import { FaqContent, FaqListContainer } from '../components/styles/faqStyle';

const AllFaqList = ({ loading, faqs }) => {
      return (
            <>
                  <FaqListContainer>
                        {!loading ? (
                              <>
                                    <FaqContent>
                                          {faqs.length > 0 ? (
                                                <>
                                                      <Accordion
                                                            defaultActiveKey={0}
                                                      >
                                                            {faqs.map(
                                                                  (
                                                                        faq,
                                                                        index
                                                                  ) => (
                                                                        <Accordion.Item
                                                                              eventKey={
                                                                                    index
                                                                              }
                                                                              key={
                                                                                    faq.id
                                                                              }
                                                                        >
                                                                              <Accordion.Header>
                                                                                    {
                                                                                          faq.question
                                                                                    }
                                                                              </Accordion.Header>
                                                                              <Accordion.Body>
                                                                                    {
                                                                                          faq.answer
                                                                                    }
                                                                              </Accordion.Body>
                                                                        </Accordion.Item>
                                                                  )
                                                            )}
                                                      </Accordion>
                                                </>
                                          ) : (
                                                <>
                                                      <div
                                                            className="text-center"
                                                            style={{
                                                                  height: '50vh',
                                                                  paddingTop:
                                                                        '20vh'
                                                            }}
                                                      >
                                                            <h4>
                                                                  No Faqs Found
                                                            </h4>
                                                      </div>
                                                </>
                                          )}
                                    </FaqContent>
                              </>
                        ) : (
                              <>
                                    <div
                                          className="text-center"
                                          style={{
                                                height: '50vh',
                                                paddingTop: '20vh'
                                          }}
                                    >
                                          <Spinner
                                                animation="border"
                                                role="status"
                                          >
                                                <span className="visually-hidden">
                                                      Loading...
                                                </span>
                                          </Spinner>
                                    </div>
                              </>
                        )}
                  </FaqListContainer>
            </>
      );
};

export default AllFaqList;
