import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Filter from '../category/Filter';
import { Modal, Row, Spinner } from 'react-bootstrap';
import Visualizer from '../visualizer';
import Breadcrumb from '../components/common/Breadcrumb';
import MobileFilter from '../category/MobileFilter';
import {
      CategoryContainer,
      CategoryContent,
      CategoryProduct,
      CategoryProductAmount,
      CategoryProductImage,
      CategoryProductItem,
      CategoryProductPrice,
      CategoryProductPrime,
      CategoryProductSize,
      CategoryProductTitle,
      CategoryProductVisualizer,
      FilterContainer,
      MobileFilterContainer
} from '../components/styles/productStyle/categoryPage';

import Progress from 'react-progress-2';
import 'react-progress-2/main.css';
import Pagination from 'react-js-pagination';
import { Helmet } from 'react-helmet';

const ProductFilter = () => {
      const [loading, setLoading] = useState(false);
      const [products, setProducts] = useState([]);
      const [totalProducts, setTotalProducts] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/products`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProducts(response.data.products);
                              setTotalProducts(response.data.totalProducts);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      const [fullscreen, setFullscreen] = useState(true);
      const [show, setShow] = useState(false);

      function handleShow(breakpoint) {
            setFullscreen(breakpoint);
            setShow(true);
      }

      async function getProductData(pageNumber = 1) {
            window.scrollTo(0, 0);
            Progress.show();
            // console.log(pageNumber);
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/products?page=${pageNumber}`;
            await axios(url).then((response) => {
                  setProducts(response.data.products);
                  setTotalProducts(response.data.totalProducts);
                  setPerPages(response.data.itemsCountPerPage);
                  setCurrentPage(response.data.currentPage);
                  Progress.hide();
            });
      }

      return (
            <>
                  <Helmet>
                        <link rel="canonical" href={window.location.href} />
                        <title>Product List | Prime Tiles with Stile</title>
                  </Helmet>
                  <Progress.Component
                        style={{ background: '#99999978', height: '5px' }}
                        thumbStyle={{
                              background: 'rgb(215, 26, 33)',
                              height: '5px'
                        }}
                  />
                  <Breadcrumb title="Products" summary="Product List" />
                  <div className="containe">
                        <MobileFilterContainer>
                              <MobileFilter />
                        </MobileFilterContainer>
                        <CategoryContainer>
                              <FilterContainer>
                                    <Filter />
                              </FilterContainer>
                              <CategoryProduct>
                                    <CategoryContent>
                                          <div className="category__body">
                                                <div className="category__product-content">
                                                      <Row className="g-4">
                                                            {!loading ? (
                                                                  products.length >
                                                                        0 &&
                                                                  products.map(
                                                                        (
                                                                              product,
                                                                              index
                                                                        ) => (
                                                                              <div
                                                                                    className="col-lg-4 col-md-6"
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <CategoryProductItem>
                                                                                          <CategoryProductImage>
                                                                                                <Link
                                                                                                      to={`/products/${product.slug}`}
                                                                                                >
                                                                                                      <img
                                                                                                            className="w-100"
                                                                                                            src={
                                                                                                                  product.image
                                                                                                            }
                                                                                                      />
                                                                                                </Link>
                                                                                          </CategoryProductImage>
                                                                                          <div className="category__product-detail">
                                                                                                <CategoryProductVisualizer>
                                                                                                      <CategoryProductPrime>
                                                                                                            <a
                                                                                                                  role="button"
                                                                                                                  className="text-white"
                                                                                                                  onClick={
                                                                                                                        handleShow
                                                                                                                  }
                                                                                                            >
                                                                                                                  Try
                                                                                                                  in
                                                                                                                  Visualizer
                                                                                                            </a>
                                                                                                      </CategoryProductPrime>
                                                                                                </CategoryProductVisualizer>
                                                                                                <CategoryProductTitle>
                                                                                                      <Link
                                                                                                            to={`/products/${product.slug}`}
                                                                                                      >
                                                                                                            {
                                                                                                                  product.title
                                                                                                            }
                                                                                                      </Link>
                                                                                                </CategoryProductTitle>
                                                                                                {product.price >
                                                                                                      0 && (
                                                                                                      <CategoryProductAmount>
                                                                                                            <CategoryProductPrice>
                                                                                                                  MRP
                                                                                                                  Rs.{' '}
                                                                                                                  {
                                                                                                                        product.price
                                                                                                                  }
                                                                                                                  /sq.ft
                                                                                                            </CategoryProductPrice>
                                                                                                      </CategoryProductAmount>
                                                                                                )}
                                                                                                <CategoryProductSize>
                                                                                                      Size
                                                                                                      {
                                                                                                            product.size
                                                                                                      }
                                                                                                </CategoryProductSize>
                                                                                          </div>
                                                                                    </CategoryProductItem>
                                                                              </div>
                                                                        )
                                                                  )
                                                            ) : (
                                                                  <>
                                                                        <div
                                                                              className="text-center"
                                                                              style={{
                                                                                    height: '50vh',
                                                                                    paddingTop:
                                                                                          '20vh'
                                                                              }}
                                                                        >
                                                                              <Spinner
                                                                                    animation="border"
                                                                                    role="status"
                                                                              >
                                                                                    <span className="visually-hidden">
                                                                                          Loading...
                                                                                    </span>
                                                                              </Spinner>
                                                                        </div>
                                                                  </>
                                                            )}
                                                      </Row>
                                                      <div className="float-end mt-4">
                                                            {totalProducts >
                                                                  12 && (
                                                                  <Pagination
                                                                        activePage={
                                                                              currentPage
                                                                        }
                                                                        totalItemsCount={
                                                                              totalProducts
                                                                        }
                                                                        itemsCountPerPage={
                                                                              perPages
                                                                        }
                                                                        onChange={(
                                                                              pageNumber
                                                                        ) =>
                                                                              getProductData(
                                                                                    pageNumber
                                                                              )
                                                                        }
                                                                        itemClass="page-item"
                                                                        linkClass="page-link"
                                                                        firstPageText="First"
                                                                        lastPageText="Last"
                                                                  />
                                                            )}
                                                      </div>
                                                </div>
                                          </div>
                                    </CategoryContent>
                              </CategoryProduct>
                        </CategoryContainer>
                  </div>

                  <Modal
                        style={{ zIndex: '999999999' }}
                        show={show}
                        fullscreen={fullscreen}
                        onHide={() => setShow(false)}
                  >
                        <Modal.Header closeButton>
                              <Modal.Title>Chose Your Room</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                              style={{ padding: '0', overflow: 'hidden' }}
                        >
                              <Visualizer />
                        </Modal.Body>
                  </Modal>
            </>
      );
};

export default ProductFilter;
