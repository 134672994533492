import React, { useEffect, useState } from 'react';
import CareerApply from './CareerApply';
import './style.css';
import Breadcrumb from '../components/common/Breadcrumb';
import { Col, Container, Row, Spinner } from 'react-bootstrap';
import axios from 'axios';
import { CareerBody } from './style';
import { Link } from 'react-router-dom';

const Career = () => {
      const [loading, setLoading] = useState(false);
      const [careers, setCareers] = useState([]);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(`${process.env.REACT_APP_SECRET_KEY}/api/careers`)
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCareers(response.data.jobs);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Breadcrumb title="Career List" summary="Careers" />
                  <Container>
                        <div className="career__container">
                              <Row>
                                    <Col lg="12">
                                          <Row className=" g-3">
                                                {!loading ? (
                                                      careers?.length > 0 ? (
                                                            careers?.map(
                                                                  (
                                                                        item,
                                                                        index
                                                                  ) => (
                                                                        <Col
                                                                              lg="4"
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <div className="faq__ask bg-white h-100 rounded">
                                                                                    <div className="faq__ask-header">
                                                                                          <div className="faq__ask-title">
                                                                                                {
                                                                                                      item.title
                                                                                                }
                                                                                          </div>
                                                                                    </div>
                                                                                    <CareerBody>
                                                                                          <div className="career__detail">
                                                                                                <ul>
                                                                                                      <li>
                                                                                                            <strong>
                                                                                                                  Vacancy:{' '}
                                                                                                            </strong>
                                                                                                            <span>
                                                                                                                  {
                                                                                                                        item.vacancy
                                                                                                                  }
                                                                                                            </span>
                                                                                                      </li>
                                                                                                      <li>
                                                                                                            <strong>
                                                                                                                  Salary:{' '}
                                                                                                            </strong>
                                                                                                            <span>
                                                                                                                  {
                                                                                                                        item.salary
                                                                                                                  }
                                                                                                            </span>
                                                                                                      </li>
                                                                                                      <li>
                                                                                                            <strong>
                                                                                                                  Experience:{' '}
                                                                                                            </strong>
                                                                                                            <span>
                                                                                                                  {
                                                                                                                        item.experience
                                                                                                                  }
                                                                                                            </span>
                                                                                                      </li>
                                                                                                      <li>
                                                                                                            <strong>
                                                                                                                  Education:{' '}
                                                                                                            </strong>
                                                                                                            <span>
                                                                                                                  {
                                                                                                                        item.education
                                                                                                                  }
                                                                                                            </span>
                                                                                                      </li>
                                                                                                </ul>
                                                                                                <Link
                                                                                                      to={`/careers/${item.slug}`}
                                                                                                      className="career__btn"
                                                                                                >
                                                                                                      Apply
                                                                                                      Now
                                                                                                </Link>
                                                                                          </div>
                                                                                    </CareerBody>
                                                                              </div>
                                                                        </Col>
                                                                  )
                                                            )
                                                      ) : (
                                                            <>
                                                                  No jobs
                                                                  available
                                                            </>
                                                      )
                                                ) : (
                                                      <div className="text-center">
                                                            <Spinner />
                                                      </div>
                                                )}
                                          </Row>
                                    </Col>
                              </Row>
                        </div>
                  </Container>
            </>
      );
};

export default Career;
