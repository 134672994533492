import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
  
  :root {
    --primary-color: #e71d28;
    --secondary-color: #8f2424;
    --white-color: #fff;
    --text-color: #3d3838;
    --footer-color:#231f20;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Manrope", sans-serif;
    font-family: "Inter", sans-serif;
  }

  ul li {
    list-style-type: none;
  }

  a {
    text-decoration: none;
  }

  body {
    position: relative;
  }
`;