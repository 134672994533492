import React, { useEffect, useState } from 'react';
// import { FaqContainer } from '../components/styles/faqStyle';

import { motion } from 'framer-motion';
import styled from 'styled-components';
import { useRef } from 'react';
import SliderLoader from '../components/styles/loaderStyle';
import Breadcrumb from '../components/common/Breadcrumb';
import { Container, Row } from 'react-bootstrap';
import { FaqContainer } from '../components/styles/faqStyle';
import FaqRequest from './FaqRequest';
import AllFaqList from './AllFaqList';
import axios from 'axios';
import { Helmet } from 'react-helmet';

const FaqBox = styled(motion.div)``;

const container = {
      hidden: { opacity: 0 },
      show: {
            opacity: 1,

            transition: {
                  staggerChildren: 0.5,
                  duration: 0.5
            }
      }
};

const FaqPage = () => {
      let screen = useRef(null);
      let body = useRef(null);

      const [loading, setLoading] = useState(false);
      const [faqs, setFaqs] = useState([]);

      useEffect(() => {
            const loadData = async () => {
                  setLoading(true);
                  await axios
                        .get(`${process.env.REACT_APP_SECRET_KEY}/api/faqs`)
                        .then((response) => {
                              if (response.data.result === 'success') {
                                    setFaqs(response.data.faqs);
                                    setLoading(false);
                              }
                        });
            };
            loadData();
      }, []);

      return (
            <>
                  <Helmet>
                        <link rel="canonical" href={window.location.href} />
                        <title>
                              Frequently Asked Question | Prime Tiles with Stile
                        </title>
                  </Helmet>
                  {/* <SliderLoader screen={screen} body={body} /> */}
                  <FaqBox
                        variants={container}
                        initial="hidden"
                        animate="show"
                        exit={{
                              opacity: 0,
                              transition: { duration: 500000000 }
                        }}
                  >
                        <Breadcrumb
                              ref={(el) => (body = el)}
                              title="FAQs"
                              summary="Frequently Asked Question"
                        />

                        <Container>
                              <FaqContainer>
                                    <Row>
                                          <FaqRequest />
                                          <AllFaqList
                                                loading={loading}
                                                faqs={faqs}
                                          />
                                    </Row>
                              </FaqContainer>
                        </Container>
                  </FaqBox>
            </>
      );
};

export default FaqPage;
