import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { BrandSliderImage } from '../styles/homeStyle/brandStyle';

const HomeBrandSlider = ({ clients }) => {
      return (
            <>
                  <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        breakpoints={{
                              300: {
                                    slidesPerView: 2,
                                    spaceBetween: 10
                              },
                              400: {
                                    slidesPerView: 2,
                                    spaceBetween: 10
                              },
                              768: {
                                    slidesPerView: 2,
                                    spaceBetween: 10
                              },
                              1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 30
                              }
                        }}
                        className="brand-slider mb-4"
                  >
                        {clients?.map((client, index) => (
                              <SwiperSlide key={index}>
                                    {client?.map((item, key) => (
                                          <BrandSliderImage>
                                                <img
                                                      src={item.image}
                                                      className="img-fluid"
                                                      alt={item.title}
                                                      width="100%"
                                                      height="100%"
                                                />
                                          </BrandSliderImage>
                                    ))}
                              </SwiperSlide>
                        ))}
                        <SwiperSlide>
                              <BrandSliderImage>
                                    <img
                                          src="/images/img/home/brands/brand2.jpg"
                                          alt="Prime Ceramics - Client Images"
                                          width="100%"
                                          height="100%"
                                    />
                              </BrandSliderImage>
                              <BrandSliderImage>
                                    <img
                                          src="/images/img/home/brands/brand4.jpg"
                                          alt="Prime Ceramics - Client Images"
                                          width="100%"
                                          height="100%"
                                    />
                              </BrandSliderImage>
                        </SwiperSlide>
                        <SwiperSlide>
                              <BrandSliderImage>
                                    <img
                                          src="/images/img/home/brands/brand3.jpg"
                                          alt="Prime Ceramics - Client Images"
                                          width="100%"
                                          height="100%"
                                    />
                              </BrandSliderImage>
                              <BrandSliderImage>
                                    <img
                                          src="/images/img/home/brands/brand6.jpg"
                                          alt="Prime Ceramics - Client Images"
                                          width="100%"
                                          height="100%"
                                    />
                              </BrandSliderImage>
                        </SwiperSlide>
                        <SwiperSlide>
                              <BrandSliderImage>
                                    <img
                                          src="/images/img/home/brands/brand4.jpg"
                                          alt="Prime Ceramics - Client Images"
                                          width="100%"
                                          height="100%"
                                    />
                              </BrandSliderImage>
                              <BrandSliderImage>
                                    <img
                                          src="/images/img/home/brands/brand1.jpg"
                                          alt="Prime Ceramics - Client Images"
                                          width="100%"
                                          height="100%"
                                    />
                              </BrandSliderImage>
                        </SwiperSlide>
                        <SwiperSlide>
                              <BrandSliderImage>
                                    <img
                                          src="/images/img/home/brands/brand5.jpg"
                                          alt="Prime Ceramics - Client Images"
                                          width="100%"
                                          height="100%"
                                    />
                              </BrandSliderImage>
                              <BrandSliderImage>
                                    <img
                                          src="/images/img/home/brands/brand2.jpg"
                                          alt="Prime Ceramics - Client Images"
                                          width="100%"
                                          height="100%"
                                    />
                              </BrandSliderImage>
                        </SwiperSlide>
                        <SwiperSlide>
                              <BrandSliderImage>
                                    <img
                                          src="/images/img/home/brands/brand6.jpg"
                                          alt="Prime Ceramics - Client Images"
                                          width="100%"
                                          height="100%"
                                    />
                              </BrandSliderImage>
                              <BrandSliderImage>
                                    <img
                                          src="/images/img/home/brands/brand3.jpg"
                                          alt="Prime Ceramics - Client Images"
                                          width="100%"
                                          height="100%"
                                    />
                              </BrandSliderImage>
                        </SwiperSlide>
                  </Swiper>
            </>
      );
};

export default HomeBrandSlider;
