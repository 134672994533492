import React from 'react';
import Breadcrumb from '../components/common/Breadcrumb';
import { Container } from 'react-bootstrap';
import {
      AboutContent,
      AboutDetail,
      AboutItem,
      AboutSummary,
      AboutTitle,
      BannerContainer,
      BannerImage
} from '../components/styles/aboutStyle';
import { Helmet } from 'react-helmet';

const Sustainability = () => {
      return (
            <>
                  <Helmet>
                        <link rel="canonical" href={window.location.href} />
                        <title>Sustainability | Prime Tiles with Stile</title>
                  </Helmet>
                  <Breadcrumb
                        title="Sustainability"
                        summary="Sustainability"
                        image="url(/images/img/breadcrumb2.png)"
                        backgroundPosition="center"
                  />
                  <Container>
                        <AboutContent>
                              <BannerContainer>
                                    <AboutItem>
                                          <AboutDetail>
                                                <AboutTitle>
                                                      Sustainability
                                                </AboutTitle>
                                                <p>
                                                      Environment, health,
                                                      safety, and society (EHSS)
                                                      always remain at the core
                                                      of the PCPL’s operation.
                                                </p>
                                          </AboutDetail>
                                    </AboutItem>
                              </BannerContainer>
                        </AboutContent>
                        <AboutContent>
                              <BannerContainer>
                                    <AboutItem>
                                          <AboutDetail>
                                                <AboutSummary>
                                                      Air, Water, Noise and
                                                      Environment
                                                </AboutSummary>
                                                <p>
                                                      The company adopts
                                                      sustainable environmental
                                                      practices. A typical tile
                                                      plant usually discharges
                                                      dust all around, given its
                                                      very nature of production.
                                                      SACMI’s dust collectors,
                                                      however, run relentlessly
                                                      to collect these particles
                                                      and bring them into reuse.
                                                      The settling tanks for
                                                      ball mills and waste water
                                                      churn the used water to be
                                                      reused. The air, water,
                                                      and noise indices are
                                                      maintained at the desired
                                                      levels. We stand committed
                                                      to comply with the
                                                      Environmental laws of the
                                                      land including the
                                                      requirements of
                                                      Environment Protection
                                                      Act.
                                                </p>
                                          </AboutDetail>
                                    </AboutItem>
                                    <BannerImage image='url("/images/sustainability/dust.jpg")' />
                              </BannerContainer>
                              <BannerContainer>
                                    <BannerImage
                                          image='url("/images/ourplant/plant6.jpg")'
                                          position="left"
                                    />
                                    <AboutItem>
                                          <AboutDetail>
                                                <AboutSummary>
                                                      Energy
                                                </AboutSummary>
                                                <p>
                                                      Energy efficiency and use
                                                      of cleaner fuel has been
                                                      ensured. The hot air
                                                      recovery from Klin is used
                                                      as fuel for Horizontal
                                                      Dryer. The electrical
                                                      system has its inverter
                                                      drives which ensure no
                                                      power is wasted as such
                                                      and every unit is duly
                                                      utilized. The overall
                                                      electrical system is
                                                      designed by best brains of
                                                      SACMI Italyt. Nepal’s
                                                      clean and green hydropower
                                                      finds its way as primary
                                                      source of power. Use of
                                                      coal is minimal and only
                                                      used for essential hot air
                                                      generation. Gas is used
                                                      just to augment the firing
                                                      temperature. We
                                                      continuously strive to
                                                      reduce energy consumption,
                                                      use efficient gadgets and
                                                      equipment’s and ensure
                                                      maximizing use of
                                                      renewable energy.
                                                </p>
                                          </AboutDetail>
                                    </AboutItem>
                              </BannerContainer>
                              <BannerContainer>
                                    <AboutItem>
                                          <AboutDetail>
                                                <AboutSummary>
                                                      People
                                                </AboutSummary>
                                                <p>
                                                      The workforce at PCPL is
                                                      benefitted with sound
                                                      Occupational Health and
                                                      Safety Practices. Use of
                                                      proper Personal Protective
                                                      Equipments ( PPE), work
                                                      hours, nutrition, work
                                                      procedures, safety first,
                                                      medical aids etc. ensure
                                                      we work in a responsible
                                                      manner. Qualified EHSS
                                                      officers ensure that we
                                                      adopt the learned way of
                                                      doing things in proper
                                                      manner.
                                                </p>
                                                <p>
                                                      The company feels proud to
                                                      maintain an
                                                      equal-opportunity employer
                                                      status. Gender equality is
                                                      evident with a sizeable
                                                      female population joining
                                                      the workforce. In the head
                                                      office of the company, the
                                                      number of female employees
                                                      exceeds the number of
                                                      males. Local women are
                                                      always given priority when
                                                      it comes to providing
                                                      employment in the
                                                      production plant.
                                                </p>
                                                <p>
                                                      Training and development
                                                      that leads to continuous
                                                      capacity building as well
                                                      as the well-being of the
                                                      staff has been an integral
                                                      part of the management
                                                      philosophy.
                                                </p>
                                                <p>
                                                      Engagement with local
                                                      community and working
                                                      together with them is
                                                      always in priority.
                                                </p>
                                          </AboutDetail>
                                    </AboutItem>
                                    <BannerImage image='url("/images/sustainability/people.jpg")' />
                              </BannerContainer>
                              <BannerContainer>
                                    {/* <BannerImage
                                          // image='url("/images/ourplant/plant5.jpg")'
                                          position="left"
                                    /> */}
                                    <AboutItem className="w-100">
                                          <AboutDetail className="ps-0">
                                                <AboutSummary>
                                                      Value Addition
                                                </AboutSummary>
                                                <p>
                                                      The use of local raw
                                                      materials brings value
                                                      addition and a strong
                                                      sustainable business model
                                                      for the company. We take
                                                      pride in confirming that
                                                      we have majority local
                                                      value addition with an aim
                                                      to reach up to 90% in next
                                                      2-3 years.
                                                </p>
                                                <p>
                                                      Replacing expensive
                                                      imports that run through
                                                      thousands of kilometers to
                                                      reach to consumer is a
                                                      significant contribution
                                                      to the economy. The
                                                      overall cost of
                                                      construction goes down,
                                                      the living standard of
                                                      common man is elevated and
                                                      overall cost in value
                                                      chain is reduced. Local
                                                      production, import
                                                      substation, saving of
                                                      foreign currency reserves,
                                                      generation of local
                                                      employment, contribution
                                                      to national exchequer and
                                                      fostering entrepreneurship
                                                      provides a clear win win
                                                      for the country and its
                                                      citizens.
                                                </p>
                                                <p>
                                                      Every tile produced at
                                                      Prime Ceramics reduces the
                                                      carbon footprint and
                                                      greens the home. We thrive
                                                      to increase our
                                                      sustainability level with
                                                      passing time.
                                                </p>
                                          </AboutDetail>
                                    </AboutItem>
                              </BannerContainer>
                        </AboutContent>
                  </Container>
            </>
      );
};

export default Sustainability;
