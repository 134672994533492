import styled from 'styled-components';
import { TestimonialTitle } from './testimonialStyle';

export const BrandBox = styled.section`
      background-image: linear-gradient(
            315deg,
            #f2f2f2 51%,
            ${(props) => props.theme.primaryColor} 0%
      );
      background-color: transparent;
      opacity: 1;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      position: relative;
      height: 588px;

      @media screen and (max-width: 1200px) {
            background-image: none;

            .col-lg-6 {
                  &:first-child {
                        order: 2;
                  }

                  &:last-child {
                        padding-top: 250px;
                        order: 1;
                  }
            }
      }
      @media screen and (max-width:767px){
            .col-lg-6 {
                  &:first-child {
                        order: 2;
                  }

                  &:last-child {
                        padding-top:1px;
                        order: 1;
                  }
            }
      }
`;

export const BrandBackgroundImage = styled.div`
      position: absolute;
      top: -30px;
      left: 20px;
`;

export const BrandContainer = styled.div`
      position: relative;
      z-index: 0;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
`;

export const BrandSlider = styled.div``;

export const BrandContent = styled.div`
      width: 70%;
      height: 100%;
      margin: auto;
      padding-top: 80px;

      @media screen and (max-width: 1068px) {
            justify-content: center !important;
            margin-top: 30px;
      }
      @media screen and (max-width: 944px) {
            justify-content: center !important;
            margin-top: 30px;
            width: 100%;
      }
      @media screen and (max-width:767px){
            padding-top:0px
      }
`;

export const BrandContentTitle = styled.div`
      position: relative;
      margin-bottom: 1.5rem;

      span:nth-child(1) {
            background-color: ${(props) => props.theme.primaryColor};
            padding: 15px;
            border-radius: 50%;
      }

      span:nth-child(2) {
            text-transform: uppercase;
            margin-left: 1rem;
            margin-right: 1.5rem;
            color: ${(props) => props.theme.primaryColor};
            padding: 0px 15px 0px 0px;
            border-radius: 50%;
      }

      h2 {
            font-size: 2.5rem;
            font-weight: 400;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
      }
      &::after {
            position: absolute;
            content: url(/images/img/home/brands/brandpattern.png);
            right: 0;
            top: -50px;

            @media screen and (max-width: 1200px) {
                  display: none;
            }
      }
      @media screen and (max-width:767px){
            h2{
                  font-size:2rem;
            }
      }
`;
export const BrandHeading = styled.h2`
      font-size:  2.25rem!important;
      font-weight: 400;
      span {
            color: ${(props) => props.theme.primaryColor};
            background-color: transparent !important;
      }
`;

export const BrandContentDescription = styled.div`
      p {
            font-style: italic;
            font-weight: 300;
            font-size: 1.125rem;
            line-height: 22px;
            color: #555555;
            z-index: 9999;
      }
      @media screen and (max-width:767px){
            p{
                  font-size:1rem;
                  text-align:justify;
            }
      }
`;

export const BrandSliderImage = styled.div`
      margin-bottom: 30px;
      display:flex;
      justify-content:center;
      align-items:center;

      img {
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
            border-radius: 5px;
            width: 100%;
            height:auto;
      }
      @media screen and (max-width:767px){
            img{
                  width:170px;
                  height:150px;
            }
      }
      @media screen and (max-width:400px){
            img{
                  width:150px;
                  height:100px;
            }
      }
`;
