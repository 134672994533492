import React, { useEffect, useState } from 'react';
import Breadcrumb from '../components/common/Breadcrumb';
import { Container, Spinner } from 'react-bootstrap';
import { TermBody, TermContainer } from '../components/styles/termStyle';
import PageNotFound from '../pages';
import { Helmet } from 'react-helmet';
import axios from 'axios';

const TermAndCondition = () => {
      const [loading, setLoading] = useState(false);
      const [policy, setPolicy] = useState([]);

      const loadData = async () => {
            setLoading(true);
            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/pages/terms-and-condition`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setPolicy(response.data.page);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Helmet>
                        <link rel="canonical" href={window.location.href} />
                        <title>
                              Terms & Condition | Prime Tiles with Stile
                        </title>
                  </Helmet>
                  <Breadcrumb
                        title="Terms & Condition"
                        summary="Terms & Condition"
                  />
                  <Container className="mt-5">
                        <TermContainer>
                              {!loading ? (
                                    <TermBody
                                          dangerouslySetInnerHTML={{
                                                __html: policy.description
                                          }}
                                    ></TermBody>
                              ) : (
                                    <Spinner />
                              )}
                        </TermContainer>
                  </Container>
            </>
      );
};

export default TermAndCondition;
