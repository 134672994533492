import React from 'react';

function MessengerIcon() {
      return (
            <>
                  <svg
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        style={{
                              width: '100%',
                              height: '100%',
                              fill: 'rgb(255, 255, 255)',
                              stroke: 'none'
                        }}
                  >
                        <path d="M16 6C9.925 6 5 10.56 5 16.185c0 3.205 1.6 6.065 4.1 7.932V28l3.745-2.056c1 .277 2.058.426 3.155.426 6.075 0 11-4.56 11-10.185C27 10.56 22.075 6 16 6zm1.093 13.716l-2.8-2.988-5.467 2.988 6.013-6.383 2.868 2.988 5.398-2.987-6.013 6.383z"></path>
                  </svg>
            </>
      );
}

export default MessengerIcon;
