import styled from 'styled-components';

export const AboutContent = styled.div`
      margin: 50px 0;
      background-color: ${(props) => props.theme.white};

      @media screen and (max-width: 568px) {
            padding: 0;
      }
`;

export const BannerImage = styled.div`
      width: 50%;
      background-image: ${(props) =>
            props.image ? props.image : "url('./video-bg.jpg')"};
      background-repeat: no-repeat;
      background-size: cover;
      background-position: ${(props) =>
            props.position ? props.position : 'center'};

      img {
            width: 100%;
      }

      @media screen and (max-width: 1200px) {
            width: 100%;
            height: auto;
            background-repeat: round;
            height: 320px;
      }
`;

export const BannerDetail = styled.div`
      padding: 0 5%;
      width: 50%;

      p {
            font-size: 2.5rem;
      }
`;

export const AboutItem = styled.div`
      display: flex;
      position: relative;
      width: 80%;
      padding-left: ${(props) => (props.padding ? props.padding : 0)};

      @media screen and (max-width: 1200px) {
            width: 100%;
            margin-top: 20px;
            padding-left: 0;
      }
`;

export const AboutDetail = styled.div`
      font-size: 18px;
      text-align: justify;
      padding-right: 30px;

      ul li {
            list-style: disc;
      }

      @media screen and (max-width: 512px) {
            padding-right: 0;
      }
`;

export const BannerContainer = styled.div`
      display: flex;
      margin-bottom: 50px;
      margin-left: 50px;
      margin-right: 50px;
      padding: 30px;

      @media screen and (max-width: 1200px) {
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
            display: grid;
            padding-top: 0 !important;

            &:nth-child(odd) {
                  ${BannerImage} {
                        order: 1 !important;
                        background-repeat: no-repeat;
                        background-position: center;
                  }

                  ${AboutItem} {
                        order: 2 !important;
                  }
            }

            &:nth-child(even) {
                  ${BannerImage} {
                        order: 1 !important;
                        background-repeat: no-repeat;
                        background-position: center;
                  }

                  ${AboutItem} {
                        order: 2 !important;
                  }
            }
      }

      &:nth-child(even) {
            ${AboutDetail} {
                  padding-left: 30px;
                  padding-right: 0;
            }

            ${BannerImage} {
                  background-position: left;
            }

            @media screen and (max-width: 756px) {
                  ${AboutDetail} {
                        padding-left: 0 !important;
                  }
            }
      }
`;

export const AboutTitle = styled.div`
      font-size: 34px;
      padding-bottom: 10px;
`;

export const AboutSummary = styled.div`
      padding-bottom: 20px;
      font-size: 28px;
`;

export const AboutDetailSummary = styled.h4`
      margin-bottom: 0.25rem;
`;
