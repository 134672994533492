import styled from 'styled-components';

export const DealerForm = styled.div`
      background-color: #ffff;
      padding: 10px;
      margin-top: 40px;
      border-radius: 10px;
      h3{
            font-size:16px;
            padding:0px 30px; 
      }
`;
