import React from 'react';
import {
      FooterItemContent,
      FooterMobile,
      FooterMobileLink
} from '../../frontend/components/layouts/footer';
import { Tab, Tabs } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import FooterCategoryItem from '../../frontend/components/footerCategory/FooterCategoryItem';
import { SubscribeFooter } from './SubscribeFooter';

export const MobileFooter = () => {
      return (
            <>
                  <FooterMobile>
                        <FooterMobileLink>
                              <Tabs
                                    defaultActiveKey="home"
                                    id="uncontrolled-tab-example"
                                    className="mb-3"
                              >
                                    <Tab eventKey="home" title="About Us">
                                          <FooterItemContent
                                                lineHeight={1.8}
                                                fontSize="1rem"
                                                fontWeight="400"
                                                textColor="#a1a1a1"
                                          >
                                                <ul>
                                                      <FooterCategoryItem
                                                            title="Who are we?"
                                                            slug="about-us"
                                                      />
                                                      <FooterCategoryItem
                                                            title="Career"
                                                            slug="career"
                                                      />
                                                      <FooterCategoryItem
                                                            title="CSR"
                                                            slug="csr"
                                                      />
                                                </ul>
                                          </FooterItemContent>
                                    </Tab>

                                    <Tab
                                          eventKey="usefullinks"
                                          title="Useful Links"
                                    >
                                          <FooterItemContent
                                                lineHeight={1.8}
                                                fontSize="1rem"
                                                fontWeight="400"
                                                textColor="#a1a1a1"
                                          >
                                                <ul>
                                                      <FooterCategoryItem
                                                            title="FAQs"
                                                            slug="faqs"
                                                      />
                                                      <FooterCategoryItem
                                                            title="Contact Us"
                                                            slug="contact-us"
                                                      />
                                                      <FooterCategoryItem
                                                            title="Dealer Contact"
                                                            slug="dealer-contact"
                                                      />
                                                </ul>
                                          </FooterItemContent>
                                    </Tab>

                                    <Tab
                                          eventKey="quicklinkd"
                                          title="Quick Link"
                                    >
                                          <FooterItemContent
                                                lineHeight={1.8}
                                                fontSize="1rem"
                                                fontWeight="400"
                                                textColor="#a1a1a1"
                                          >
                                                <ul>
                                                      <FooterCategoryItem
                                                            title="Blog / Event"
                                                            slug="blogs"
                                                      />
                                                      <FooterCategoryItem
                                                            title="Privacy Policy"
                                                            slug="privacy-policy"
                                                      />
                                                      <FooterCategoryItem
                                                            title="Terms & Condition"
                                                            slug="terms-and-condition"
                                                      />
                                                </ul>
                                          </FooterItemContent>
                                    </Tab>

                                    <Tab eventKey="contact" title="Contact Us">
                                          <FooterItemContent
                                                lineHeight={1.8}
                                                fontSize="1rem"
                                                fontWeight="400"
                                                textColor="#a1a1a1"
                                          >
                                                <ul>
                                                      <FooterCategoryItem
                                                            title="18105000062"
                                                            slug="tel:18105000062"
                                                      />
                                                      <FooterCategoryItem
                                                            title="Tripureshwor, Kathmandu"
                                                            slug="#"
                                                      />
                                                      <FooterCategoryItem
                                                            title="info@primeceramics.com.np"
                                                            slug="mailto:info@primeceramics.com.np"
                                                      />
                                                </ul>
                                          </FooterItemContent>
                                    </Tab>
                              </Tabs>
                        </FooterMobileLink>
                        <SubscribeFooter />
                  </FooterMobile>
            </>
      );
};
