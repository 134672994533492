import { Nav } from 'react-bootstrap';
import styled from 'styled-components';

export const FilterIcon = styled.div`
      text-align: center;
      /* height: 55px; */
      width: 83px;

      img {
            height: 55px;
            object-fit: contain;
            width: 48px;
      }
`;

export const FilterContainer = styled.div`
      background-color: ${(props) => props.theme.white};

      @media screen and (max-width: 1200px) {
            display: none;
      }
`;

export const MobileFilterContainer = styled.div`
      display: none;

      @media screen and (max-width: 1200px) {
            display: block;
            padding: 20px 50px;

            ${FilterContainer} {
                  display: none;
            }

            .offcanvas-body {
                  padding: 0;
                  border-top: 1px solid #f2f2f2;
            }
      }
`;

export const FilterItem = styled.div`
      padding: 10px;
      margin-bottom: 2.5px;
`;

export const FilterTitle = styled.div`
      text-align: center;
`;

export const NavLink = styled(Nav.Link)`
      background-color: #f2f2f2;
      color: #333;
`;

export const FilterBody = styled.div`
      display: flex;
      width: 380px;
      background-color: ${(props) => props.theme.white};

      ${NavLink} {
            padding: 0;

            &:hover {
                  color: ${(props) => props.theme.white};
                  background-color: #666 !important;

                  ${FilterIcon} svg {
                        fill: white !important;
                  }
            }
      }
`;

export const FilterContentCategory = styled.div`
      padding: 20px;
      margin-left: 2.5px;
      background-color: ${(props) => props.theme.white};
      width: calc(100% - 104px);
      overflow-y: auto;
      max-height: 607px;
`;

export const FilterCategory = styled.div`
      display: grid;
      gap: 10px;
`;

export const FilterCategoryItem = styled.div`
      a {
            border: 1px solid #666;
            padding: 6px 20px;
            border-radius: 20px;
            display: inline-block;
            background-color: #f2f2f2;
            width: 100%;
            font-size: 16px;
            color: #68798b;
            height: 100%;
            max-width: fit-content;

            &:hover {
                  background-color: #666;
                  color: ${(props) => props.theme.white};
            }
      }
`;

export const CategoryContainer = styled.div`
      display: flex;
      margin-bottom: 50px;
`;

export const CategoryProduct = styled.div`
      width: calc(100% - 380px);

      @media screen and (max-width: 1200px) {
            width: 100%;
      }
`;

export const CategoryContent = styled.div`
      padding: 20px 50px;
`;

export const CategoryHeader = styled.div``;

export const CategorySummary = styled.div`
      font-size: 16px;
      margin: 20px 0;
`;

export const CategoryTitle = styled.div`
      font-size: 24px;
`;

export const CategoryProductItem = styled.div`
      border: 1px solid #f2f2f2;
      height: 100%;
      background-color: ${(props) => props.theme.white};

      img {
            padding: 15px;
            width: 100%;
      }
`;

export const CategoryProductImage = styled.div`
      img {
            height: 260px;
            width: 328px;
            object-fit: contain;
      }

      a {
            display: inline-block;
            width: 100%;
            height: 100%;
      }
`;

export const CategoryProductVisualizer = styled.div`
      display: flex;
      padding: 15px;
      justify-content: space-between;
`;

export const CategoryProductPrime = styled.div`
      font-size: 13px;
      /* border: 1px solid #666; */
      padding: 1px 5px;
      display: inline-block;
      background-color: #666;
      color: white;
      border-radius: 0;

      &:hover {
            color: white;
            /* background-color: #333; */
            padding: 1px 5px;
      }
`;

export const CategoryProductTitle = styled.div`
      a {
            padding: 0 15px;
            font-weight: 500;
            color: #666;
      }
`;

export const CategoryProductAmount = styled.div`
      display: flex;
      justify-content: space-between;
      padding: 10px 15px;
      align-items: center;
`;

export const CategoryProductPrice = styled.div`
      color: #666;
`;

export const CategoryProductSize = styled.div`
      font-size: 14px;
      padding: 0px 15px 10px;
      color: #666;
`;
