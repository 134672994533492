import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Filter from './Filter';
import './style.css';
import { useParams } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Visualizer from '../visualizer';
import Breadcrumb from '../components/common/Breadcrumb';
import Product from './Product';
import {
      CategoryContainer,
      CategoryContent,
      CategoryHeader,
      CategoryProduct,
      CategorySummary,
      FilterContainer,
      MobileFilterContainer
} from '../components/styles/productStyle/categoryPage';
import MobileFilter from './MobileFilter';

import Progress from 'react-progress-2';
import 'react-progress-2/main.css';
import Pagination from 'react-js-pagination';
import { Row, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';

const Category = () => {
      const { categoryId } = useParams();
      const { slugId } = useParams();
      const [category, setCategory] = useState([]);
      const [loading, setLoading] = useState(false);

      const [fullscreen, setFullscreen] = useState(true);
      const [show, setShow] = useState(false);

      function handleShow(breakpoint) {
            setFullscreen(breakpoint);
            setShow(true);
      }

      const [products, setProducts] = useState([]);
      const [totalProducts, setTotalProducts] = useState(0);
      const [perPages, setPerPages] = useState(0);
      const [currentPage, setCurrentPage] = useState(0);

      const loadData = async () => {
            setLoading(true);
            const data = {
                  type: categoryId,
                  slug: slugId
            };
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/categories/navigation/all`,
                        data
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setProducts(response.data.products);
                              setCategory(response.data.category);
                              setTotalProducts(response.data.totalProducts);
                              setPerPages(response.data.itemsCountPerPage);
                              setCurrentPage(response.data.currentPage);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      };
      useEffect(() => {
            loadData();
      }, [categoryId]);

      async function getProductData(pageNumber = 1) {
            setLoading(true);
            window.scrollTo(0, 0);

            const data = {
                  type: categoryId,
                  slug: slugId
            };

            // console.log(pageNumber);
            const url = `${process.env.REACT_APP_SECRET_KEY}/api/categories/navigation/all?page=${pageNumber}`;
            await axios
                  .post(url, data)
                  .then((response) => {
                        setProducts(response.data.products);
                        setTotalProducts(response.data.totalProducts);
                        setPerPages(response.data.itemsCountPerPage);
                        setCurrentPage(response.data.currentPage);
                        // Progress.hide();
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });
            setLoading(false);
      }

      return (
            <>
                  {!loading && (
                        <Helmet>
                              <link
                                    rel="canonical"
                                    href={window.location.href}
                              />
                              <title>
                                    {`${category.title} in Nepal | Prime Ceramics -
                                    Tile with Stile`}
                              </title>
                              <meta
                                    name="keywords"
                                    content="prime ceramics, prime ceramic tiles, tiles in nepal, nepali tiles manufacturers, nepali ceramic tiles manufacturers, nepali vitrified tiles manufacturers, nepali porcelain tiles manufacturers, nepali marble tiles manufacturers, nepali granite tiles manufacturers, nepali bathroom tiles manufacturers, nepali kitchen tiles manufacturers, nepali floor tiles manufacturers, nepali wall tiles manufacturers, nepali outdoor tiles manufacturers"
                              />
                              <meta
                                    name="description"
                                    content="Prime Ceramics - Tile with Stile: Your premier choice for high-quality tile manufacturing in Nepal. As a leading tile factory in Nepal, Prime Ceramics offers a wide range of Nepali ceramic tiles, including durable floor tiles, decorative wall tiles, vitrified tiles, porcelain tiles, marble tiles, granite tiles, bathroom tiles, and kitchen tiles. Discover innovative tile solutions and custom designs with Prime Ceramics - your trusted name in quality tiles in Nepal."
                              />
                        </Helmet>
                  )}
                  <Progress.Component
                        style={{ background: '#99999978', height: '5px' }}
                        thumbStyle={{
                              background: 'rgb(215, 26, 33)',
                              height: '5px'
                        }}
                  />
                  <Breadcrumb
                        title={`${category.title}`}
                        summary={category.title}
                  />
                  <MobileFilterContainer>
                        <MobileFilter />
                  </MobileFilterContainer>
                  <CategoryContainer>
                        <FilterContainer>
                              <Filter type={categoryId} />
                        </FilterContainer>
                        <CategoryProduct>
                              <CategoryContent>
                                    <CategoryHeader>
                                          {/* <CategoryTitle>
                                                {category.title}{' '}
                                                {categoryId == 'categories'
                                                      ? ''
                                                      : 'for floor & wall'}
                                          </CategoryTitle> */}
                                          <CategorySummary>
                                                <div
                                                      dangerouslySetInnerHTML={{
                                                            __html: category.description
                                                      }}
                                                ></div>
                                          </CategorySummary>
                                    </CategoryHeader>
                                    <div className="category__body">
                                          <div className="category__product-content">
                                                <Row className="g-4">
                                                      {!loading ? (
                                                            products?.length >
                                                                  0 &&
                                                            products?.map(
                                                                  (
                                                                        product,
                                                                        index
                                                                  ) => (
                                                                        <div
                                                                              className="col-lg-4 col-md-6"
                                                                              key={
                                                                                    index
                                                                              }
                                                                        >
                                                                              <Product
                                                                                    product={
                                                                                          product
                                                                                    }
                                                                                    handleShow={
                                                                                          handleShow
                                                                                    }
                                                                              />
                                                                        </div>
                                                                  )
                                                            )
                                                      ) : (
                                                            <div
                                                                  className="text-center"
                                                                  style={{
                                                                        height: '420px'
                                                                  }}
                                                            >
                                                                  <Spinner />
                                                            </div>
                                                      )}
                                                </Row>
                                                <div className="float-end">
                                                      {totalProducts > 12 && (
                                                            <Pagination
                                                                  activePage={
                                                                        currentPage
                                                                  }
                                                                  totalItemsCount={
                                                                        totalProducts
                                                                  }
                                                                  itemsCountPerPage={
                                                                        perPages
                                                                  }
                                                                  onChange={(
                                                                        pageNumber
                                                                  ) =>
                                                                        getProductData(
                                                                              pageNumber
                                                                        )
                                                                  }
                                                                  itemClass="page-item"
                                                                  linkClass="page-link"
                                                                  firstPageText="First"
                                                                  lastPageText="Last"
                                                            />
                                                      )}
                                                </div>
                                          </div>
                                    </div>
                              </CategoryContent>
                        </CategoryProduct>
                  </CategoryContainer>
                  <Modal
                        style={{ zIndex: '999999999' }}
                        show={show}
                        fullscreen={fullscreen}
                        onHide={() => setShow(false)}
                  >
                        <Modal.Header closeButton>
                              <Modal.Title>Chose Your Room</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                              style={{ padding: '0', overflow: 'hidden' }}
                        >
                              <Visualizer />
                        </Modal.Body>
                  </Modal>
            </>
      );
};

export default Category;
