import React, { useEffect, useState } from 'react';

import { FooterCategory } from '../../frontend/components/layouts/footer';
import FooterCategoryItemContainer from '../../frontend/components/footerCategory/FooterCategory';
import { Row } from 'react-bootstrap';
import axios from 'axios';

export const CategoryFooter = () => {
      const [loading, setLoading] = useState(false);
      const [categories, setCategories] = useState([]);
      const [areas, setAreas] = useState([]);
      const [designs, setDesigns] = useState([]);
      const [sizes, setSizes] = useState([]);
      const [colors, setColors] = useState([]);
      const [types, setTypes] = useState([]);
      const [finishes, setFinishes] = useState([]);

      useEffect(() => {
            const fetchData = async () => {
                  setLoading(true);
                  await axios.get(`${process.env.REACT_APP_SECRET_KEY}/api/footers/items?limit=10`).then((response) => {
                        setAreas(response.data.items.areas);
                        setCategories(response.data.items.categories);
                        setDesigns(response.data.items.designs);
                        setTypes(response.data.items.types);
                        setColors(response.data.items.colors);
                        setSizes(response.data.items.sizes);
                        setFinishes(response.data.items.finishes);
                  });
                  setLoading(false);
            }
            fetchData();
      }, []);

      return (
            <>
                  <FooterCategory>
                        <Row>
                              <FooterCategoryItemContainer
                                    items={categories}
                                    loading={loading}
                                    type="categories"
                                    title="Tiles" />
                              <FooterCategoryItemContainer
                                    items={areas}
                                    loading={loading}
                                    type="areas"
                                    title="Tiles by Area" />
                              <FooterCategoryItemContainer
                                    items={designs}
                                    loading={loading}
                                    type="designs"
                                    title="Tiles by Design" />
                              <FooterCategoryItemContainer
                                    items={types}
                                    loading={loading}
                                    type="types"
                                    title="Tiles by Type" />
                              <FooterCategoryItemContainer
                                    items={colors}
                                    loading={loading}
                                    type="colors"
                                    title="Tiles by Colors" />
                              <FooterCategoryItemContainer
                                    items={sizes}
                                    loading={loading}
                                    type="sizes"
                                    title="Tiles by Sizes" />
                              <FooterCategoryItemContainer
                                    items={finishes}
                                    loading={loading}
                                    type="finishes"
                                    title="Tiles by Finish" />

                        </Row>
                  </FooterCategory>
            </>
      )
}
