import axios from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom';
import ColorIcon from '../components/icons/ColorIcon';
import RoomIcon from '../components/icons/RoomIcon';
import TypeIcon from '../components/icons/TypeIcon';
import Finish from '../components/icons/Finish';
import TilesIcon from '../components/icons/TilesIcon';
import DesignIcon from '../components/icons/DesignIcon';
import AreaIcon from '../components/icons/AreaIcon';
import {
      FilterBody,
      FilterCategory,
      FilterCategoryItem,
      FilterContentCategory,
      FilterIcon,
      FilterItem,
      FilterTitle,
      NavLink
} from '../components/styles/productStyle/categoryPage';

const Filter = ({ type }) => {
      const [loading, setLoading] = useState(false);
      const [categories, setCategories] = useState([]);
      const [areas, setAreas] = useState([]);
      const [designs, setDesigns] = useState([]);
      const [sizes, setSizes] = useState([]);
      const [colors, setColors] = useState([]);
      const [types, setTypes] = useState([]);
      const [finishes, setFinishes] = useState([]);

      useEffect(() => {
            const fetchData = async () => {
                  setLoading(true);
                  await axios
                        .get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/footers/items?limit=100`
                        )
                        .then((response) => {
                              setAreas(response.data.items.areas);
                              setCategories(response.data.items.categories);
                              setDesigns(response.data.items.designs);
                              setTypes(response.data.items.types);
                              setColors(response.data.items.colors);
                              setSizes(response.data.items.sizes);
                              setFinishes(response.data.items.finishes);
                        });
                  setLoading(false);
            };
            fetchData();
      }, []);
      return (
            <>
                  <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey={type ? type : 'areas'}
                  >
                        <FilterBody>
                              <div className="filter__body-icon">
                                    <Nav className="filter__content flex-column">
                                          <Nav.Item>
                                                <NavLink eventKey="areas">
                                                      <FilterItem>
                                                            <FilterIcon>
                                                                  <RoomIcon />
                                                            </FilterIcon>
                                                            <FilterTitle>
                                                                  Room
                                                            </FilterTitle>
                                                      </FilterItem>
                                                </NavLink>
                                          </Nav.Item>
                                          <Nav.Item>
                                                <NavLink eventKey="sizes">
                                                      <FilterItem>
                                                            <FilterIcon>
                                                                  <AreaIcon />
                                                            </FilterIcon>
                                                            <FilterTitle>
                                                                  Size
                                                            </FilterTitle>
                                                      </FilterItem>
                                                </NavLink>
                                          </Nav.Item>
                                          <Nav.Item>
                                                <NavLink eventKey="types">
                                                      <FilterItem>
                                                            <FilterIcon>
                                                                  <TypeIcon />
                                                            </FilterIcon>
                                                            <FilterTitle>
                                                                  Type
                                                            </FilterTitle>
                                                      </FilterItem>
                                                </NavLink>
                                          </Nav.Item>
                                          <Nav.Item>
                                                <NavLink eventKey="designs">
                                                      <FilterItem>
                                                            <FilterIcon>
                                                                  <DesignIcon />
                                                            </FilterIcon>
                                                            <FilterTitle>
                                                                  Design
                                                            </FilterTitle>
                                                      </FilterItem>
                                                </NavLink>
                                          </Nav.Item>
                                          <Nav.Item>
                                                <NavLink eventKey="colors">
                                                      <FilterItem>
                                                            <FilterIcon>
                                                                  <ColorIcon />
                                                            </FilterIcon>
                                                            <FilterTitle>
                                                                  Color
                                                            </FilterTitle>
                                                      </FilterItem>
                                                </NavLink>
                                          </Nav.Item>
                                          <Nav.Item>
                                                <NavLink eventKey="finishes">
                                                      <FilterItem>
                                                            <FilterIcon>
                                                                  <Finish />
                                                            </FilterIcon>
                                                            <FilterTitle>
                                                                  Finish
                                                            </FilterTitle>
                                                      </FilterItem>
                                                </NavLink>
                                          </Nav.Item>
                                    </Nav>
                              </div>
                              <FilterContentCategory>
                                    <Tab.Content>
                                          <Tab.Pane eventKey="areas">
                                                <FilterCategory>
                                                      {areas.map(
                                                            (item, index) => (
                                                                  <FilterCategoryItem
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <Link
                                                                              to={`/products/areas/${item.slug}`}
                                                                        >
                                                                              {
                                                                                    item.title
                                                                              }
                                                                        </Link>
                                                                  </FilterCategoryItem>
                                                            )
                                                      )}
                                                </FilterCategory>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="sizes">
                                                <FilterCategory>
                                                      {sizes.map(
                                                            (item, index) => (
                                                                  <FilterCategoryItem
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <Link
                                                                              to={`/products/sizes/${item.slug}`}
                                                                        >
                                                                              {
                                                                                    item.title
                                                                              }
                                                                        </Link>
                                                                  </FilterCategoryItem>
                                                            )
                                                      )}
                                                </FilterCategory>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="types">
                                                <FilterCategory>
                                                      {types.map(
                                                            (item, index) => (
                                                                  <FilterCategoryItem
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <Link
                                                                              to={`/products/types/${item.slug}`}
                                                                        >
                                                                              {
                                                                                    item.title
                                                                              }
                                                                        </Link>
                                                                  </FilterCategoryItem>
                                                            )
                                                      )}
                                                </FilterCategory>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="designs">
                                                <FilterCategory>
                                                      {designs.map(
                                                            (item, index) => (
                                                                  <FilterCategoryItem
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <Link
                                                                              to={`/products/designs/${item.slug}`}
                                                                        >
                                                                              {
                                                                                    item.title
                                                                              }
                                                                        </Link>
                                                                  </FilterCategoryItem>
                                                            )
                                                      )}
                                                </FilterCategory>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="colors">
                                                <FilterCategory>
                                                      {colors.map(
                                                            (item, index) => (
                                                                  <FilterCategoryItem
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <Link
                                                                              to={`/products/colors/${item.slug}`}
                                                                        >
                                                                              {
                                                                                    item.title
                                                                              }
                                                                        </Link>
                                                                  </FilterCategoryItem>
                                                            )
                                                      )}
                                                </FilterCategory>
                                          </Tab.Pane>
                                          <Tab.Pane eventKey="finishes">
                                                <FilterCategory>
                                                      {finishes.map(
                                                            (item, index) => (
                                                                  <FilterCategoryItem
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <Link
                                                                              to={`/products/finishes/${item.slug}`}
                                                                        >
                                                                              {
                                                                                    item.title
                                                                              }
                                                                        </Link>
                                                                  </FilterCategoryItem>
                                                            )
                                                      )}
                                                </FilterCategory>
                                          </Tab.Pane>
                                    </Tab.Content>
                              </FilterContentCategory>
                        </FilterBody>
                  </Tab.Container>
            </>
      );
};

export default Filter;
