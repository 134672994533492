import React, { useState } from 'react';
import {
      Header,
      HeaderContainer,
      HeaderContent,
      HeaderLogo,
      HeaderMobileMenu,
      HeaderNav
} from '../../frontend/components/layouts/header';
import { Link } from 'react-router-dom';
import MenuIcon from '../../frontend/components/icons/MenuIcon';
import LoginIcon from '../../frontend/components/icons/LoginIcon';
import StickyHeader from './StickyHeader';

import { Modal } from 'react-bootstrap';
import Visualizer from '../../frontend/visualizer';

const HomeHeader = ({ setShow }) => {
      const [fullscreen, setFullscreen] = useState(true);
      const [showVisualizer, setShowVisualizer] = useState(false);

      function handleShowVisualizer(breakpoint) {
            setFullscreen(breakpoint);
            setShowVisualizer(true);
      }

      return (
            <>
                  <Header>
                        <HeaderContainer>
                              <HeaderContent>
                                    <HeaderLogo>
                                          <Link to="/">
                                                <img
                                                      className="img-fluid"
                                                      width="130px"
                                                      height="54px"
                                                      alt="Prime Cermaics Pvt. Ltd. Logo"
                                                      src="/images/prime-logo.png"
                                                />
                                          </Link>
                                    </HeaderLogo>

                                    <HeaderNav>
                                          <nav>
                                                <ul>
                                                      <li>
                                                            <Link to="#">
                                                                  About Us
                                                            </Link>

                                                            <ul
                                                                  className={`submenu `}
                                                            >
                                                                  <li>
                                                                        <Link to="/about-us">
                                                                              Who
                                                                              we
                                                                              are?
                                                                        </Link>
                                                                  </li>
                                                                  <li>
                                                                        <Link to="/our-plant">
                                                                              Our
                                                                              Plant
                                                                        </Link>
                                                                  </li>
                                                                  {/* <li>
                                                                        <Link to="/gallery">
                                                                              Gallery
                                                                        </Link>
                                                                  </li> */}
                                                            </ul>
                                                      </li>
                                                      <li>
                                                            <Link to="/why-prime-tiles">
                                                                  Why Prime
                                                                  Tiles
                                                            </Link>
                                                      </li>
                                                      <li>
                                                            <Link to="/products">
                                                                  Products
                                                            </Link>
                                                      </li>
                                                      <li>
                                                            <Link to="/catalogues">
                                                                  Catalogue
                                                            </Link>
                                                      </li>
                                                      {/* <li> <Link to="#" onClick={handleShowVisualizer}> Visualizer </Link> </li> */}
                                                      <li>
                                                            <Link to="/sustainability">
                                                                  Sustainability
                                                            </Link>
                                                      </li>
                                                      <li>
                                                            <Link to="/contact-us">
                                                                  Contact Us
                                                            </Link>
                                                      </li>
                                                      <li>
                                                            <Link to="/dealer-contact">
                                                                  Dealers
                                                            </Link>
                                                      </li>
                                                      <li>
                                                            <Link
                                                                  to="https://portal.primeceramics.com.np/login"
                                                                  target="_blank"
                                                                  title="Prime Ceramics Portal"
                                                            >
                                                                  <LoginIcon />
                                                            </Link>
                                                      </li>
                                                </ul>
                                          </nav>
                                    </HeaderNav>
                              </HeaderContent>
                              <HeaderMobileMenu>
                                    <div id="header">
                                          <a
                                                href="#menu"
                                                onClick={() => setShow()}
                                          >
                                                {/* <MenuIcon /> */}
                                                <img
                                                      src="./images/img/sidebar.png"
                                                      alt="menu icon"
                                                      height="30"
                                                      width="30"
                                                />
                                          </a>
                                    </div>
                              </HeaderMobileMenu>
                        </HeaderContainer>
                  </Header>
                  <StickyHeader />

                  <Modal
                        style={{ zIndex: '999999999' }}
                        show={showVisualizer}
                        fullscreen={fullscreen}
                        onHide={() => setShowVisualizer(false)}
                  >
                        <Modal.Header closeButton>
                              <Modal.Title>Chose Your Room</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                              style={{ padding: '0', overflow: 'hidden' }}
                        >
                              <Visualizer />
                        </Modal.Body>
                  </Modal>
            </>
      );
};

export default HomeHeader;
