import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import Filter from './Filter';

const MobileFilter = () => {
      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      return (
            <>
                  <div className="mobile__filter-container">
                        <div className="mobile__filter-title">All Products</div>
                        <div
                              className="mobile__filter-button"
                              onClick={handleShow}
                        >
                              <div className="mobile__filter-icon">
                                    <span className="material-symbols-outlined">
                                          sort
                                    </span>
                              </div>
                              <div className="mobile__filter-icon--title">
                                    Filter
                              </div>
                        </div>
                  </div>
                  <Offcanvas show={show} onHide={handleClose}>
                        <Offcanvas.Header closeButton>
                              <Offcanvas.Title>Filter</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                              <Filter />
                        </Offcanvas.Body>
                  </Offcanvas>
            </>
      );
};

export default MobileFilter;
