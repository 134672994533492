import styled from 'styled-components';

export const BlogContainer = styled.div`
      margin: 50px 0;
      padding: 0 50px;

      @media screen and (max-width: 568px) {
            padding: 0 20px;
      }
`;

export const BlogHeader = styled.div`
      margin-bottom: 30px;

      h1 {
            color: ${(props) => props.theme.linkColor};
      }
`;

export const BlogContent = styled.div`
      display: flex;
`;

export const BlogItem = styled.div`
      background-color: ${(props) => props.theme.white};
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05);
      padding: 0;
      height: 100%;
      padding-bottom: 20px;

      @media screen and (max-width: 992px) {
            width: 100%;
      }
`;

export const BlogItemImage = styled.div`
      a {
            display: inline-block;
            width: 100%;
      }

      img {
            height: 234px;
            /* border-bottom: 1px solid ${(props) => props.theme.linkColor}; */
            padding: 10px;
            object-fit: contain;
            width: 100%;
      }
`;

export const BlogItemIcons = styled.div`
      display: flex;
      gap: 20px;
`;

export const BlogItemIcon = styled.div`
      margin: 10px 0;

      i,
      span {
            color: rgb(86, 86, 86);
      }

      span {
            margin-left: 10px;
      }
`;

export const BlogItemDescription = styled.div`
      padding: 10px 20px;
      min-height: 120px;

      p {
            color: rgb(85, 81, 81) !important;
            margin: 0;
      }

      h1 {
            font-size: 16px;
            font-weight: 600;
      }
`;

export const BlogItemButton = styled.div`
      margin: 20px;

      a {
            background: ${(props) => props.theme.primaryColor};
            border: 2px solid ${(props) => props.theme.primaryColor};
            color: ${(props) => props.theme.white};
            padding: 10px 24px;
            transition: 0.4s;

            &:hover {
                  color: ${(props) => props.theme.primaryColor};
                  background: ${(props) => props.theme.white};
            }
      }
`;
