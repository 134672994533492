import React from "react";

const TikTok = () => {
  return (
    <div>
      <svg
        version="1.1"
        id="svg1932"
        xmlSpace="preserve"
        width="682.66669"
        height="682.66669"
        viewBox="0 0 682.66669 682.66669"
        xmlns="http://www.w3.org/2000/svg"
                    xmlnsSvg="http://www.w3.org/2000/svg"
                    fill="#fff"
      >
        <defs id="defs1936">
          <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1946">
            <path d="M 0,512 H 512 V 0 H 0 Z" id="path1944" />
          </clipPath>
        </defs>
        <g id="g1938" transform="matrix(1.3333333,0,0,-1.3333333,0,682.66667)">
          <g id="g1940">
            <g id="g1942" clip-path="url(#clipPath1946)">
              <g id="g1948" transform="translate(464.8672,384.5342)">
                <path
                  d="m 0,0 c -62.115,0 -112.467,50.353 -112.467,112.466 h -80.334 V -216.9 c 0,-39.93 -32.369,-72.3 -72.301,-72.3 -39.929,0 -72.298,32.37 -72.298,72.3 0,39.929 32.369,72.299 72.298,72.299 v 80.334 c -84.294,0 -152.632,-68.337 -152.632,-152.633 0,-84.297 68.338,-152.634 152.632,-152.634 84.301,0 152.635,68.337 152.635,152.634 V -44.139 C -80.811,-66.912 -41.975,-80.334 0,-80.334 Z"
                  style={{fill:"#fff",stroke:"#fff",strokeWidth:"30",strokeLinecap:"round",strokeLineJoin:"round",strokeMiterlimit:"10",strokeDasharray:"none",strokeOpacity:"1"}}
                  id="path1950"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default TikTok;
