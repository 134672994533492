import React, { useState } from 'react';
import Breadcrumb from '../components/common/Breadcrumb';
import { Container, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Visualizer from '../visualizer';
import {
      AboutContent,
      AboutDetail,
      AboutItem,
      AboutSummary,
      AboutTitle,
      BannerContainer,
      BannerImage
} from '../components/styles/aboutStyle';
import { Helmet } from 'react-helmet';

const WhyPrimeTile = () => {
      const [fullscreen, setFullscreen] = useState(true);
      const [showVisualizer, setShowVisualizer] = useState(false);

      function handleShowVisualizer(breakpoint) {
            setFullscreen(breakpoint);
            setShowVisualizer(true);
      }
      return (
            <>
                  <Helmet>
                        <link rel="canonical" href={window.location.href} />
                        <title>Why Prime Tiles with Stile?</title>
                  </Helmet>
                  <Breadcrumb
                        title="Why Prime Tiles?"
                        summary="Why Prime Tiles?"
                  />
                  <Container>
                        <AboutContent>
                              <BannerContainer>
                                    <AboutItem>
                                          <AboutDetail>
                                                <AboutTitle>
                                                      Why Prime Tiles with{' '}
                                                      <i>Stile</i>?
                                                </AboutTitle>
                                                <AboutSummary>
                                                      Technology
                                                </AboutSummary>
                                                <p>
                                                      Crafted with the best raw
                                                      materials and run on SACMI
                                                      Italy turnkey state of art
                                                      plant, Prime Tiles are
                                                      class apart. The ceramic
                                                      engineering used in the
                                                      manufacturing process
                                                      encompasses the most
                                                      recent developments and
                                                      sophistication in ceramic
                                                      tile manufacturing
                                                      technology. SACMI –
                                                      ‘Ceramics better’ since
                                                      1919 is at its full show
                                                      at our plant. The wet
                                                      grinding body preparation,
                                                      pressing process,
                                                      automatic lines, glaze
                                                      line, digital printing,
                                                      superior kiln, packaging
                                                      and sorting are all from
                                                      the best in the industry.
                                                      Noteworthy is the
                                                      sophisticated robotic
                                                      sorting line at Prime
                                                      Tiles Manufacturing
                                                      facility, which ensure the
                                                      planarity, thickness,
                                                      sizes, shades and other
                                                      such critical parameters
                                                      are checked using infrared
                                                      technology and every tile
                                                      comes out as perfect piece
                                                      to be laid.
                                                      <Link to="/our-plant">
                                                            (Click here for
                                                            plant details.)
                                                      </Link>
                                                </p>
                                          </AboutDetail>
                                    </AboutItem>
                                    <BannerImage image='url("/images/ourplant/technology.jpg")' />
                              </BannerContainer>

                              <BannerContainer>
                                    <BannerImage image='url("/images/abouts/designs.png")' />
                                    <AboutItem>
                                          <AboutDetail>
                                                <AboutSummary>
                                                      Designs
                                                </AboutSummary>
                                                <p>
                                                      The tile designs have been
                                                      carefully selected based
                                                      on taste of consumers and
                                                      cover the most
                                                      contemporary stylish
                                                      patterns. The ultra modern
                                                      digital printing
                                                      technology and usage of
                                                      Spanish Inks ensures
                                                      flawless design patterns
                                                      and glow. Everyone has a
                                                      choice to make and all
                                                      choices are available. The
                                                      desire and aspiration of
                                                      our end-users have been
                                                      well studied, and they
                                                      would find our design to
                                                      be just what they have
                                                      been looking for.
                                                      <Link to="/products">
                                                            (Click here for
                                                            designs.)
                                                      </Link>
                                                </p>
                                                <p>
                                                      If you want to design your
                                                      house and see how the
                                                      tiles look, we have a
                                                      design tool.
                                                      <Link
                                                            onClick={
                                                                  handleShowVisualizer
                                                            }
                                                      >
                                                            (Click here for the
                                                            design tool)
                                                      </Link>
                                                </p>
                                          </AboutDetail>
                                    </AboutItem>
                              </BannerContainer>

                              <BannerContainer>
                                    <AboutItem>
                                          <AboutDetail>
                                                <AboutSummary>
                                                      One Stop Solution
                                                </AboutSummary>
                                                <p>
                                                      The Tiles covers almost
                                                      all sizes, designs and
                                                      usage and can be used
                                                      across segments like
                                                      residential, commercial,
                                                      public spaces,
                                                      infrastructure projects
                                                      and the like. Prime Tiles
                                                      offers ‘ One Stop
                                                      Solution’ with its array
                                                      of products across the
                                                      wall and floor segment.
                                                      The smallest to largest
                                                      sizes, the simplest to the
                                                      complex designs, the
                                                      roughest to the smooth
                                                      finishes, extra high gloss
                                                      to matt glazes, slimmest
                                                      to the thicker, Prime
                                                      offers choice and solution
                                                      for every consumer.
                                                      Currently the largest size
                                                      is 600X1200 mm.
                                                      <Link to="/products/sizes/600x1200-tiles">
                                                            (Click here for the
                                                            range of tiles)
                                                      </Link>
                                                </p>
                                                <p>
                                                      Prime Tiles offer a
                                                      complete array of flooring
                                                      solutions, which can
                                                      easily score over
                                                      traditional flooring
                                                      materials like marble,
                                                      wood, PVC etc. Prime has
                                                      all kind of wall and floor
                                                      tiles for usage across all
                                                      type of structures.
                                                </p>
                                                <p>
                                                      If you want home delivery,
                                                      we will be at your
                                                      doorsteps.
                                                      <Link to="/terms-and-condition">
                                                            (Click here for the
                                                            home delivery.)
                                                      </Link>
                                                </p>
                                          </AboutDetail>
                                    </AboutItem>
                                    <BannerImage image='url("/images/ourplant/onestopsolution.jpg")' />
                              </BannerContainer>

                              <BannerContainer>
                                    <BannerImage image='url("/images/sustainability/pricing.jpg")' />
                                    <AboutItem>
                                          <AboutDetail>
                                                <AboutSummary>
                                                      Pricing
                                                </AboutSummary>
                                                <p>
                                                      The price of Prime Tiles
                                                      is one of the most
                                                      competitive in the
                                                      industry. Armed with
                                                      superior sourcing of raw
                                                      material, use of domestic
                                                      resources, lesser
                                                      transport cost and lead
                                                      time, efficient
                                                      manufacturing process and
                                                      transcending trade
                                                      barriers and costs, Prime
                                                      Tiles costs less than any
                                                      other superior tiles in
                                                      the market. We bring a
                                                      treat for the Nepalese
                                                      consumer on the table. We
                                                      believe in fair market
                                                      practices, and you will
                                                      never be overcharged.
                                                </p>
                                          </AboutDetail>
                                    </AboutItem>
                              </BannerContainer>

                              <BannerContainer>
                                    <AboutItem>
                                          <AboutDetail>
                                                <AboutSummary>
                                                      Sustainability
                                                </AboutSummary>
                                                <p>
                                                      At Prime Ceramics, we
                                                      believe in creating
                                                      long-term value and making
                                                      a meaningful impact on
                                                      community and world
                                                      surrounding us. We drive
                                                      sustainable development
                                                      through usage of efficient
                                                      energy system, sound
                                                      environmental,
                                                      occupational health and
                                                      safety and social
                                                      inclusion practices. We
                                                      believe in ethical
                                                      business which is guided
                                                      by transparency, fairness
                                                      and value for all
                                                      stakeholders. Prime is a
                                                      long term player and does
                                                      not take any short cuts.
                                                      <Link to="/sustainability">
                                                            ( click here for our
                                                            sustainability
                                                            policy)
                                                      </Link>
                                                </p>
                                                <p>
                                                      Welcome to the world of
                                                      Prime – Tiles with
                                                      <i>Stile</i>
                                                </p>
                                          </AboutDetail>
                                    </AboutItem>
                                    <BannerImage image='url("/images/ourplant/sustainability.jpg")' />
                              </BannerContainer>
                        </AboutContent>
                  </Container>

                  <Modal
                        style={{ zIndex: '999999999' }}
                        show={showVisualizer}
                        fullscreen={fullscreen}
                        onHide={() => setShowVisualizer(false)}
                  >
                        <Modal.Header closeButton>
                              <Modal.Title>Chose Your Room</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                              style={{ padding: '0', overflow: 'hidden' }}
                        >
                              <Visualizer />
                        </Modal.Body>
                  </Modal>
            </>
      );
};

export default WhyPrimeTile;
