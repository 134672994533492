import { motion } from 'framer-motion';
import styled from 'styled-components';

export const FaqContainer = styled.div`
      margin: 50px 0;
      padding: 0 50px;
`;

export const FaqRequestContainer = styled.div.attrs(() => ({
      className: 'col-md-4 col-lg-4'
}))``;

export const FaqAsk = styled.div`
      position: sticky;
      top: 50px;
`;

export const FaqAskHeader = styled.div`
      background-color: ${(props) => props.theme.primaryColor};
      padding: 12px 20px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      box-shadow: 0px 0px 15px ${(props) => props.theme.boxShadowColor};
`;

export const FaqAskBody = styled.div`
      padding: 20px;
      background-color: white;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      box-shadow: 0px 0px 15px ${(props) => props.theme.boxShadowColor};
`;

export const FaqAskTitle = styled.div`
      font-size: 16px;
      color: ${(props) => props.theme.white};
`;

export const FaqFormButton = styled.button.attrs(() => ({
      type: 'submit'
}))`
      background: ${(props) => props.theme.white};
      border: 2px solid ${(props) => props.theme.primaryColor};
      padding: 10px 24px;
      color: ${(props) => props.theme.primaryColor};
      transition: 0.4s;

      &:hover {
            background-color: ${(props) => props.theme.primaryColor};
            color: ${(props) => props.theme.white};
      }
`;

export const FaqListContainer = styled.div.attrs(() => ({
      className: 'col-md-8 col-lg-8'
}))`
      .accordion-button:not(.collapsed) {
            background-color: ${(props) => props.theme.primaryColor};
            color: ${(props) => props.theme.white};
      }

      .accordion-button:focus {
            box-shadow: none;
      }

      .accordion-button:not(.collapsed)::after {
            background-image: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="#FFF"><path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/></svg>';
      }
`;

export const FaqContent = styled.div`
      background-color: white;
`;
