import styled from 'styled-components';

export const Header = styled.header`
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1000;
      width: 100%;
`;

export const HeaderContainer = styled.div`
      padding: 15px 10px 20px 10px;
      max-width: 1536px;
      margin: 0 auto;

      @media screen and (max-width: 1200px) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 10px 20px;
            background-color: ${(props) => props.theme.white};
            position: fixed;
            width: 100%;
            border-bottom: 1px solid #dee2e6;
            z-index: 21474;
      }
`;

export const HeaderContent = styled.div`
      display: flex;
      padding: 0 50px;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 1200px) {
            padding: 0;
      }
`;

export const HeaderLogo = styled.div`
      padding-left: ${(props) => props.padding && '101px'};

      a {
            display: block;
      }

      @media screen and (max-width: 1200px) {
            padding: 0;
      }
      @media screen and (max-width: 700px) {
            img {
                  width: 6.25rem;
            }
      }
`;

export const HeaderNav = styled.div`
      display: flex;
      align-items: center;
      background-color: ${(props) => props.color && props.theme.footerColor};
      clip-path: ${(props) =>
            props.clipPath && 'polygon(3% 0%, 100% 0%, 100% 100%, -3% 300%)'};
      padding: ${(props) => props.padding && '24px 10px 24px 10px'};
      padding-right: ${(props) => props.paddingRight};

      ul {
            display: flex;
            margin: 0;

            li {
                  padding: 0 1.5rem;
                  position: relative;

                  &:not(:last-child) {
                        margin-right: 0rem;
                  }

                  &:last-child {
                        padding-left: 1.5rem;
                        padding-right: 0;
                  }

                  .submenu {
                        display: none;
                        position: absolute;
                        left: 0;
                        padding: 0;
                        top: 0px;
                        margin-top: 30px;

                        li {
                              display: block;
                              background: white;
                              color: #000;
                              min-width: max-content;
                              padding-bottom: 10px;

                              &:first-child {
                                    padding-top: 10px;
                              }

                              a {
                                    color: #000;
                              }
                        }
                  }

                  a {
                        font-size: 15px;
                        font-weight: 300;
                        position: relative;
                        color: ${(props) => props.theme.white};
                        padding-top: 10px;
                        padding-bottom: 10px;

                        &.active::after {
                              content: '';
                              position: absolute;
                              width: 40px;
                              height: 1px;
                              background-color: ${(props) => props.theme.white};
                              left: 0;
                              bottom: -16px;
                              border-radius: 5px;
                        }
                  }

                  &:hover > .submenu {
                        display: block;
                  }
            }
      }

      @media screen and (max-width: 1200px) {
            display: none;
      }
`;

export const HeaderMobileMenu = styled.div`
      display: none;
      color: ${(props) => props.theme.white} !important;

      @media screen and (max-width: 1200px) {
            display: block;
      }
      @media screen and (max-width: 700px) {
            img {
                  width: 24px;
                  height: 24px;
            }
      }
`;

export const HeaderCategory = styled.div`
      background-color: #151515;
`;

export const StickyHeaderNavContent = styled.div`
      ul {
            display: flex;
            list-style-type: none;
            justify-content: space-between;
            padding: 0 20px !important;
            padding-left: 0 !important;
            position: relative;

            li {
                  min-width: fit-content;

                  a {
                        text-decoration: none;
                        padding: 10px;
                        color: #acacac;
                        display: block;

                        /* &:hover {
                              background-color: ${(props) => props.theme.black};
                        } */
                  }
            }
      }

      @media screen and (max-width: 1200px) {
            display: none;
      }
`;

export const SubmenuContent = styled.ul`
      position: absolute !important;
      top: 44px;
      left: 0;
      bottom: auto;
      right: auto;
      background-color: #202020;
      width: 100%;
      visibility: hidden;
      opacity: 0;
      height: 0%;
      z-index: 4;
      overflow-y: auto;
      transition: all 0.3s linear;

      ul {
            display: block;
      }

      a {
            color: ${(props) => props.theme.white};

            &:hover {
                  background-color: transparent;
            }
      }

      ${StickyHeaderNavContent} ul li:hover & {
            visibility: visible;
            opacity: 1;
            height: fit-content;
            justify-content: space-between;
            transition: all 0.3s linear;
            padding: 20px !important;
      }
`;

export const SubmenuContentItem = styled.div`
      a {
            font-size: 14px;
            padding: 0 !important;
            color: white !important;

            &:hover {
                  background-color: transparent;
            }
      }

      ul {
            padding-left: 0 !important;
            margin-top: 10px;

            li {
                  margin-top: 5px;

                  a {
                        padding: 2px 10px;
                        color: #a8a8a8 !important;

                        &:hover {
                              color: ${(props) =>
                                    props.theme.primaryColor} !important;
                        }
                  }
            }
      }
`;
