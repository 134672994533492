import React from 'react';
import {
      BreadcrumbBox,
      BreadcrumbContainer
} from '../styles/common/breadcrumbStyle';
import { Link } from 'react-router-dom';

const Breadcrumb = ({
      otherLink,
      otherLinkTitle,
      title,
      summary,
      image,
      backgroundPosition
}) => {
      return (
            <>
                  <BreadcrumbBox>
                        <BreadcrumbContainer
                              image={
                                    image
                                          ? image
                                          : 'url(/images/img/breadcrumb8.png)'
                              }
                              backgroundPosition={backgroundPosition}
                        >
                              <h2>{summary}</h2>

                              <ol className="d-flex p-0 m-0">
                                    <li className="px-3">
                                          <Link to="/">Home</Link>
                                    </li>
                                    <span></span>
                                    {otherLink && (
                                          <li className="px-3">
                                                <Link to={otherLink}>
                                                      {otherLinkTitle}
                                                </Link>
                                          </li>
                                    )}
                                    <li className="px-3">{title}</li>
                              </ol>
                        </BreadcrumbContainer>
                  </BreadcrumbBox>
            </>
      );
};

export default Breadcrumb;
