import React from 'react';
import './style.css';

const PrimeTitle = ({ classes }) => {
      return (
            <>
                  <div className="prime-title">
                        <img
                              style={{ height: '100px', marginTop: '-100px' }}
                              src="/images/prime-logo.png"
                        />
                        <div data-aos-duration="1500" data-aos="fade-right">
                              Welcome to the <br /> world of
                              <span style={{ color: '#d71a21' }}> </span>
                        </div>
                        <div
                              data-aos-duration="1500"
                              data-aos-delay="500"
                              data-aos="fade-up"
                        >
                              '
                              <span style={{ color: '#d71a21' }}>
                                    Prime Tiles with <i>Stile.</i>
                              </span>
                              '
                        </div>
                  </div>
            </>
      );
};

export default PrimeTitle;
