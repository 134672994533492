import React from 'react';
import { Modal } from 'react-bootstrap';

const CatalogoueRequest = ({ show, slug, setShow }) => {
      return (
            <>
                  <Modal show={show} onHide={() => setShow(false)}>
                        <Modal.Header closeButton>
                              <Modal.Title className="fs-5">
                                    Share with your friends & family
                              </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                              <div className="d-flex align-items-center gap-3 justify-content-center">
                                    <a
                                          href={`https://api.whatsapp.com/send?text=https://primeceramics.com.np`}
                                    >
                                          <img src="/icons/whatsapp.png" />
                                    </a>
                                    <a
                                          href={`viber://forward?text=https://primeceramics.com.np`}
                                    >
                                          <img src="/icons/viber.png" />
                                    </a>
                                    <a
                                          href={`mail://forward?text=https://primeceramics.com.np`}
                                    >
                                          <img src="/icons/mail.png" />
                                    </a>
                              </div>
                        </Modal.Body>
                  </Modal>
            </>
      );
};

export default CatalogoueRequest;
