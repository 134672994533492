import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HeaderCategory, StickyHeaderNavContent, SubmenuContent, SubmenuContentItem } from "../../frontend/components/layouts/header";
import axios from "axios";

const StickyHeader = () => {
      const [isSticky, setIsSticky] = useState(false);
      useEffect(() => {
            const handleScroll = () => {
                  const scrollTop = window.pageYOffset;
                  setIsSticky(scrollTop > 200);
            };
            window.addEventListener("scroll", handleScroll);
            return () => {
                  window.removeEventListener("scroll", handleScroll);
            };
      }, []);

      const [loading, setLoading] = useState(false);
      const [categories, setCategories] = useState([]);
      const [areas, setAreas] = useState([]);
      const [designs, setDesigns] = useState([]);
      const [sizes, setSizes] = useState([]);
      const [colors, setColors] = useState([]);
      const [types, setTypes] = useState([]);
      const [finishes, setFinishes] = useState([]);

      useEffect(() => {
            const fetchData = async () => {
                  setLoading(true);
                  await axios.get(`${process.env.REACT_APP_SECRET_KEY}/api/footers/items?limit=10`).then((response) => {
                        setAreas(response.data.items.areas);
                        setCategories(response.data.items.categories);
                        setDesigns(response.data.items.designs);
                        setTypes(response.data.items.types);
                        setColors(response.data.items.colors);
                        setSizes(response.data.items.sizes);
                        setFinishes(response.data.items.finishes);
                  });
                  setLoading(false);
            }
            fetchData();
      }, []);

      return (
            <>
                  <div className={`header ${isSticky ? "sticky" : "d-none"}`}>
                        <HeaderCategory>
                              <StickyHeaderNavContent className="container">
                                    <ul className="list-inline">
                                          <li className="list-inline-item">
                                                <Link to="#">Floor Tiles</Link>
                                                <SubmenuContent>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Tiles by Room Area</Link>
                                                            <ul>
                                                                  {areas.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/areas/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Size</Link>
                                                            <ul>
                                                                  {sizes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/sizes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Design</Link>
                                                            <ul>
                                                                  {designs.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/designs/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Categories</Link>
                                                            <ul>
                                                                  {types.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/types/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Finishes</Link>
                                                            <ul>
                                                                  {finishes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/finishes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                </SubmenuContent>
                                          </li>
                                          <li>
                                                <Link to="#">Wall Tiles</Link>
                                                <SubmenuContent>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Tiles by Room Area</Link>
                                                            <ul>
                                                                  {areas.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/areas/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Size</Link>
                                                            <ul>
                                                                  {sizes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/sizes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Design</Link>
                                                            <ul>
                                                                  {designs.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/designs/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Categories</Link>
                                                            <ul>
                                                                  {types.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/types/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Finishes</Link>
                                                            <ul>
                                                                  {finishes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/finishes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                </SubmenuContent>
                                          </li>
                                          <li>
                                                <Link to="#">Bathroom Tiles</Link>
                                                <SubmenuContent>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Tiles by Room Area</Link>
                                                            <ul>
                                                                  {areas.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/areas/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Size</Link>
                                                            <ul>
                                                                  {sizes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/sizes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Design</Link>
                                                            <ul>
                                                                  {designs.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/designs/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Categories</Link>
                                                            <ul>
                                                                  {types.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/types/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Finishes</Link>
                                                            <ul>
                                                                  {finishes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/finishes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                </SubmenuContent>
                                          </li>
                                          <li>
                                                <Link to="#">Kitchen Tiles</Link>
                                                <SubmenuContent>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Tiles by Room Area</Link>
                                                            <ul>
                                                                  {areas.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/areas/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Size</Link>
                                                            <ul>
                                                                  {sizes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/sizes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Design</Link>
                                                            <ul>
                                                                  {designs.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/designs/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Categories</Link>
                                                            <ul>
                                                                  {types.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/types/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Finishes</Link>
                                                            <ul>
                                                                  {finishes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/finishes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                </SubmenuContent>
                                          </li>
                                          <li>
                                                <Link to="#">Room Tiles</Link>
                                                <SubmenuContent>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Tiles by Room Area</Link>
                                                            <ul>
                                                                  {areas.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/areas/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Size</Link>
                                                            <ul>
                                                                  {sizes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/sizes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Design</Link>
                                                            <ul>
                                                                  {designs.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/designs/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Categories</Link>
                                                            <ul>
                                                                  {types.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/types/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Finishes</Link>
                                                            <ul>
                                                                  {finishes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/finishes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                </SubmenuContent>
                                          </li>
                                          <li>
                                                <Link to="#">Marble Tiles</Link>
                                                <SubmenuContent>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Tiles by Room Area</Link>
                                                            <ul>
                                                                  {areas.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/areas/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Size</Link>
                                                            <ul>
                                                                  {sizes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/sizes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Design</Link>
                                                            <ul>
                                                                  {designs.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/designs/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Categories</Link>
                                                            <ul>
                                                                  {types.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/types/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Finishes</Link>
                                                            <ul>
                                                                  {finishes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/finishes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                </SubmenuContent>
                                          </li>
                                          <li>
                                                <Link to="#">Wooden Tiles</Link>
                                                <SubmenuContent>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Tiles by Room Area</Link>
                                                            <ul>
                                                                  {areas.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/areas/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Size</Link>
                                                            <ul>
                                                                  {sizes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/sizes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Design</Link>
                                                            <ul>
                                                                  {designs.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/designs/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Categories</Link>
                                                            <ul>
                                                                  {types.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/types/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Finishes</Link>
                                                            <ul>
                                                                  {finishes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/finishes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                </SubmenuContent>
                                          </li>
                                          <li>
                                                <Link to="#">Vitrified Tiles</Link>
                                                <SubmenuContent>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Tiles by Room Area</Link>
                                                            <ul>
                                                                  {areas.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/areas/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Size</Link>
                                                            <ul>
                                                                  {sizes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/sizes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Design</Link>
                                                            <ul>
                                                                  {designs.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/designs/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Categories</Link>
                                                            <ul>
                                                                  {types.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/types/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Finishes</Link>
                                                            <ul>
                                                                  {finishes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/finishes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                </SubmenuContent>
                                          </li>
                                          <li>
                                                <Link to="#">Ceramic Tiles</Link>
                                                <SubmenuContent>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Tiles by Room Area</Link>
                                                            <ul>
                                                                  {areas.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/areas/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Size</Link>
                                                            <ul>
                                                                  {sizes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/sizes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Design</Link>
                                                            <ul>
                                                                  {designs.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/designs/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Categories</Link>
                                                            <ul>
                                                                  {types.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/types/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                      <SubmenuContentItem>
                                                            <Link to="#">Popular Floor Tiles Finishes</Link>
                                                            <ul>
                                                                  {finishes.map((item, index) => (
                                                                        <li key={index}> <Link to={`/products/finishes/${item.slug}`}>{item.title}</Link> </li>
                                                                  ))}
                                                            </ul>
                                                      </SubmenuContentItem>
                                                </SubmenuContent>
                                          </li>
                                          <li>
                                                <Link to="/blogs">Blog</Link>
                                          </li>
                                    </ul>
                              </StickyHeaderNavContent>
                        </HeaderCategory>

                  </div>
            </>
      )
}

export default StickyHeader;
