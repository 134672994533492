import React from 'react'

const TypeIcon = () => {
  return (
    <div>
      <svg id="Layer_3"  viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" data-name="Layer 3" style={{height:"39px", width:"39px"}}><path d="m21 2.5h-18a.49971.49971 0 0 0 -.5.5v18a.49971.49971 0 0 0 .5.5h18a.49971.49971 0 0 0 .5-.5v-18a.49971.49971 0 0 0 -.5-.5zm-.5 9h-8v-8h8zm-17-8h8v8h-8zm0 9h8v8h-8zm9 8v-8h8v8z"   /></svg>
    </div>
  )
}

export default TypeIcon;
