import React from 'react';

import {
      FooterContainer,
      FooterStyle
} from '../../frontend/components/layouts/footer';

import { CategoryFooter } from './CategoryFooter';
import { LinkFooter } from './LinkFooter';
import { CopyrightFooter } from './CopyrightFooter';
import { MobileFooter } from './MobileFooter';
import SocialMedia from './SocialMedia';

const Footer = () => {
      return (
            <>
                  <FooterStyle>
                        <div className="footer__rect">
                              <img
                                    src="/images/img/footer/rectangle.png"
                                    alt="Prime Ceramics - Footer"
                                    width="239"
                                    height="31"
                              ></img>
                        </div>
                        <FooterContainer>
                              <div className="container-fluid p-0">
                                    <CategoryFooter />

                                    <LinkFooter />

                                    <MobileFooter />

                                    <CopyrightFooter />
                              </div>
                        </FooterContainer>
                  </FooterStyle>
                  <SocialMedia />
            </>
      );
};

export default Footer;
