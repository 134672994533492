import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import HomePage from './home';
import FaqPage from './faqs';
import PageNotFound from './pages';
import ContactPage from './contact';
import BlogPage from './blogs';
import Category from './category';
import ProductFilter from './product/ProductFilter';
import ProductDetail from './product';
import About from './about';
import Career from './career';
import CatalogueDetail from './catalogue/CatalogueShow';
import Catalogue from './catalogue';
import BlogDetail from './blogs/BlogDetail';
import PressRelease from './blogs/PressRelease';
import ContactContainer from './dealer/ContactContainer';
import PrivacyPolicy from './privacy-policy';
import TermAndCondition from './terms-and-contition';
import WhyPrimeTile from './about/WhyPrimeTile';
import OurPlant from './about/OurPlant';
import Sustainability from './about/Sustainability';
import CareerDetail from './career/CareerDetail';

const PublicRouter = () => {
      const location = useLocation();

      return (
            <>
                  {/* <AnimatePresence initial={false} exitBeforeEnter> */}
                  <Routes location={location} key={location.pathname}>
                        <Route exact path="/" element={<HomePage />} />

                        <Route
                              path="/why-prime-tiles"
                              element={<WhyPrimeTile />}
                        />

                        <Route
                              path="/sustainability"
                              element={<Sustainability />}
                        />

                        <Route path="/our-plant" element={<OurPlant />} />

                        <Route exact path="/faqs" element={<FaqPage />} />

                        <Route
                              path="/dealer-contact"
                              element={<ContactContainer />}
                        />
                        <Route
                              path="/terms-and-condition"
                              element={<TermAndCondition />}
                        />

                        {/* Frontend */}
                        <Route path="/catalogues" element={<Catalogue />} />
                        <Route
                              path="/catalogues/:catalgoueId"
                              element={<CatalogueDetail />}
                        />

                        <Route
                              path="/privacy-policy"
                              element={<PrivacyPolicy />}
                        />

                        {/* Categories */}
                        <Route
                              path="/products/:categoryId/:slugId"
                              element={<Category />}
                        />

                        {/* About Us & Contact Us */}
                        <Route path="/about-us" element={<About />} />
                        <Route path="/contact-us" element={<ContactPage />} />

                        <Route path="/blogs" element={<BlogPage />} />
                        <Route path="/blogs/:blogId" element={<BlogDetail />} />
                        <Route path="/csr" element={<PressRelease />} />

                        {/* Product */}
                        <Route path="/products" element={<ProductFilter />} />
                        <Route
                              path="/products/:productId"
                              element={<ProductDetail />}
                        />

                        {/* Career Pages */}
                        <Route path="/careers" element={<Career />} />
                        <Route path="/careers/:careerId" element={<CareerDetail />} />
                        <Route path="/careers" element={<Career />} />

                        <Route path="*" element={<PageNotFound />} />
                  </Routes>
                  {/* </AnimatePresence> */}
            </>
      );
};

export default PublicRouter;
