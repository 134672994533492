import React from 'react'
import { Col } from 'react-bootstrap';

import Skeleton from 'react-loading-skeleton'
import { FooterItem, FooterItemContent, FooterItemTitle } from '../layouts/footer';
import FooterCategoryItem from './FooterCategoryItem';

const FooterCategoryItemContainer = ({
      title,
      items,
      loading,
      type
}) => {
      return (
            <>
                  <Col lg={true}>
                        <FooterItem>
                              <FooterItemTitle>
                                    <h2>
                                          {title}
                                    </h2>
                              </FooterItemTitle>
                              <FooterItemContent margin="8px">
                                    <ul>
                                          {!loading ? (
                                                <>
                                                      {items.map((item, index) => (
                                                            <FooterCategoryItem
                                                                  key={index}
                                                                  title={item.title}
                                                                  type={type}
                                                                  slug={item.slug} />
                                                      ))}
                                                </>
                                          ) : (
                                                <>
                                                      <Skeleton count={5} height={18} />
                                                </>
                                          )}
                                    </ul>
                              </FooterItemContent>
                        </FooterItem>
                  </Col>
            </>
      )
}

export default FooterCategoryItemContainer;
