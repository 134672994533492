import React from 'react';

const MenuIcon = () => {
      return (
            <>
                  <svg
                        height="30"
                        viewBox="0 0 16 16"
                        width="30"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="#231F20"
                  >
                        <g id="_31" data-name="31">
                              <path d="m15.5 4h-15a.5.5 0 0 1 0-1h15a.5.5 0 0 1 0 1z" />
                              <path d="m15.5 9h-15a.5.5 0 0 1 0-1h15a.5.5 0 0 1 0 1z" />
                              <path d="m15.5 14h-15a.5.5 0 0 1 0-1h15a.5.5 0 0 1 0 1z" />
                        </g>
                  </svg>
            </>
      );
};
export default MenuIcon;
