import styled from 'styled-components';

export const TestimonialBox = styled.section`
      background-color: transparent;
      opacity: 1;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      position: relative;
      /* height: 588px; */
      margin-top: 1rem;
      margin-bottom: 3rem;
      background-image: linear-gradient(
            315deg,
            ${(props) => props.theme.primaryColor} 54%,
            #f2f2f2 0%
      );

      @media screen and (max-width: 1200px) {
            background-image: none;
            padding-top: 0px;
      }
      @media screen and (max-width:767px){
            padding-top:10px
      }
`;

export const TestimonialBackgroundImage = styled.div`
      position: absolute;
      top: -30px;
      right: 30px;
`;

export const TestimonialContainer = styled.div`
      position: relative;
      z-index: 0;
      padding: 80px 0px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      @media screen and (max-width:767px){
            padding:10px 0
      }
`;

export const TestimonialContent = styled.div`
      display: flex;
      align-items: center;

      @media screen and (max-width: 1068px) {
            padding-left: 85px;
            margin-top: 20px;
      }

      @media screen and (max-width: 944px) {
            padding-left: 0;
            margin-top: 20px;
      }
`;

export const TestimonialTitle = styled.div`
      span:nth-child(1) {
            background-color: ${(props) => props.theme.primaryColor};
            padding: 15px;
            border-radius: 50%;
      }

      span:nth-child(2) {
            text-transform: uppercase;
            margin-left: 1rem;
            margin-right: 1.5rem;
            color: ${(props) => props.theme.primaryColor};
            padding: 0px 15px 0px 0px;
            border-radius: 50%;
      }

      h2 {
            font-size: 2.5rem;
            font-weight: 400;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
      }
`;

export const TestimonialHeading = styled.h2`
      font-size: 2.5rem !important;
      font-weight: 400;
      span {
            color: ${(props) => props.theme.primaryColor};
            background-color: transparent !important;
      }
`;

export const TestimonialSliderContent = styled.div`
      margin: auto;
      background-color: ${(props) => props.theme.white};
      border-radius: 30px;
      padding: 30px;

      img {
            height: 60px;
      }
`;

export const TestimonialSliderText = styled.div`
      margin-bottom: 2rem;
      margin-top: 0.5rem;

      p {
            font-style: italic;
            font-weight: 300;
            font-size: 0.938rem;
            line-height: 18px;
            color: #565656;
      }
`;

export const TestimonialSliderName = styled.div`
      font-weight: 600;
      font-size: 1rem;
`;

export const TestimonialSliderPosition = styled.div`
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 17px;
      /* identical to box height */
      color: #535353;
`;

export const TestimonialSliderImage = styled.div`
      img {
            height: 66px;
            width: 66px;
            object-fit: cover;
            border-radius: 50px;
      }
`;

export const TestimonialFooter = styled.div`
      display: flex;
      align-items: center;
      gap: 1.5rem;
      margin-bottom: 0.75rem;
`;
