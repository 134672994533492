import React from 'react'

export const lightTheme = {
      body: "#FCF6F4",
      text: "#000000",
      fontFamily: "'Manrope', sans-serif",
      fontFamily: "'Inter', sans-serif",
      bodyRgba: "252, 246, 244",
      textRgba: "0,0,0",
      white: '#FFFFFF',
      black: '#000000',
      linkColor: "#7c7c7c",
      primaryColor: '#D71A21',
      secondaryColor: '#8f2424',
      footerColor: '#231f20',
      boxShadowColor: "rgba(35, 31, 32, 0.1)",
      cardBodyColor: "#f2f2f2"
}

export const DarkTheme = {
      body: "#000000",
      text: "#FCF6F4",
      fontFamily: "'Manrope', sans-serif",
      fontFamily: "'Inter', sans-serif",
      textRgba: "252, 246, 244",
      bodyRgba: "0,0,0",
}
