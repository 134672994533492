import React, { useState } from 'react';
import {
      VisualizerContainer,
      VisualizerContent,
      VisualizerContentButton,
      VisualizerDescription,
      VisualizerTitle,
      VisualizerVideo
} from '../components/styles/homeStyle/visualizerStyle';
import { Container } from 'react-bootstrap';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';

import { Modal } from 'react-bootstrap';
import Visualizer from '../visualizer';
import { CloseButtton } from './styles';

const VisualizerSection = () => {
      const [show, setShow] = useState(false);

      const [fullscreen, setFullscreen] = useState(true);
      const [showVisualizer, setShowVisualizer] = useState(false);

      function handleShowVisualizer(breakpoint) {
            setFullscreen(breakpoint);
            setShowVisualizer(true);
      }

      return (
            <>
                  <VisualizerContainer>
                        <Container>
                              <Row>
                                    <Col lg={6}>
                                          <VisualizerContent>
                                                <VisualizerTitle>
                                                      <h2>
                                                            <span>Prime</span>
                                                            <br /> Visualizer
                                                      </h2>
                                                </VisualizerTitle>
                                                <VisualizerDescription>
                                                      <p>
                                                            Using this
                                                            visualizer, you can
                                                            quickly and
                                                            effortlessly preview
                                                            how different tile
                                                            options would appear
                                                            in various spaces.
                                                            Whether you're an
                                                            interior designer, a
                                                            contractor, or a
                                                            homeowner, this tool
                                                            allows you to
                                                            visualize and
                                                            experiment with
                                                            different tile
                                                            patterns, colors,
                                                            textures, and
                                                            layouts.
                                                      </p>
                                                </VisualizerDescription>
                                                <VisualizerContentButton>
                                                      <Link
                                                            onClick={
                                                                  handleShowVisualizer
                                                            }
                                                      >
                                                            Try it on
                                                      </Link>
                                                </VisualizerContentButton>
                                          </VisualizerContent>
                                    </Col>
                                    <Col lg={6}>
                                          <VisualizerVideo>
                                                <img
                                                      src="/images/img/home/visualizer.png"
                                                      alt="Visualizer Video"
                                                      className="img-fluid"
                                                      width="100%"
                                                      height="100%"
                                                />
                                          </VisualizerVideo>
                                    </Col>
                              </Row>
                        </Container>
                  </VisualizerContainer>

                  <Modal
                        style={{ zIndex: '999999999' }}
                        show={showVisualizer}
                        fullscreen={fullscreen}
                        onHide={() => setShowVisualizer(false)}
                  >
                        <CloseButtton
                              type="button"
                              className="btn-close"
                              ariaLabel="Close"
                              onClick={() => setShowVisualizer(false)}
                        />
                        <Modal.Body
                              style={{ padding: '0', overflow: 'hidden' }}
                        >
                              <Visualizer />
                        </Modal.Body>
                  </Modal>
            </>
      );
};

export default VisualizerSection;
