import React, { useEffect, useState } from 'react';
import {
      FooterSocialMedia,
      FooterSocialMediaLink,
      FooterSubscription
} from '../../frontend/components/layouts/footer';
import { Link } from 'react-router-dom';
import Facebook from '../../frontend/components/icons/Facebook';
import Instagram from '../../frontend/components/icons/Instagram';
import Youtube from '../../frontend/components/icons/Youtube';
import { Button, Form } from 'react-bootstrap';
import PaperPlane from '../../frontend/components/icons/PaperPlane';
import Linkedin from '../../frontend/components/icons/Linkedin';
import TikTok from '../../frontend/components/icons/TikTok';
import axios from 'axios';
import { toast } from 'react-hot-toast';

import $ from 'jquery';

export const SubscribeFooter = () => {
      const [email, setEmail] = useState('');
      const [loading, setLoading] = useState(false);

      const handleForm = async (event) => {
            event.preventDefault();

            const data = {
                  email: email
            };
            setLoading(true);
            await axios
                  .post(
                        `${process.env.REACT_APP_SECRET_KEY}/api/subscribers`,
                        data
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              toast.success('Successfully Subscribed.');
                              $('form').trigger('reset');
                        }

                        if (response.data.result === 'error') {
                              toast.error(response.data.message.email);
                        }
                  })
                  .catch((error) => {
                        toast.error(error.message);
                  });
            setLoading(false);
      };

      return (
            <>
                  <FooterSubscription>
                        <Form onSubmit={handleForm}>
                              <Form.Group className="w-100">
                                    <Form.Control
                                          type="email"
                                          placeholder="Subscribe Now"
                                          onChange={(event) =>
                                                setEmail(event.target.value)
                                          }
                                          required
                                    />
                              </Form.Group>
                              <Form.Group>
                                    <Button
                                          aria-label="Prime Ceramics - Subscribe"
                                          type="submit"
                                          className="border-0"
                                          name="subscribe"
                                    >
                                          <PaperPlane />
                                    </Button>
                              </Form.Group>
                        </Form>
                  </FooterSubscription>
                  <FooterSocialMedia>
                        <FooterSocialMediaLink>
                              <Link
                                    to="https://www.facebook.com/PrimeTiles.Official"
                                    target="_blank"
                                    title="Prime Tiles Official Facebook Page"
                              >
                                    <Facebook />
                              </Link>
                        </FooterSocialMediaLink>
                        <FooterSocialMediaLink>
                              <Link
                                    to="https://www.instagram.com/primetiles.official"
                                    target="_blank"
                                    title="Prime Tiles Official Instagram"
                              >
                                    <Instagram />
                              </Link>
                        </FooterSocialMediaLink>
                        <FooterSocialMediaLink>
                              <Link
                                    to="https://www.linkedin.com/company/primetilesofficial"
                                    target="_blank"
                                    title="Prime Tiles Official Linkedin"
                              >
                                    <Linkedin />
                              </Link>
                        </FooterSocialMediaLink>
                        <FooterSocialMediaLink>
                              <Link
                                    to="https://www.youtube.com/@primetiles.Official"
                                    target="_blank"
                                    title="Prime Tiles Official YouTube"
                              >
                                    <Youtube />
                              </Link>
                        </FooterSocialMediaLink>
                        <FooterSocialMediaLink>
                              <Link
                                    to="https://www.tiktok.com/@primetiles.official"
                                    target="_blank"
                                    title="Prime Tiles Official Tiktok"
                              >
                                    <TikTok />
                              </Link>
                        </FooterSocialMediaLink>
                  </FooterSocialMedia>
            </>
      );
};
