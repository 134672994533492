import React from 'react';

const DesignIcon = () => {
      return (
            <div>
                  <svg
                        version="1.1"
                        id="fi_516280"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 469.333 469.333"
                        style={{ enableBackground: 'new 0 0 469.333 469.333' }}
                        xmlSpace="preserve"
                        width="30"
                        height="30"
                  >
                        <g>
                              <g>
                                    <path
                                          d="M469.333,234.667c0-3.691-1.899-7.125-5.035-9.067l-37.035-22.933l37.035-22.912c3.136-1.963,5.035-5.397,5.035-9.088
			s-1.899-7.125-5.056-9.067l-224-138.667c-3.435-2.133-7.787-2.133-11.221,0L5.056,161.6C1.899,163.541,0,166.976,0,170.667
			s1.899,7.125,5.056,9.067l37.013,22.933L5.035,225.621C1.899,227.541,0,230.976,0,234.667s1.899,7.125,5.035,9.067l37.035,22.933
			L5.035,289.621C1.899,291.541,0,294.976,0,298.667s1.92,7.125,5.056,9.067l224,138.667c1.707,1.067,3.669,1.6,5.611,1.6
			s3.904-0.533,5.611-1.6l224-138.667c3.136-1.941,5.056-5.376,5.056-9.067s-1.899-7.125-5.035-9.067l-37.035-22.955l37.035-22.933
			C467.435,241.792,469.333,238.357,469.333,234.667z M30.933,170.667L234.667,44.544L438.4,170.667L234.667,296.789L30.933,170.667
			z M438.4,298.667L234.667,424.789L30.933,298.667l31.403-19.456l166.72,103.211c1.707,1.045,3.669,1.579,5.611,1.579
			s3.904-0.533,5.611-1.6l166.72-103.211L438.4,298.667z M234.667,360.789L30.912,234.667l31.403-19.477L229.035,318.4
			c1.728,1.067,3.691,1.6,5.632,1.6s3.904-0.533,5.611-1.6l166.72-103.211l31.424,19.477L234.667,360.789z"
                                    ></path>
                              </g>
                        </g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                        <g></g>
                  </svg>
            </div>
      );
};

export default DesignIcon;
