import React from 'react'

const AreaIcon = () => {
  return (
    <div>
      <svg id="Layer_2" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" data-name="Layer 2" width="40" height="40"><path d="m86.75 79.13-4.37 4.37v-44.14l4.37 4.37c.37.37.86.56 1.35.56s.97-.19 1.35-.56c.74-.74.74-1.95 0-2.69l-7.62-7.62c-.74-.74-1.95-.74-2.69 0l-7.62 7.62c-.74.74-.74 1.95 0 2.69s1.95.74 2.69 0l4.37-4.37v44.14l-4.37-4.37c-.74-.74-1.95-.74-2.69 0s-.74 1.95 0 2.69l7.62 7.62c.37.37.86.56 1.35.56s.97-.19 1.35-.56l7.62-7.62c.74-.74.74-1.95 0-2.69s-1.95-.74-2.69 0z"/><path d="m58.97 10.56c-.74-.74-1.95-.74-2.69 0s-.74 1.95 0 2.69l4.37 4.37h-44.15l4.37-4.37c.74-.74.74-1.95 0-2.69s-1.95-.74-2.69 0l-7.62 7.62c-.74.74-.74 1.95 0 2.69l7.62 7.62c.37.37.86.56 1.35.56s.97-.19 1.35-.56c.74-.74.74-1.95 0-2.69l-4.37-4.37h44.13l-4.37 4.37c-.74.74-.74 1.95 0 2.69.37.37.86.56 1.35.56s.97-.19 1.35-.56l7.62-7.62c.74-.74.74-1.95 0-2.69z"/><path d="m65.24 32.86h-53.34c-1.05 0-1.9.85-1.9 1.9v53.33c0 1.05.85 1.9 1.9 1.9h53.33c1.05 0 1.9-.85 1.9-1.9v-53.33c0-1.05-.85-1.9-1.9-1.9zm-1.9 53.33h-49.53v-49.52h49.52v49.52z"/></svg>
    </div>
  )
}

export default AreaIcon
