import React, { useEffect, useState } from 'react';
import { Container, Spinner } from 'react-bootstrap';
import BlogItemBox from '../components/frontend/blogs/BlogItemBox';
import axios from 'axios';
import { BlogContent } from '../components/styles/blogStyle';
import { Swiper, SwiperSlide } from 'swiper/react';

const BlogSection = () => {
      const [loading, setLoading] = useState(false);
      const [blogs, setBlogs] = useState([]);

      useEffect(() => {
            const loadData = async () => {
                  setLoading(true);
                  await axios
                        .get(`${process.env.REACT_APP_SECRET_KEY}/api/blogs`)
                        .then((response) => {
                              if (response.data.result === 'success') {
                                    setBlogs(response.data.blogs);
                                    setLoading(false);
                              }
                        })
                        .catch((error) => {
                              console.log(error.message);
                        });
            };
            loadData();
      }, []);

      return (
            <>
                  <Container>
                        <BlogContent className="mb-5">
                              {!loading ? (
                                    <>
                                          {blogs.length > 0 ? (
                                                blogs.length == 2 ? (
                                                      <Swiper
                                                            slidesPerView={1}
                                                            spaceBetween={30}
                                                            breakpoints={{
                                                                  640: {
                                                                        slidesPerView: 1,
                                                                        spaceBetween: 10
                                                                  },
                                                                  768: {
                                                                        slidesPerView: 2,
                                                                        spaceBetween: 10
                                                                  },
                                                                  1024: {
                                                                        slidesPerView:
                                                                              blogs.length >
                                                                              1
                                                                                    ? 2
                                                                                    : 1,
                                                                        spaceBetween: 30
                                                                  }
                                                            }}
                                                      >
                                                            {blogs.map(
                                                                  (
                                                                        blog,
                                                                        index
                                                                  ) => (
                                                                        <>
                                                                              <SwiperSlide
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <BlogItemBox
                                                                                          blog={
                                                                                                blog
                                                                                          }
                                                                                          width="w-100"
                                                                                    />
                                                                              </SwiperSlide>
                                                                        </>
                                                                  )
                                                            )}
                                                      </Swiper>
                                                ) : (
                                                      <Swiper
                                                            slidesPerView={1}
                                                            spaceBetween={30}
                                                            breakpoints={{
                                                                  640: {
                                                                        slidesPerView: 1,
                                                                        spaceBetween: 10
                                                                  },
                                                                  768: {
                                                                        slidesPerView: 2,
                                                                        spaceBetween: 10
                                                                  },
                                                                  1024: {
                                                                        slidesPerView:
                                                                              blogs.length >
                                                                              1
                                                                                    ? 3
                                                                                    : 1,
                                                                        spaceBetween: 30
                                                                  }
                                                            }}
                                                      >
                                                            {blogs.map(
                                                                  (
                                                                        blog,
                                                                        index
                                                                  ) => (
                                                                        <>
                                                                              <SwiperSlide
                                                                                    key={
                                                                                          index
                                                                                    }
                                                                              >
                                                                                    <BlogItemBox
                                                                                          blog={
                                                                                                blog
                                                                                          }
                                                                                          width="w-100 h-100"
                                                                                    />
                                                                              </SwiperSlide>
                                                                        </>
                                                                  )
                                                            )}
                                                      </Swiper>
                                                )
                                          ) : (
                                                <></>
                                          )}
                                    </>
                              ) : (
                                    <>
                                          <div
                                                className="text-center"
                                                style={{
                                                      height: '50vh',
                                                      paddingTop: '20vh'
                                                }}
                                          >
                                                <Spinner
                                                      animation="border"
                                                      role="status"
                                                >
                                                      <span className="visually-hidden">
                                                            Loading...
                                                      </span>
                                                </Spinner>
                                          </div>
                                    </>
                              )}
                        </BlogContent>
                  </Container>
            </>
      );
};

export default BlogSection;
