import React, { useState } from 'react';
import {
      OtherHeaderBox,
      OtherHeaderContainer,
      OtherHeaderShape
} from '../../frontend/components/layouts/header/otherStyle';
import {
      HeaderLogo,
      HeaderMobileMenu,
      HeaderNav
} from '../../frontend/components/layouts/header';
import { Link } from 'react-router-dom';
import LoginIcon from '../../frontend/components/icons/LoginIcon';
import MenuIcon from '../../frontend/components/icons/MenuIcon';
import StickyHeader from './StickyHeader';
import { Modal } from 'react-bootstrap';
import Visualizer from '../../frontend/visualizer';

export const OtherHeader = ({ setShow }) => {
      const [fullscreen, setFullscreen] = useState(true);
      const [showVisualizer, setShowVisualizer] = useState(false);

      function handleShowVisualizer(breakpoint) {
            setFullscreen(breakpoint);
            setShowVisualizer(true);
      }
      return (
            <>
                  <OtherHeaderBox>
                        <OtherHeaderContainer>
                              <HeaderLogo padding={true}>
                                    <Link to="/">
                                          <img
                                                src="/images/prime-logo.png"
                                                className="img-fluid"
                                                width="130"
                                                height="54"
                                                alt="Prime Ceramics Pvt. Ltd. Official Logo"
                                          />
                                    </Link>
                              </HeaderLogo>
                              <HeaderNav
                                    color={true}
                                    padding={true}
                                    clipPath={true}
                                    paddingRight="40px"
                              >
                                    <nav>
                                          <ul>
                                                <li>
                                                      <Link to="/about-us">
                                                            About Us
                                                      </Link>

                                                      <ul className="submenu">
                                                            <li>
                                                                  <Link to="about-us">
                                                                        Who we
                                                                        are?
                                                                  </Link>
                                                            </li>
                                                            <li>
                                                                  <Link to="/our-plant">
                                                                        Our
                                                                        Plant
                                                                  </Link>
                                                            </li>
                                                            {/* <li>
                                                                  <Link to="/gallery">
                                                                        Gallery
                                                                  </Link>
                                                            </li> */}
                                                      </ul>
                                                </li>
                                                <li>
                                                      <Link to="/why-prime-tiles">
                                                            Why Prime Tiles
                                                      </Link>
                                                </li>
                                                <li>
                                                      <Link to="/products">
                                                            Products
                                                      </Link>
                                                </li>
                                                <li>
                                                      <Link to="/catalogues">
                                                            Catalogue
                                                      </Link>
                                                </li>
                                                <li>
                                                      <Link to="/sustainability">
                                                            Sustainability
                                                      </Link>
                                                </li>
                                                <li>
                                                      <Link to="/contact-us">
                                                            Contact Us
                                                      </Link>
                                                </li>
                                                <li>
                                                      <Link to="/dealer-contact">
                                                            Dealers
                                                      </Link>
                                                </li>
                                                {/* <li> <Link to="/catalogues"> Compare </Link> </li> */}
                                                <li>
                                                      <Link
                                                            to="https://portal.primeceramics.com.np/login"
                                                            target="_blank"
                                                      >
                                                            <LoginIcon />
                                                      </Link>
                                                </li>
                                          </ul>
                                    </nav>
                              </HeaderNav>
                              <HeaderMobileMenu>
                                    <div id="header">
                                          <a
                                                href="#menu"
                                                onClick={() => setShow()}
                                          >
                                                <MenuIcon />
                                          </a>
                                    </div>
                              </HeaderMobileMenu>
                        </OtherHeaderContainer>
                  </OtherHeaderBox>

                  <StickyHeader />

                  <Modal
                        style={{ zIndex: '999999999' }}
                        show={showVisualizer}
                        fullscreen={fullscreen}
                        onHide={() => setShowVisualizer(false)}
                  >
                        <Modal.Header closeButton>
                              <Modal.Title>Chose Your Room</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                              style={{ padding: '0', overflow: 'hidden' }}
                        >
                              <Visualizer />
                        </Modal.Body>
                  </Modal>
            </>
      );
};
