import React, { useEffect, useState } from 'react';
import Breadcrumb from '../components/common/Breadcrumb';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { Card, Col, Container, Row } from 'react-bootstrap';
import {
      CareerCard,
      CareerHeader,
      CareerHeaderIcon,
      CareerHeaderImage,
      CareerLink,
      CareerTable
} from './style';
import CareerApply from './CareerApply';

const CareerDetail = () => {
      const { careerId } = useParams();
      const [loading, setLoading] = useState(false);
      const [career, setCareer] = useState([]);
      const [careers, setCareers] = useState([]);

      const [show, setShow] = useState(false);

      const handleClose = () => setShow(false);
      const handleShow = () => setShow(true);

      const loadData = async () => {
            setLoading(true);

            await axios
                  .get(
                        `${process.env.REACT_APP_SECRET_KEY}/api/careers/${careerId}`
                  )
                  .then((response) => {
                        if (response.data.result === 'success') {
                              setCareer(response.data.job);
                              setCareers(response.data.jobs);
                        }
                  })
                  .catch((error) => {
                        console.log(error.message);
                  });

            setLoading(false);
      };

      useEffect(() => {
            loadData();
      }, []);

      return (
            <>
                  <Breadcrumb
                        otherLinkTitle="Career List"
                        otherLink="/careers"
                        summary={career.title}
                        title={career.title}
                  />
                  <Container>
                        <div className="career__container">
                              <Row>
                                    <Col lg={8}>
                                          <CareerCard>
                                                <Card.Body>
                                                      <CareerTable>
                                                            <tbody>
                                                                  <tr>
                                                                        <td colspan="3">
                                                                              <strong>
                                                                                    Basic
                                                                                    Information
                                                                              </strong>
                                                                        </td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td className="w-25">
                                                                              Title
                                                                        </td>
                                                                        <td className="w-3">
                                                                              :
                                                                        </td>
                                                                        <td className="w-64">
                                                                              {
                                                                                    career.title
                                                                              }
                                                                        </td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td className="w-25">
                                                                              Job
                                                                              Type
                                                                        </td>
                                                                        <td className="w-3">
                                                                              :
                                                                        </td>
                                                                        <td className="w-64">
                                                                              {
                                                                                    career.type
                                                                              }
                                                                        </td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td className="w-25">
                                                                              Department
                                                                        </td>
                                                                        <td className="w-3">
                                                                              :
                                                                        </td>
                                                                        <td className="w-64">
                                                                              {
                                                                                    career.department_name
                                                                              }
                                                                        </td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td className="w-25">
                                                                              No.
                                                                              of
                                                                              Vacancy
                                                                        </td>
                                                                        <td className="w-3">
                                                                              :
                                                                        </td>
                                                                        <td className="w-64">
                                                                              {
                                                                                    career.vacancy
                                                                              }
                                                                        </td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td className="w-25">
                                                                              Salary
                                                                        </td>
                                                                        <td className="w-3">
                                                                              :
                                                                        </td>
                                                                        <td className="w-64">
                                                                              {
                                                                                    career.salary
                                                                              }
                                                                        </td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td className="w-25">
                                                                              Qualification
                                                                        </td>
                                                                        <td className="w-3">
                                                                              :
                                                                        </td>
                                                                        <td className="w-64">
                                                                              {
                                                                                    career.education
                                                                              }
                                                                        </td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td className="w-25">
                                                                              Experience
                                                                        </td>
                                                                        <td className="w-3">
                                                                              :
                                                                        </td>
                                                                        <td className="w-64">
                                                                              {
                                                                                    career.experience
                                                                              }
                                                                        </td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td className="w-25">
                                                                              Deadline
                                                                        </td>
                                                                        <td className="w-3">
                                                                              :
                                                                        </td>
                                                                        <td className="w-64">
                                                                              {
                                                                                    career.deadline
                                                                              }
                                                                        </td>
                                                                  </tr>
                                                            </tbody>
                                                      </CareerTable>
                                                      <CareerTable className="table  mt-4">
                                                            <tbody>
                                                                  <tr>
                                                                        <td colspan="3">
                                                                              <strong>
                                                                                    Other
                                                                                    Details
                                                                              </strong>
                                                                        </td>
                                                                  </tr>
                                                                  <tr>
                                                                        <td colspan="3">
                                                                              <div
                                                                                    dangerouslySetInnerHTML={{
                                                                                          __html: career.description
                                                                                    }}
                                                                              ></div>
                                                                        </td>
                                                                  </tr>
                                                            </tbody>
                                                      </CareerTable>
                                                      <CareerLink className="text-end mb-3">
                                                            <Link
                                                                  to="#"
                                                                  onClick={() =>
                                                                        handleShow()
                                                                  }
                                                            >
                                                                  Apply Now
                                                            </Link>
                                                      </CareerLink>
                                                </Card.Body>
                                          </CareerCard>
                                    </Col>
                                    <Col lg={4}>
                                          {!loading && careers.length > 0 && (
                                                <>
                                                      <div className="border-bottom pb-3">
                                                            <h4>Other Jobs</h4>
                                                      </div>
                                                      {careers.map(
                                                            (job, index) => (
                                                                  <div
                                                                        className="mt-3"
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <CareerHeader>
                                                                              <Link
                                                                                    to={`/careers/${job.slug}`}
                                                                              >
                                                                                    <CareerHeaderImage>
                                                                                          <img
                                                                                                src="/favicon.png"
                                                                                                alt={
                                                                                                      job.title
                                                                                                }
                                                                                          />
                                                                                          <div>
                                                                                                {
                                                                                                      job.title
                                                                                                }
                                                                                                <CareerHeaderIcon
                                                                                                      fontSize="16px"
                                                                                                      className="mt-2"
                                                                                                      fontWeigth="500"
                                                                                                >
                                                                                                      <div>
                                                                                                            Posted:{' '}
                                                                                                            {
                                                                                                                  job.date
                                                                                                            }
                                                                                                      </div>
                                                                                                </CareerHeaderIcon>
                                                                                          </div>
                                                                                    </CareerHeaderImage>
                                                                              </Link>
                                                                        </CareerHeader>
                                                                  </div>
                                                            )
                                                      )}
                                                </>
                                          )}
                                    </Col>
                              </Row>
                        </div>
                  </Container>

                  <CareerApply
                        handleClose={handleClose}
                        handleShow={handleShow}
                        show={show}
                  />
            </>
      );
};

export default CareerDetail;
