import styled from 'styled-components';

export const ContactSection = styled.div``;

export const ContactInfoContainer = styled.div.attrs(() => ({
      className: 'col-lg-6'
}))`
      display: flex;
      align-items: stretch;
      position: relative;
      z-index: 2;
`;

export const ContactInfoItem = styled.div.attrs(() => ({
      className: 'col-lg-6'
}))`
      padding: 40px;
      background: ${(props) => props.theme.white};
      color: ${(props) => props.theme.footerColor};
      text-align: stretch;
      box-shadow: -5px -5px 40px 0 rgba(0, 0, 0, 0.1);
      flex-direction: column;
      display: flex;
      text-align: center;

      i {
            font-size: 48px;
            color: ${(props) => props.theme.primaryColor};
            margin-bottom: 15px;
      }

      h4 {
            padding: 0;
            margin: 0 0 10px 0;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
      }

      p {
            font-size: 15px;
      }
      a{
            color: ${(props) => props.theme.footerColor}; 
      }
      
`;

export const ContactFormContainer = styled.div.attrs(() => ({
      className: 'col-lg-6'
}))`
      box-shadow: 10px -5px 40px 0 rgba(0, 0, 0, 0.1);
      background: ${(props) => props.theme.white};
      position: relative;
      z-index: 1;
      padding: 30px;
      display: flex;
      align-items: stretch;
      z-index: 2;

      label {
            margin-bottom: 5px;
            color: ${(props) => props.theme.linkColor};
      }

      input,
      textarea {
            border-radius: 0;
            box-shadow: none;
            font-size: 14px;
      }

      input {
            padding: 10px 15px;
      }

      textarea {
            padding: 12px 15px;
      }
      .form-control:focus {
            border-color: rgb(243, 54, 33);
      }
`;

export const ContactFormButton = styled.div`
      text-align: center;
      margin-top: 1.5rem;

      button[type='submit'] {
            background: ${(props) => props.theme.white};
            border: 2px solid ${(props) => props.theme.primaryColor};
            padding: 10px 24px;
            color: ${(props) => props.theme.primaryColor};
            transition: 0.4s;

            &:hover {
                  background: ${(props) => props.theme.primaryColor};
                  color: ${(props) => props.theme.white};
            }
      }
`;

export const ContactDescription = styled.div`
      margin-top: 30px;
      box-shadow: 10px -5px 40px 0 rgba(0, 0, 0, 0.1);
      background-color: transparent !important;

      iframe {
            width: 100%;
            border: 0;
      }
`;

export const ContactContainer = styled.div`
      margin: 50px 0;
      padding: 0 50px;

      @media screen and (max-width: 640px) {
            padding: 0;

            .container-lg {
                  padding: 0 12px;
            }

            ${ContactDescription} {
                  order: 1;
            }

            ${ContactSection} {
                  order: 2;
            }
      }
`;
export const TollFree = styled.div`
 ${'' /* display:none; */}
`
