import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import HomeHeader from './HomeHeader';
import { OtherHeader } from './OtherHeader';
import MobileMenu from './MobileMenu';

const Header = () => {
      const [show, setShow] = useState(false);

      const handleStatus = () => {
            setShow(!show);
      };

      return (
            <>
                  {useLocation().pathname == '/' ? (
                        <>
                              <HomeHeader setShow={handleStatus} />
                        </>
                  ) : (
                        <>
                              <OtherHeader setShow={handleStatus} />
                        </>
                  )}

                  <MobileMenu show={show} setShow={handleStatus} />
            </>
      );
};

export default Header;
