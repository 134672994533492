import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import './style.css';
import { Modal } from 'react-bootstrap';
import CatalogueView from './CatalogueView';
import CatalogoueRequest from './CatalogueRequest';
import Breadcrumb from '../components/common/Breadcrumb';
import { Helmet } from 'react-helmet';

const Catalogue = () => {
      const [loading, setLoading] = useState(false);
      const [catalogues, setCatalogues] = useState([]);

      const [slug, setSlug] = useState('');
      const [title, setTitle] = useState('');
      const [fullscreen, setFullscreen] = useState(true);
      const [show, setShow] = useState(false);

      const [showRequest, setShowRequest] = useState(false);
      const [slugRequest, setSlugRequest] = useState('');

      useEffect(() => {
            const loadData = async () => {
                  setLoading(true);
                  await axios
                        .get(
                              `${process.env.REACT_APP_SECRET_KEY}/api/catalogues`
                        )
                        .then((response) => {
                              if (response.data.result === 'success') {
                                    setCatalogues(response.data.catalogues);
                                    setLoading(false);
                                    console.log(response.data.catalogues);
                              }
                        });
            };
            loadData();
      }, []);

      const handleCatalogue = (dataSlug, dataTitle) => {
            setTitle(dataTitle);
            setSlug(dataSlug);
            setShow(true);
      };

      const handleRequest = (dataSlug) => {
            setSlugRequest(dataSlug);
            setShowRequest(true);
      };

      return (
            <>
                  <Helmet>
                        <link rel="canonical" href={window.location.href} />
                        <title>Catalogues | Prime Tiles with Stile</title>
                  </Helmet>
                  <Breadcrumb title="Catalogues" summary="Catalogues" />
                  <div className="container-lg my-5">
                        {!loading ? (
                              <>
                                    {catalogues.length > 0 ? (
                                          <>
                                                <div className="catalogue__body">
                                                      {catalogues.map(
                                                            (
                                                                  catalogue,
                                                                  index
                                                            ) => (
                                                                  <div
                                                                        className="catalogue__item"
                                                                        key={
                                                                              index
                                                                        }
                                                                  >
                                                                        <div className="catalogue__item-image">
                                                                              <a
                                                                                    role="button"
                                                                                    onClick={() =>
                                                                                          handleCatalogue(
                                                                                                catalogue.slug,
                                                                                                catalogue.title
                                                                                          )
                                                                                    }
                                                                                    href="#"
                                                                              >
                                                                                    <img
                                                                                          src={`${process.env.REACT_APP_SECRET_KEY}/frontend/images/catalogues/${catalogue.image}`}
                                                                                          alt={
                                                                                                catalogue.title
                                                                                          }
                                                                                    />
                                                                              </a>
                                                                        </div>
                                                                        <div className="catalogue__item-detail">
                                                                              <div className="catalogue__item-title">
                                                                                    <Link
                                                                                          role="button"
                                                                                          to={`/catalogues/${catalogue.slug}`}
                                                                                    >
                                                                                          {
                                                                                                catalogue.title
                                                                                          }
                                                                                    </Link>
                                                                              </div>
                                                                              <div className="catalogue__item-icon">
                                                                                    <div className="d-flex align-items-center gap-2">
                                                                                          <Link
                                                                                                to={`/catalogues/${catalogue.slug}`}
                                                                                          >
                                                                                                View
                                                                                          </Link>
                                                                                          <span>
                                                                                                |
                                                                                          </span>
                                                                                          <a
                                                                                                download={
                                                                                                      true
                                                                                                }
                                                                                                href={`${process.env.REACT_APP_SECRET_KEY}/frontend/images/catalogues/${catalogue.file}`}
                                                                                          >
                                                                                                Download
                                                                                          </a>
                                                                                    </div>
                                                                                    <div className="catalogue__item-share">
                                                                                          <button
                                                                                                type="button"
                                                                                                onClick={() =>
                                                                                                      handleRequest(
                                                                                                            catalogue.slug
                                                                                                      )
                                                                                                }
                                                                                          >
                                                                                                <i className="bx bx-share-alt"></i>
                                                                                          </button>
                                                                                    </div>
                                                                              </div>
                                                                        </div>
                                                                  </div>
                                                            )
                                                      )}
                                                </div>
                                          </>
                                    ) : (
                                          <>
                                                <div
                                                      className="text-center"
                                                      style={{
                                                            height: '50vh',
                                                            paddingTop: '20vh'
                                                      }}
                                                >
                                                      <h4>
                                                            No Catalogue Found
                                                      </h4>
                                                </div>
                                          </>
                                    )}
                              </>
                        ) : (
                              <>
                                    <div
                                          className="text-center"
                                          style={{
                                                height: '50vh',
                                                paddingTop: '20vh'
                                          }}
                                    >
                                          <Spinner
                                                animation="border"
                                                role="status"
                                          >
                                                <span className="visually-hidden">
                                                      Loading...
                                                </span>
                                          </Spinner>
                                    </div>
                              </>
                        )}
                  </div>
                  <Modal
                        style={{ zIndex: '999999999' }}
                        show={show}
                        fullscreen={fullscreen}
                        onHide={() => setShow(false)}
                  >
                        <Modal.Header closeButton>
                              <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body
                              style={{ padding: '0', overflow: 'hidden' }}
                        >
                              <CatalogueView slug={slug} />
                        </Modal.Body>
                  </Modal>

                  <CatalogoueRequest
                        slug={slugRequest}
                        show={showRequest}
                        setShow={setShowRequest}
                  />
            </>
      );
};

export default Catalogue;
