import styled from 'styled-components';

export const BreadcrumbBox = styled.div`
      ol li {
            list-style-type: none;
            position: relative;
            color: #555;

            &::after {
                  content: '';
                  position: absolute;
                  top: 38%;
                  right: -2px;
                  height: 6px;
                  width: 6px;
                  background-color: var(--secondary-color);
                  transform: translateY(-50%);
                  transform: rotate(45deg);
            }

            &:last-child::after {
                  content: none;
            }

            a {
                  color: #555;
            }
      }

      @media screen and (max-width: 1200px) {
            padding-top: 61px;
      }
`;

export const BreadcrumbContainer = styled.div`
      height: 170px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-image: ${(props) => props.image};
      background-position: ${(props) => props.backgroundPosition};

      h2 {
            color: ${(props) => props.theme.primaryColor};
      }
`;
